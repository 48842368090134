import React from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from 'components/Input/components/InputIcon/InputIcon.scss';

interface InputIconProps {
  className?: string;
}
const InputIcon = ({ className, ...other }: InputIconProps) => {
  const attributes: any = {
    ...other,
  };
  const { glyph, ...rest } = attributes;

  return (
    <Icon
      glyph={glyph}
      className={classnames(styles.icon, className)}
      {...rest}
    />
  );
};

export default InputIcon;
