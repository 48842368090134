import React, { memo } from 'react';
import classNames from 'classnames';

import styles from 'components/Indicator/Indicator.scss';
import { Style } from '@xxxlgroup/hydra-utils/icon';
import {
  CircleVariant,
  ThemeVariant,
} from 'components/ProgressStepper/ProgressStepper.types';

interface IndicatorProps {
  children: React.ReactNode;
  className?: string;
  /** Callback for onClick events. If onClick is passed, Indicator becomes a button */
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  /** Takes the standard theme system values: 'primary', 'secondary', 'tertiary', 'dark' */
  theme?: ThemeVariant;

  /** Defines how the variant is shown. loud - full filled circle. silent - outlined circle */
  variant?: CircleVariant;
  /** Style tuple of the given Glyph */
  style?: Style;
  /** In case button has no valid content to provide accessible description */
  title?: string;
}

const Indicator = ({
  className,
  children,
  onClick,
  theme = 'primary',
  variant = 'loud',
  style,
  title,
  ...others
}: IndicatorProps) => {
  const Component = typeof onClick === 'function' ? 'button' : 'div';

  return (
    <Component
      className={classNames(
        className,
        styles.indicator,
        styles[`variant-${variant}-theme-${theme}`],
      )}
      onClick={onClick}
      style={style}
      title={title}
      {...others}
    >
      {children}
    </Component>
  );
};

export default memo(Indicator);
