import React from 'react';
import { sanitize } from 'isomorphic-dompurify';
import classnames from 'classnames';
import { HeadingProps } from 'components/Heading/Heading.types';
import { SEOLevel } from 'types/typeDefinitions';

import styles from 'components/Heading/Heading.scss';

type TagType = SEOLevel | 'div';

const Heading = ({
  anchorId,
  children,
  className,
  content,
  level = 1,
  noMargin = false,
  SEO,
  textAlign = 'inherit',
  ...other
}: HeadingProps) => {
  let Tag: TagType = 'div';
  let otherContent: React.ReactNode;
  let htmlContent: string | undefined;

  if (children) {
    otherContent = children;
  } else if (typeof content === 'string') {
    htmlContent = sanitize(content);
    if (
      typeof htmlContent === 'object' &&
      (htmlContent as Record<string, any>).toString
    ) {
      htmlContent = (htmlContent as Record<string, any>).toString();
    }
  } else {
    otherContent = content;
  }

  if (typeof SEO === 'string') {
    Tag = SEO;
  } else if (typeof SEO === 'boolean' && SEO === true) {
    Tag = `h${level}` as SEOLevel;
  }

  return (
    <Tag
      {...other}
      id={anchorId}
      className={classnames(
        styles.heading,
        styles[`heading-${level}`],
        { [styles.noMargin]: noMargin },
        styles[`aligned-${textAlign}`],
        className,
      )}
      {...(htmlContent && {
        dangerouslySetInnerHTML: { __html: htmlContent },
      })}
    >
      {otherContent}
    </Tag>
  );
};

export default Heading;
