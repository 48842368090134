import React from 'react';
import classnames from 'classnames';
import { LabelProps } from 'components/Label/Label.types';
import { sanitize } from 'isomorphic-dompurify';
import styles from 'components/Label/Label.scss';

const Label = ({
  className,
  visuallyHidden = false,
  i18n,
  id,
  refId,
  content,
  optionalSymbol = '',
  required = false,
  requiredSymbol = '*',
  visible = false,
  ...other
}: LabelProps) => {
  const Element = refId ? 'label' : 'span';
  const elementAttributes = {
    ...other,
    htmlFor: refId,
    id,
    className: classnames(
      styles.component,
      className,
      visuallyHidden && styles.hidden,
      required && styles.mandatory,
      visible && styles.visible,
    ),
    title: required ? i18n.mandatory : undefined,
    dangerouslySetInnerHTML: {
      __html: sanitize(content),
    },
    'data-required-symbol': requiredSymbol,
    'data-optional-symbol': optionalSymbol,
  };
  return <Element {...elementAttributes} />;
};

export default Label;
