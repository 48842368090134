/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
import React from 'react';

export type Alignment = 'right' | 'left';
export const ALIGNMENT_RIGHT: Alignment = 'right';
export const ALIGNMENT_LEFT: Alignment = 'left';

export type SelectEventType =
  | React.FocusEvent<HTMLDivElement>
  | React.KeyboardEvent<HTMLDivElement>
  | React.MouseEvent<HTMLDivElement>;

export interface SelectProps {
  /** Aligns dropout container right instead of left(default) in seamless mode */
  alignment?: Alignment;
  /** classnames set by parent component */
  className?: string;
  /** data-purpose prop, e.g. used for cypress gui-tests */
  'data-purpose'?: string;
  /** disabled state of select */
  disabled?: boolean;
  dispatchChange?: (event?: SelectEventType, open?: boolean) => void;
  /** display error if  existing */
  error?: string;
  /** adapt Flyout styles from select */
  flyOutClassName?: string;
  /** enable input field to filter options */
  filterable?: boolean;
  /** Various i18n messages  */
  i18n?: {
    hideSearchInputLabel: boolean;
    mandatory: string | React.ReactNode;
    searchInputAriaLabel: string | React.ReactNode;
    searchInputLabel: string | React.ReactNode;
    searchInputPlaceholder: string | React.ReactNode;
  };
  /** key of options-object which should be displayed as selected option */
  keyName: string;
  /** key of options-object which should get returned on selection */
  keyValue: string;
  /** label of select */
  label: string;
  /** number of options necessary to display the search  */
  filterItemAmount?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  /** callback for changes */
  onChange: (event?: SelectEventType, value?: string | null) => void;
  /** callback for blur events */
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  /** options of select */
  options: Record<string, any>[];
  /** The symbol(text) after placeholder and label if the field is optional (not mandatory) */
  optionalSymbol?: string;
  /** Show a default placeholder instead of the label text */
  placeholder?: string;
  /** The input field is mandatory and must be filled. *Notice:* An asterisk is automatically appended to the label/title. */
  required?: boolean;
  /** The required symbol(text) after placeholder if the field is mandatory */
  requiredSymbol?: string;
  /** seamless style option */
  seamless?: boolean;
  /** value of selected item */
  selectedOption: string | null;
}
