/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
import React, { useState, useCallback, useRef, useMemo, useId } from 'react';
import { createPortal } from 'react-dom';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import classnames from 'classnames';
import { circleQuestionmark } from '@xxxlgroup/hydra-icons';
import Icon from 'components/Icon';
import { noop } from '@xxxlgroup/hydra-utils/common';

import styles from 'components/Tooltip/Tooltip.scss';

type Variants = 'sign' | 'describer';

export const getFallbackPortalRoot = () => {
  const defaultPortalRoot = document.getElementById('tooltip');

  if (!defaultPortalRoot) {
    throw new Error(
      'There has to be a DOM-node with id="tooltip" in order to use the Tooltip-Component.',
    );
  }
  return defaultPortalRoot;
};

const ActivatorElement = (
  <button
    type="button"
    aria-label="tooltip activator"
    className={styles.activatorButton}
  >
    <Icon
      glyph={circleQuestionmark}
      className={styles.activator}
      data-purpose="tooltip.activator"
    />
  </button>
);

interface TooltipProps {
  /** element which activates the tooltip on hover */
  activator?: React.ReactElement<HTMLButtonElement>;
  children?: React.ReactNode;
  className?: string;
  /** horizontal offset of the tooltip - Distance between content and tooltip */
  horizontalOffset?: number;
  /** triggers function passed in the handlerHover event */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** directional placement of the tooltip relative to the activator */
  placement?: Placement;
  /** the element where the portal for the tooltip will render */
  portalRoot?: HTMLElement;
  /** describes if its a small or bigger information */
  variant?: Variants;
  /** vertical offset of the tooltip - Distance between content and tooltip */
  verticalOffset?: number;
}

const Tooltip = (props: TooltipProps) => {
  const {
    activator = ActivatorElement,
    children,
    className,
    horizontalOffset = 0,
    onClick = noop,
    placement = 'right',
    portalRoot = null,
    variant = 'sign',
    verticalOffset = 0,
    ...other
  } = props;

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [activatorRef, setActivatorRef] = useState<HTMLButtonElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const popperElement = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const tooltipId = useId();

  const direction = useMemo(() => {
    if (variant !== 'describer') {
      return placement;
    }
    if (placement === 'top') {
      return 'top-start';
    }
    if (placement === 'bottom') {
      return 'bottom-start';
    }

    return placement;
  }, [placement, variant]);

  const { styles: stylesPopper, attributes: attributesPopper } = usePopper(
    activatorRef,
    popperElement.current,
    {
      placement: direction,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [verticalOffset, horizontalOffset],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowElement,
          },
        },
      ],
    },
  );

  const handleHover = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event);
    if (!isVisible) {
      setIsVisible(true);
    }
  };

  const animationEndCallback = useCallback(() => {
    if (tooltipRef.current) {
      tooltipRef.current.classList.remove(styles.endAnimation);
      tooltipRef.current.removeEventListener(
        'animationend',
        animationEndCallback,
      );
    }
    setIsVisible(false);
  }, [tooltipRef]);

  const handleLeave = useCallback(() => {
    if (isVisible && tooltipRef.current) {
      tooltipRef.current.classList.add(styles.endAnimation);
      tooltipRef.current.addEventListener('animationend', animationEndCallback);
    }
  }, [isVisible, tooltipRef, animationEndCallback]);

  const renderActivator = () =>
    React.cloneElement(activator, {
      onClick: handleHover,
      onMouseEnter: handleHover,
      onMouseLeave: handleLeave,
      onTouchEnd: handleHover,
      onBlur: handleLeave,
      onFocus: handleHover,
      ref: setActivatorRef,
      'data-testid': 'activator',
      'aria-describedby': tooltipId,
    });

  const renderTooltipContent = () => {
    const classNames = classnames(
      styles.tooltip,
      styles[direction],
      styles[variant],
      {
        [styles.hidden]: !isVisible,
      },
    );

    return (
      <div
        ref={popperElement}
        style={stylesPopper.popper}
        {...attributesPopper.popper}
        className={styles.tooltipContainer}
      >
        <div
          id={tooltipId}
          data-testid="tooltip"
          ref={tooltipRef}
          className={classNames}
          role="tooltip"
        >
          <div className={styles.tooltipInner}>
            <span className={styles.tooltipContent}>{children}</span>
            <div
              ref={setArrowElement}
              className={styles.arrow}
              style={{
                // set arrow size to avoid arrow placement issue on first popper instance creation!
                width: 0,
                height: 0,
                ...stylesPopper.arrow,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTooltip = () =>
    createPortal(
      isVisible && <div>{renderTooltipContent()}</div>,
      portalRoot || getFallbackPortalRoot(),
    );

  return (
    <>
      <div
        className={classnames(styles.activatorWrapper, className)}
        {...other}
      >
        {renderActivator()}
      </div>
      {typeof window !== 'undefined' && renderTooltip()}
    </>
  );
};

export default Tooltip;
