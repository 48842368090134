import React from 'react';
import classnames from 'classnames';
import { decodeHtmlEntities } from '@xxxlgroup/hydra-utils/formatting';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import filterInvalidHTMLAttributes from 'utils/htmlAttributes';

import Loading from 'components/Loading';
import { ButtonProps } from 'components/Button/Button.types';

import styles from 'components/Button/Button.scss';

const Button = React.forwardRef<
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      active = false,
      ariaLabel,
      children,
      className,
      disabled = false,
      glyphAfter,
      glyphBefore,
      iconSize = 'regular',
      inverted = false,
      layout = 'inline',
      loading = false,
      size = 'regular',
      title,
      type = 'button',
      variant = 'primary',
      appearance = 'regular',
      ...other
    },
    ref,
  ) => {
    const usedButtonClassNames = classnames(
      styles.button,
      styles[variant],
      styles[`appearance-${appearance}`],
      styles[size],
      styles[layout],
      className,
      {
        [styles.isLoading]: loading,
        [styles.active]: active,
        [styles.inverted]: inverted,
      },
    );

    const [iconStyleBefore, iconClassNameBefore] = pseudoIcon(glyphBefore);
    const [iconStyleAfter, iconClassNameAfter] = pseudoIcon(
      glyphAfter,
      'after',
    );

    const buttonAttributes = {
      ...other,
      className: classnames(
        usedButtonClassNames,
        iconClassNameBefore,
        iconClassNameAfter,
        styles[`${iconSize}Glyph`],
      ),
      disabled: disabled || loading,
      type,
      title: decodeHtmlEntities(title ?? ''),
      style: { ...iconStyleBefore, ...iconStyleAfter },
    };

    return (
      <button
        ref={ref}
        {...filterInvalidHTMLAttributes('button', buttonAttributes)}
        aria-label={ariaLabel}
      >
        {children && (
          <span key="text" className={styles.text}>
            {children}
          </span>
        )}
        {loading && (
          <Loading key="loading" type="dots" className={styles.loadingIcon} />
        )}
      </button>
    );
  },
);
export default Button;
