import React, { useMemo, useRef, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import Input from 'components/Input';
import Select from 'components/Select';
import {
  FlagData,
  SelectInputProps,
} from 'components/SelectInput/SelectInput.types';

import styles from 'components/SelectInput/SelectInput.scss';

const SelectInput = ({
  className,
  selectDefaultValue,
  inputDefaultValue,
  options,
  isRequired = false,
  type = 'normal',
  filterItemAmount,
  i18n,
  onChange,
  errors,
  isTouch,
  placeholder,
  ...other
}: SelectInputProps) => {
  const isPhoneType = type === 'phone';

  const sortedOptions = useMemo(
    // eslint-disable-next-line arrow-body-style
    () => {
      return options.sort((a, b) => {
        if (a.rank === 0) return 1;
        if (b.rank === 0) return -1;
        return b.rank - a.rank;
      });
    },
    [options],
  );

  const mapFlagIconsWithNames = useMemo(
    () =>
      sortedOptions.map((country: FlagData) => ({
        id: country.id,
        orgName: country.name,
        name: (
          <div className={styles.labelWrapper} key={country.id}>
            <div className={styles.country}>
              <div
                className={classNames(
                  styles.countryFlag,
                  styles[`country-${country.id.toLowerCase()}`],
                )}
              />
              <div className={styles.selectedCountry}>{country.name}</div>
            </div>
            <div className={styles.prefix}>{country.prefix}</div>
          </div>
        ),
      })),
    [sortedOptions],
  );

  const selectedValueRef = useRef<string>(selectDefaultValue ?? options[0].id);
  const inputValueRef = useRef<string>(inputDefaultValue ?? '');

  const changeCallback = useCallback(
    (valueType: 'input' | 'select', value: string) => {
      if (valueType === 'input') {
        inputValueRef.current = value;
      }

      if (valueType === 'select') {
        selectedValueRef.current = value;
      }

      onChange({
        selectValue: selectedValueRef.current,
        inputValue: inputValueRef.current,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    selectedValueRef.current = selectDefaultValue;
    inputValueRef.current = inputDefaultValue;
  }, [selectDefaultValue, inputDefaultValue]);

  const renderSelectField = useMemo(
    () => (
      <Select
        className={styles.select}
        label=""
        filterable
        keyValue="id"
        keyName="name"
        filterItemAmount={filterItemAmount}
        flyOutClassName={styles.flyout}
        onChange={(_, value) => {
          if (value) {
            changeCallback('select', value);
          }
        }}
        options={isPhoneType ? mapFlagIconsWithNames : sortedOptions}
        selectedOption={selectDefaultValue}
        i18n={{
          hideSearchInputLabel: i18n.isSearchLabelHidden ?? false,
          mandatory: i18n.mandatory,
          searchInputAriaLabel: i18n.searchInputAriaLabel,
          searchInputLabel: i18n.searchInputLabel,
          searchInputPlaceholder: i18n.searchInputPlaceholder,
        }}
      />
    ),
    [
      changeCallback,
      filterItemAmount,
      i18n.isSearchLabelHidden,
      i18n.mandatory,
      i18n.searchInputAriaLabel,
      i18n.searchInputLabel,
      i18n.searchInputPlaceholder,
      isPhoneType,
      mapFlagIconsWithNames,
      selectDefaultValue,
      sortedOptions,
    ],
  );

  const inputAttributes = {
    i18n: {
      mandatory: i18n.mandatory,
      cancel: i18n.cancel,
    },
    label: i18n.inputLabel,
    hideLabel: false,
    required: isRequired,
    ...other,
  };

  return (
    <Input
      value={inputDefaultValue}
      onChange={(_, trimmedValue) => {
        const re = /^\+?\d*[\d ]*$/;
        if (!isPhoneType || trimmedValue === '' || re.test(trimmedValue)) {
          changeCallback('input', trimmedValue);
        }
      }}
      className={classNames(styles.input, className)}
      prefix={renderSelectField}
      inputMode={isPhoneType ? 'tel' : 'text'}
      errors={errors}
      isTouch={isTouch}
      placeholder={placeholder}
      {...(isPhoneType && { type: 'tel' })}
      {...inputAttributes}
    />
  );
};
export default SelectInput;
