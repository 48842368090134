import React, { MutableRefObject, useCallback, useRef } from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import SnackbarItem from 'components/Snackbar/components/SnackbarItem';
import SnackbarContent from 'components/Snackbar/components/SnackbarContent';
import { CloseSnackbarItem } from 'components/Snackbar/components/SnackbarItem/SnackbarItem.types';
import { SnackbarContentRef } from 'components/Snackbar/components/SnackbarContent/SnackbarContent.types';
import { SnackbarProps } from 'components/Snackbar/Snackbar.types';
import {
  SnackbarApi,
  SnackbarApiOptions,
} from 'components/Snackbar/SnackbarApi.types';

import styles from 'components/Snackbar/Snackbar.scss';

const DEFAULT_DURATION = 0;
const DEFAULT_REMOVE_DELAY = 0;
const DEFAULT_IS_CLOSEABLE = true;
const DEFAULT_RANK = 'auto';

const Snackbar = <I extends string, O extends SnackbarApiOptions>({
  children,
  className,
  component,
  definitions,
}: SnackbarProps<I, O>) => {
  const snackbarContentRef = useRef<SnackbarContentRef>(null);

  const snackbarApi: SnackbarApi<I, O> = useCallback(
    (id, content, options) => {
      const definition = (definitions || []).find(
        (currDefinition) => currDefinition.id === id,
      );
      const { setContainerStyles, setItems } = snackbarContentRef.current || {};

      const snackId = crypto.getRandomValues(new Uint32Array(1))[0];

      if (definition && content && setContainerStyles && setItems) {
        const {
          component: definitionComponent,
          removeDelay,
          defaultDuration,
          defaultIsCloseable,
          defaultLayer,
          defaultRank,
        } = definition;
        const {
          isCloseable = defaultIsCloseable,
          duration = defaultDuration,
          layer = defaultLayer,
          rank = defaultRank,
          className: optionClassName,
          onDismissed = noop,
          onRemoved = noop,
          onDurationPaused = noop,
          onDurationResumed = noop,
          ...customOptions
        } = options ?? {};

        const closeSnackbarItemRef: MutableRefObject<CloseSnackbarItem | null> =
          {
            current: null,
          };

        const snackbarItem = (
          <SnackbarItem
            apiOptions={{
              className: optionClassName ?? '',
              rank: rank ?? DEFAULT_RANK,
              duration: duration ?? DEFAULT_DURATION,
              isCloseable: isCloseable ?? DEFAULT_IS_CLOSEABLE,
              onDismissed,
              onRemoved,
              onDurationPaused,
              onDurationResumed,
              ...customOptions,
            }}
            coreDefinition={{
              id,
              component: definitionComponent,
              removeDelay: removeDelay ?? DEFAULT_REMOVE_DELAY,
            }}
            remove={() =>
              setItems((currentItems) => {
                currentItems.delete(snackbarItem);
                return new Set(currentItems);
              })
            }
            ref={closeSnackbarItemRef}
            key={snackId}
          >
            {content}
          </SnackbarItem>
        );

        if (layer) {
          setContainerStyles({ zIndex: styles[`layer_${layer}`] ?? layer });
        }

        setItems((currentItems) => {
          currentItems.add(snackbarItem);
          return new Set(currentItems);
        });

        return {
          dismiss: (closed) => {
            closeSnackbarItemRef.current?.(!!closed);
          },
        };
      }

      return null;
    },
    [definitions],
  );

  const finalChildren =
    typeof children === 'function' ? children(snackbarApi) : children;

  return (
    <>
      {finalChildren}
      <SnackbarContent
        className={className}
        component={component}
        ref={snackbarContentRef}
      />
    </>
  );
};

export default Snackbar;
