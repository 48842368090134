import React, { useContext } from 'react';
import classnames from 'classnames';

import styles from 'components/List/components/ListItem/ListItem.scss';
import { ListContext } from 'components/List/context';

interface ListItemProps {
  children: React.ReactNode;
  /** Sets a class to give customized s tyles to the div element. */
  className?: string;
  /** The content for the description field */
  description?: React.ReactNode;
}

const ListItem: React.FC<ListItemProps> = ({
  children,
  className,
  description,
  ...other
}) => {
  const { bulletPoints, hasDivider, isMultilineList, size } =
    useContext(ListContext);

  const listItemClassName = classnames(
    {
      [styles.divider]: hasDivider,
      [styles.multiline]: isMultilineList,
    },
    styles[bulletPoints],
    styles[size],
    className,
  );
  return (
    <div className={listItemClassName} {...other}>
      {children}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default ListItem;
