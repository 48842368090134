import React, { forwardRef } from 'react';
import ReactAnimateHeight, { AnimateHeightProps } from 'react-animate-height';
import styles from 'components/AnimateHeight/AnimateHeight.scss';

const AnimateHeight = forwardRef<ReactAnimateHeight, AnimateHeightProps>(
  (
    { duration = 300, easing = styles.animationTimingFunction, ...props },
    ref,
  ) => (
    <ReactAnimateHeight
      {...props}
      ref={ref}
      duration={duration}
      easing={easing}
    >
      {props.children}
    </ReactAnimateHeight>
  ),
);

export default AnimateHeight;
