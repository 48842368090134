import React from 'react';
import classnames from 'classnames';
import { checkPropTypeLabel } from 'components/EnergyEfficiencyLabel/utils';
import { checkPropTypeColor } from 'components/EnergyEfficiencyLabel2021/utils';

import styles from 'components/EnergyEfficiencyLabel/EnergyEfficiencyLabel.scss';

type LabelSize = 'regular' | 'small';

interface EnergyEfficiencyLabelProps {
  className?: string;
  /** Sets the color code. */
  color?: string;
  /** Sets the textual label. */
  label: string;
  /** used only for backwards compitability with EnergyEfficiencyLabel2021 */
  letterSize?: string;
  /** Sets the size of the label */
  size?: LabelSize;
}

const getClassnameByLabel = (energyLabel: string) =>
  `level-${energyLabel?.toLowerCase().replace(/\+/g, 'plus')}`;

const EnergyEfficiencyLabel = ({
  label,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  letterSize,
  className,
  color,
  size = 'regular',
  ...other
}: EnergyEfficiencyLabelProps) => {
  const labelParts = /^([^+]*)(\+*)/.exec(label.toUpperCase());

  const attributes = {
    ...other,
    className: classnames(
      styles.efficiency,
      styles[`${getClassnameByLabel(label)}`],
      styles[size],
      className,
    ),
    style: {},
  };

  if (color) {
    attributes.style = {
      backgroundColor: color,
      borderLeftColor: color,
    };
  }

  return (
    <div {...attributes}>
      <span>{labelParts && labelParts[1]}</span>
      {labelParts && labelParts[2] && (
        <span className={styles.suffix}>{labelParts && labelParts[2]}</span>
      )}
    </div>
  );
};

EnergyEfficiencyLabel.isLabelValid = (label: string) =>
  /^([aA]\+{0,3})$|^[b-gB-G]$/.test(label);

EnergyEfficiencyLabel.propTypes = {
  color: checkPropTypeColor,
  label: checkPropTypeLabel,
};

EnergyEfficiencyLabel.defaultProps = {
  color: null,
  label: 'A',
};

export default EnergyEfficiencyLabel;
