import React from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import classnames from 'classnames';
import { LinkProps } from 'components/Link/Link.types';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

import styles from 'components/Link/Link.scss';

const Link: React.FC<LinkProps> = (props) => {
  const {
    children,
    className,
    disabled,
    glyphAfter,
    glyphBefore,
    layout = 'inline',
    link = 'button',
    onClick = noop,
    theme = 'primary',
    title,
    typography = 'xxsmall',
    underline = false,
    ...other
  } = props;

  const Tag = link;

  const [iconBeforeStyle, iconBeforeClassName] = pseudoIcon(glyphBefore);
  const [iconAfterStyle, iconAfterClassName] = pseudoIcon(glyphAfter, 'after');

  const classNames = classnames(
    styles.link,
    styles[`${theme}Theme`],
    styles[`${typography}Typography`],
    styles[`${layout}`],
    {
      [styles.underline]: underline,
    },
    className,
  );

  return (
    <Tag
      className={classnames(
        classNames,
        iconBeforeClassName,
        iconAfterClassName,
        { [styles.iconBefore]: glyphBefore, [styles.iconAfter]: glyphAfter },
      )}
      disabled={disabled}
      onClick={onClick}
      type={Tag === 'button' ? Tag : null}
      {...other}
      style={{ ...iconBeforeStyle, ...iconAfterStyle }}
      title={title}
    >
      {children && <span className={styles.children}>{children}</span>}
    </Tag>
  );
};

export default Link;
