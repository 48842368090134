import React from 'react';
import classnames from 'classnames';
import Arrow from 'components/Carousel/components/Arrow';
import {
  ArrowAttributesType,
  ScrollDirectionType,
} from 'components/Carousel/Carousel.types';

import styles from 'components/Carousel/Carousel.scss';

interface ArrowsProps {
  arrowAttributes: ArrowAttributesType;
  scrollableStart: boolean;
  scrollableEnd: boolean;
  classNameArrow?: string;
  onLeft: (event: React.MouseEvent) => void;
  onRight: (event: React.MouseEvent) => void;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
  isLastControlDisabled: boolean;
  i18n: { previous: string; next: string };
  scrollDirection: ScrollDirectionType;
}

const Arrows = ({
  arrowAttributes,
  scrollableStart,
  scrollableEnd,
  classNameArrow,
  onLeft,
  onRight,
  onMouseLeave,
  onMouseEnter,
  isLastControlDisabled,
  i18n,
  scrollDirection = 'horizontal',
}: ArrowsProps) => {
  const { type, size, showDisabled } = arrowAttributes;
  const hasHorizontalScrollDirection = scrollDirection === 'horizontal';

  return (
    <>
      <Arrow
        className={classnames(
          styles.arrowButton,
          {
            [styles.horizontalButton]: hasHorizontalScrollDirection,
            [styles.empty]: type === 'empty',
            [styles.fadeOut]: !scrollableStart && !showDisabled,
            [styles.fadeIn]: scrollableStart && !showDisabled,
          },
          classNameArrow,
        )}
        i18n={{ label: i18n.previous }}
        direction={hasHorizontalScrollDirection ? 'previous' : 'up'}
        disabled={!scrollableStart}
        showDisabled={showDisabled}
        onClick={onLeft}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        size={size}
        type={type}
      />
      <Arrow
        className={classnames(
          styles.arrowButton,
          {
            [styles.hidden]:
              !showDisabled && (isLastControlDisabled || !scrollableEnd),
            [styles.empty]: type === 'empty',
            [styles.fadeOut]: !scrollableEnd && !showDisabled,
            [styles.fadeIn]: scrollableEnd && !showDisabled,
            [styles.horizontalButton]: hasHorizontalScrollDirection,
          },
          classNameArrow,
        )}
        i18n={{ label: i18n.next }}
        direction={hasHorizontalScrollDirection ? 'next' : 'down'}
        disabled={isLastControlDisabled || !scrollableEnd}
        showDisabled={showDisabled}
        onClick={onRight}
        size={size}
        type={type}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      />
    </>
  );
};

export default Arrows;
