import { createContext } from 'react';
import { BulletPoints, Size } from 'components/List/List.types';

type ContextProps = {
  bulletPoints: BulletPoints;
  hasDivider: boolean;
  isMultilineList: boolean;
  size: Size;
};

export const ListContext = createContext<ContextProps>({
  bulletPoints: 'bullet',
  hasDivider: false,
  isMultilineList: false,
  size: 'small',
});
