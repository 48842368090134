import React, { useContext, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { CollapsibleContext } from 'components/Accordion/context';
import styles from 'components/Accordion/components/Collapsible/Collapsible.scss';
import { CollapsibleProps } from 'components/Accordion/components/Collapsible/Collapsible.types';
import CollapsibleContent from 'components/Accordion/components/CollapsibleContent/CollapsibleContent';

const Collapsible: React.FC<CollapsibleProps> = ({
  activator,
  activatorClassName,
  buttonWrapperTag,
  className,
  contentClassName,
  'data-purpose': dataPurpose,
  disabled = false,
  glyphBefore,
  hasInlineChevron,
  hasNoSpacingInActivator,
  i18n,
  iconClassName,
  nonExpandableContent,
  isNonExpandableContentClickable,
  subText,
  onToggle,
  defaultOpen,
  cmsId,
  anchorId,
  ...props
}) => {
  const { setCollapsibleIndentation, collapsibleIndentation } =
    useContext(CollapsibleContext);

  useEffect(() => {
    glyphBefore && setCollapsibleIndentation(true);
  }, [glyphBefore, setCollapsibleIndentation]);

  const collapsibleContentProps = useMemo(
    () => ({
      activator,
      activatorClassName,
      buttonWrapperTag,
      contentClassName,
      'data-purpose': dataPurpose,
      defaultOpen,
      disabled,
      glyphBefore,
      hasInlineChevron,
      hasNoSpacingInActivator,
      i18n,
      iconClassName,
      nonExpandableContent,
      isNonExpandableContentClickable,
      subText,
      onToggle,
      children: props.children,
    }),
    [
      activator,
      activatorClassName,
      buttonWrapperTag,
      contentClassName,
      dataPurpose,
      defaultOpen,
      disabled,
      glyphBefore,
      hasNoSpacingInActivator,
      i18n,
      iconClassName,
      nonExpandableContent,
      isNonExpandableContentClickable,
      onToggle,
      props.children,
      subText,
      hasInlineChevron,
    ],
  );

  return useMemo(
    () => (
      <div
        className={className}
        id={anchorId || cmsId}
        data-testid="collapsible.button"
      >
        <div
          className={classnames(styles.collapsibleWrapper, {
            [styles.indent]: collapsibleIndentation && !glyphBefore,
          })}
        >
          <CollapsibleContent {...collapsibleContentProps} />
        </div>
      </div>
    ),
    [
      className,
      anchorId,
      cmsId,
      collapsibleIndentation,
      glyphBefore,
      collapsibleContentProps,
    ],
  );
};
export default Collapsible;
