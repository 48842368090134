import { UseBannerState } from 'components/Banner/Banner.types';
import { cloneElement, ReactElement, useMemo } from 'react';
import classnames from 'classnames';
import styles from 'components/Banner/Banner.scss';

const createContent = (
  link: ReactElement<HTMLLinkElement>,
  content: any,
  isFullyClickable?: boolean,
) =>
  content
    ? cloneElement(
        link,
        {
          className: classnames(styles.link, {
            [styles.isFullyClickable]: isFullyClickable,
          }),
        },
        [content],
      )
    : content;

export interface UseBannerProps {
  title?: string;
  isFullyClickable?: boolean;
  link?: ReactElement<HTMLLinkElement>;
  subTitle?: string;
  linkTitle?: string;
}

export const useBannerState = ({
  title,
  subTitle,
  linkTitle,
  isFullyClickable,
  link,
}: UseBannerProps): UseBannerState => {
  const titleContent = useMemo(
    () =>
      link && !subTitle && !linkTitle
        ? createContent(link, title, isFullyClickable)
        : title,
    [link, linkTitle, isFullyClickable, title, subTitle],
  );

  const textContent = useMemo(
    () => (link ? createContent(link, linkTitle, isFullyClickable) : linkTitle),
    [link, linkTitle, isFullyClickable],
  );

  const subTitleContent = useMemo(
    () =>
      link && !linkTitle
        ? createContent(link, subTitle, isFullyClickable)
        : subTitle,
    [link, linkTitle, isFullyClickable, subTitle],
  );

  const isSubLineShown = Boolean(subTitle || (link && linkTitle));

  return {
    isSubLineShown,
    titleContent,
    textContent,
    subTitleContent,
  };
};
