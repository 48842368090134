import React from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { decodeHtmlEntities } from '@xxxlgroup/hydra-utils/formatting';
import {
  IconButtonProps,
  ButtonType,
} from 'components/IconButton/IconButton.types';
import classnames from 'classnames';

import styles from 'components/IconButton/IconButton.scss';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      active = false,
      ariaLabel,
      className,
      glyph,
      disabled = false,
      layout = 'inline',
      link: Link,
      name,
      onClick = noop,
      title,
      type = 'button',
      value,
      onMouseEnter,
      onMouseLeave,
      ...other
    },
    ref,
  ) => {
    const iconButtonClassNames = [
      styles.button,
      styles[layout],
      { [styles.active]: active },
      className,
    ];

    const [iconStyle, iconClassName] = pseudoIcon(glyph);

    const sanitizedAriaLabel =
      (ariaLabel && decodeHtmlEntities(ariaLabel)) ?? undefined;

    const sanitizedTitle = title ? decodeHtmlEntities(title) : '';

    const buttonAttributes = {
      ref,
      'aria-label': sanitizedAriaLabel,
      className: classnames(iconButtonClassNames, iconClassName),
      disabled: disabled as boolean | undefined,
      name,
      onClick,
      title: sanitizedTitle,
      type: type as ButtonType | undefined,
      value,
      onMouseEnter,
      onMouseLeave,
      ...other,
      style: iconStyle,
    };

    if (Link) {
      delete buttonAttributes.disabled;
      delete buttonAttributes.name;
      delete buttonAttributes.type;
      delete buttonAttributes.value;
      return <Link {...buttonAttributes} />;
    }

    return <button {...buttonAttributes} />;
  },
);

export default IconButton;
