import React, { forwardRef } from 'react';
import classnames from 'classnames';
import Content from 'components/Content';

import styles from 'components/RadioButton/RadioButton.scss';

interface RadioButtonProps {
  /** Value for the checkbox. */
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  /** A more in depth description for the radio button. */
  description?: React.ReactNode;
  /** If true, the checkbox is shown as disabled and cannot be modified. */
  disabled?: boolean;
  /** Name for the input element. */
  name?: string;
  /** Callback function that will be invoked when the input is blurred. */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /** Callback function that will be invoked when the value changes. */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Callback function that will be invoked when the input is focused. */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /** Callback function that will be invoked when the element is clicked. */
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  /** An optional aria-role for the input-field */
  role?: string;
  /** The value of the field to set in the input checkbox. */
  value?: string;
}

// render children or label (handle label deprecation)
const renderMainLabel = (children: React.ReactNode, label?: string) => {
  if (children) {
    return (
      <span className={styles.selectionControlContentChildren}>{children}</span>
    );
  }
  if (label) {
    return (
      <Content
        tag="span"
        className={styles.selectionControlContentChildren}
        content={label}
      />
    );
  }
  return null;
};

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      checked,
      children,
      className,
      description,
      disabled = false,
      name,
      onBlur,
      onChange,
      onFocus,
      onClick,
      value,
      ...other
    },
    ref,
  ) => {
    const inputAttrs = {
      ...other,
      checked,
      className: styles.selectionControlInput,
      disabled,
      name,
      onBlur,
      onChange,
      onFocus,
      onClick,
      type: 'radio',
      value,
    };

    const markerClassName = classnames(
      styles.selectionControlMarker,
      styles.radioButtonMarker,
    );

    const markerBackgroundClassName = classnames(
      styles.selectionControlMarkerBackground,
      styles.radioButtonMarkerBackground,
    );

    const markerIconClassName = classnames(
      styles.selectionControlMarkerIcon,
      styles.radioButtonMarkerIcon,
    );

    return (
      <label className={classnames(className, styles.selectionControl)}>
        <input {...inputAttrs} ref={ref} />
        <span className={markerClassName}>
          <span className={markerBackgroundClassName}>
            <span className={markerIconClassName} />
          </span>
        </span>
        <span className={styles.selectionControlContent}>
          {renderMainLabel(children)}
          {description && (
            <span className={styles.selectionControlContentDescription}>
              {description}
            </span>
          )}
        </span>
      </label>
    );
  },
);

export default RadioButton;
