import React, { memo, ReactNode, useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { ActionType, TabContext } from 'components/Tabs/reducer';
import { scrollIntoView } from 'utils/common';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { Glyph } from 'types/typeDefinitions';

import styles from 'components/Tabs/components/Tab/Tab.scss';

type InteractiveType = React.MouseEvent | React.FocusEvent;
interface TabProps {
  /** Given value declares the content to the related Tab for assistive technologies */
  ariaControls?: string;
  className?: string;
  /** Glyph to display an icon */
  glyph?: Glyph;
  /** This field comes from database */
  id?: string;
  /** The tab's index */
  index?: number;
  /** True if Tab selected, false if not */
  isSelected?: boolean;
  /** displays additional information */
  metaLabel?: string;
  /** declares empty fn() to pass to parent component */
  onClick?: (event: InteractiveType) => void;
  /** declares empty fn() to pass to parent component */
  onFocus?: (event: InteractiveType) => void;
  /** Includes the title of the tab */
  title: string;
  children: ReactNode;
}

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const Tab = ({
  ariaControls = undefined,
  className,
  glyph,
  id,
  index = 0,
  isSelected = false,
  metaLabel,
  onClick = noop,
  onFocus = noop,
  title,
  ...props
}: TabProps) => {
  const { state, dispatch } = useContext(TabContext);
  const [iconStyle, iconClassName] = pseudoIcon(glyph);

  const indexRef = useRef(state.activeIndex);
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
  const tabRef = useRef<HTMLButtonElement>(null);

  const onTabFocus = (
    purpose: (event: InteractiveType) => void,
    event: InteractiveType,
  ) => {
    if (purpose && event) {
      scrollIntoView(event.currentTarget, { horizontal: true });
      purpose(event);
    }
  };

  const onTabClick =
    (purpose: (event: InteractiveType) => void) => (event: InteractiveType) => {
      onTabFocus(purpose, event);
      if (dispatch) {
        dispatch({
          type: ActionType.SETINDEX,
          payload: index,
        });
      }
    };

  useEffect(() => {
    if (indexRef.current !== state.activeIndex && isSelected) {
      tabRef.current?.focus();
    }
    indexRef.current = state.activeIndex;
  }, [isSelected, state]);

  return (
    <div className={className} data-testid="tabWrapper">
      <button
        aria-controls={ariaControls}
        aria-selected={isSelected}
        className={classnames(styles.button, iconClassName)}
        data-index={index}
        id={id}
        onClick={onTabClick(onClick)}
        onFocus={onTabClick(onFocus)}
        ref={tabRef}
        role="tab"
        style={iconStyle}
        tabIndex={isSelected ? 0 : -1}
        title={title}
        type="button"
        {...props}
      >
        {title}
        {metaLabel && <span className={styles.metaLabel}> {metaLabel} </span>}
      </button>
    </div>
  );
};

export default memo(Tab);
