import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'components/Slider/components/Slide/Slide.scss';

/** @deprecated - please don't use this component in other components except within the slider */
// eslint-disable-next-line react/prefer-stateless-function
class Slide extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    /** What kind of arrow should get displayed */
    active: PropTypes.bool,
    /** HTML Elements or components which should get displayed as Slides */
    children: PropTypes.node.isRequired,
    /** Adds the possibility to style the component */
    className: PropTypes.string,
    /* Defines whether multiple slides are displayed or not. */
    multiple: PropTypes.bool,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    active: false,
    className: null,
    multiple: false,
  };

  render() {
    const { active, children, className, multiple, ...other } = this.props;

    return (
      <div
        className={classNames(styles.slide, className, {
          [styles.active]: active,
          [styles.multiple]: multiple,
        })}
        role="group"
        aria-roledescription="slide"
        {...other}
      >
        {children}
      </div>
    );
  }
}

export default Slide;
