import React from 'react';
import classnames from 'classnames';
import { sanitize } from 'isomorphic-dompurify';
import contentSemanticElements from 'components/Content/contentSemanticElements';
import { ContentProps } from 'components/Content/Content.types';
import styles from 'components/Content/Content.scss';

const sanitizeContent = (content: string) =>
  sanitize(content, {
    ALLOWED_TAGS: [
      ...contentSemanticElements.elements,
      ...contentSemanticElements.paragraphPhraseTags,
    ],
    ADD_ATTR: [...contentSemanticElements.addAttr],
    FORBID_ATTR: [...contentSemanticElements.forbiddenAttr],
  }).trim();

const Content: React.FC<ContentProps> = ({
  className,
  content,
  forwardedRef,
  tag: Element = 'div',
  size = 'rg',
  ...other
}) => {
  const elementAttributes = {
    ...other,
    className: classnames(
      styles.component,
      size !== 'inherit' && styles[`${size}`],
      className,
    ),
  };

  if (Array.isArray(content)) {
    elementAttributes.children = [...content];
  } else if (typeof content === 'string') {
    elementAttributes.dangerouslySetInnerHTML = {
      __html: sanitizeContent(content),
    };
  } else {
    elementAttributes.children = content;
  }

  return <Element ref={forwardedRef} {...elementAttributes} />;
};

export default Content;
