import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from 'components/ColorCircle/ColorCircle.scss';
import { multicolor } from '@xxxlgroup/hydra-icons';
import createPropError from 'utils/createPropError';
import svgEncode from '@xxxlgroup/hydra-utils/icon/svgEncode';

type Size = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';

interface ColorCircleProps {
  /** aria-label that should describe the depicted color */
  ariaLabel?: string;
  /** color value as hex code */
  active?: boolean;
  className?: string;
  /** color value as hex code */
  color: string;
  /** disabled state for color circle */
  disabled?: boolean;
  /** Background of container in which the circle is rendered */
  darkBackground?: boolean;
  /** prop for displaying the color circle without hovering effect  */
  onlyColorMode?: boolean;
  /** size of the color circle */
  size: Size;
}

const validateColorProp = (
  props: Record<string, string>,
  propName: string,
  componentName: string,
) => {
  if (
    !/^#?[0-9a-fA-F]{3}$/.test(props[propName]) &&
    !/^#?[0-9a-fA-F]{6}$/.test(props[propName]) &&
    props[propName] !== 'multicolor'
  ) {
    return createPropError(propName, componentName, 'Validation failed!');
  }
  return null;
};

const ColorCircle = ({
  ariaLabel = 'Circle with color filling',
  active = false,
  className,
  color,
  darkBackground = false,
  disabled = false,
  onlyColorMode = true,
  size = 'medium',
  ...other
}: ColorCircleProps) => {
  const hasMultiColor = color === 'multicolor';

  const colorStyle = useMemo(
    () => (hasMultiColor ? undefined : { color }),
    [hasMultiColor, color],
  );

  const circleBorder = darkBackground
    ? styles.circleBorderLight
    : styles.circleBorderDark;

  return (
    <svg
      data-testid="coloredcircle.icon"
      className={classNames(styles.circle, className, styles[size], {
        [styles.active]: active && !disabled,
        [styles.disabled]: disabled || onlyColorMode,
      })}
      role="img"
      aria-label={ariaLabel}
      viewBox="0 0 32 32"
      style={colorStyle}
      {...other}
    >
      {hasMultiColor ? (
        <image className={styles.multicolor} href={svgEncode(multicolor[1])} />
      ) : (
        <circle className={classNames(circleBorder)} cx="16" cy="16" r="16" />
      )}
      {disabled && <rect x="-5" y="13" height="6" width="42" />}
    </svg>
  );
};

ColorCircle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: validateColorProp,
};

ColorCircle.defaultProps = {
  color: 'ff0000',
};

ColorCircle.validateColorProp = validateColorProp;

export default ColorCircle;
