/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Arrow from 'components/Carousel/components/Arrow';
import IndicatorsBar from 'components/Carousel/components/IndicatorsBar/IndicatorsBar';
import Slide from 'components/Slider/components/Slide';
import styles from 'components/Slider/Slider.scss';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';

const sneakPeekTypes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const sneakPeekDirectionTypes = {
  left: 'left',
  both: 'both',
  right: 'right',
};

const shouldShowIndicators = (
  numberOfBigIndicators,
  childrenLength,
  pageCount,
  multiple,
) =>
  (typeof window === 'undefined' &&
    numberOfBigIndicators &&
    childrenLength > 1) ||
  (pageCount > 1 && multiple) ||
  (!multiple && childrenLength > 1);

/** @deprecated - please use Carousel component instead */
class Slider extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    /** Defines the amount of time between two transitions (in ms) */
    animationSpeed: PropTypes.oneOf(['slow', 'fast']),
    /** Renders arrows to navigate through the slides */
    arrows: PropTypes.bool,
    /** What type of arrow should be used */
    arrowType: PropTypes.oneOf(['filled', 'empty']),
    /** Slides automatically */
    autoPlay: PropTypes.bool,
    /** HTML Elements or components which should get displayed as Slides */
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    /** Adds the possibility to style most parts of the component */
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        arrows: PropTypes.string,
        indicators: PropTypes.string,
        slide: PropTypes.string,
        slider: PropTypes.string,
        sliderTrack: PropTypes.string,
        clipper: PropTypes.string,
      }),
    ]),
    /** If activated, the Slider does only respond to arrows and will ignore touch and mouse moves. */
    disableTouch: PropTypes.bool,
    /** Enable navigation of slides via arrow-keys with a global window listener
     * (by default the keyboard-navigation is enabled if a slider-element is focused)
     */
    enableGlobalKeyboardNavigation: PropTypes.bool,
    /** Multiple items will not fill last slide.  */
    lastPageSemiFilled: PropTypes.bool,
    /** Activates the highlighting of the active slide */
    highlightActiveSlide: PropTypes.bool,
    /** Translation for UI elements. */
    i18n: PropTypes.shape({
      next: PropTypes.node.isRequired,
      previous: PropTypes.node.isRequired,
      goto: PropTypes.node.isRequired,
    }).isRequired,
    /** Renders dot-indicators to show the amount of available slides */
    indicators: PropTypes.bool,
    /** Defines the initial index of the visible slide */
    initialPageIndex: PropTypes.number,
    /** Activates endless sliding */
    isLooped: PropTypes.bool,
    /** Defines whether there should get multiple Slides displayed at once or not. */
    multiple: PropTypes.bool,
    /** number of slider big indicators to show (Instagram indicators bar only). */
    numberOfBigIndicators: PropTypes.number,
    /** Attaches an event handler to the slider */
    onPageChange: PropTypes.func,
    /** If activated, the arrows on first/last slide are visible, but greyed out and will prevent the click */
    showDisabledArrow: PropTypes.bool,
    /** Props to be passed to each slide */
    slideOptions: PropTypes.shape(),
    /** Enable the visibility of the partial previous/next slide */
    sneakPeek: PropTypes.oneOf([
      sneakPeekTypes.small,
      sneakPeekTypes.medium,
      sneakPeekTypes.large,
    ]),
    /** Define, where the partial next slide should appear */
    sneakPeekDirection: PropTypes.oneOf([
      sneakPeekDirectionTypes.left,
      sneakPeekDirectionTypes.both,
      sneakPeekDirectionTypes.right,
    ]),
    /** Choose how many slides the next/prev action should move. If 0, all visible slides move */
    step: PropTypes.number,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    animationSpeed: 'slow',
    arrows: false,
    arrowType: 'filled',
    autoPlay: false,
    className: null,
    disableTouch: false,
    enableGlobalKeyboardNavigation: false,
    highlightActiveSlide: false,
    indicators: false,
    initialPageIndex: 0,
    lastPageSemiFilled: false,
    multiple: false,
    numberOfBigIndicators: 3,
    onPageChange: null,
    showDisabledArrow: false,
    slideOptions: {},
    sneakPeek: null,
    sneakPeekDirection: sneakPeekDirectionTypes.both,
    step: null,
  };

  // prevents re-rendering from sub-components
  i18nCache = {
    next: { label: this.props.i18n.next },
    previous: { label: this.props.i18n.previous },
    indicators: { goto: this.props.i18n.goto },
  };

  periodicalExecutor = null;

  requestAnimationFrameStartTime = null;

  mounted = false;

  // eslint-disable-next-line react/state-in-constructor
  state = {
    activeSlideIndex: 0,
    currentPage: this.props.multiple ? 0 : this.props.initialPageIndex,
    isThrowing: false,
    lastPage: 0,
    negativeModuloSlides: 0,
    pageCount: 1,
    visibleSlides: 1,
    visibleSlidesIndexStart: 0,
    visibleSlidesIndexEnd: 0,
  };

  touchStats = {
    diffX: 0,
    isHorizontal: null,
    isMoving: false,
    initialDiffX: 0,
    isTouch: false,
    rubberDivisor: 3,
    startTime: 0,
    startX: 0,
    startY: 0,
    threshold: {
      directionDetectionDelta: 2,
      speed: 0.5,
      swipeToNextPage: 100,
    },
    touchStartedInside: false,
  };

  childCount = 1;

  trackRef = React.createRef();

  sliderRef = React.createRef();

  arrowLeftRef = React.createRef();

  arrowRightRef = React.createRef();

  waitForStyles = 2000;

  trackWidth = 0;

  stylesApplied = false;

  constructor(props) {
    super(props);

    this.childCount = props.children.length;
    if (props.multiple) {
      this.calculatePageCount();
    }
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.multiple) {
      requestAnimationFrame(this.checkIfStylesApplied);
      this.calculatePageCount();
      window.addEventListener('resize', this.resize);
    } else {
      this.initPageCount();
      this.stylesApplied = true;
    }
    this.subscribeKeyboardEvents();
  }

  componentDidUpdate(prevProps) {
    const { children, multiple, step } = this.props;

    if (prevProps.children.length !== children.length) {
      this.initPageCount();
    } else if (multiple !== prevProps.multiple || step !== prevProps.step) {
      this.calculatePageCount().then(() => {
        this.slideToPage(0)();
      });
    }
  }

  componentWillUnmount() {
    this.stopAutoPlay();

    window.removeEventListener('resize', this.resize);
    window.removeEventListener('click', this.stopNestedClick, true);
    window.removeEventListener('focus', this.stopNestedFocus, true);
    window.removeEventListener('touchmove', this.touchMove);
    window.removeEventListener('mousemove', this.touchMove);
    window.removeEventListener('touchend', this.touchEnd);
    window.removeEventListener('mouseup', this.touchEnd);
    window.removeEventListener('keyup', this.handleKeyboardStrokes);

    if (this.sliderRef.current) {
      this.sliderRef.current.removeEventListener(
        'keyup',
        this.handleKeyboardStrokes,
      );
    }

    this.mounted = false;
  }

  initPageCount = () => {
    this.calculatePageCount()
      .then(this.checkAndStartAutoPlay)
      .then(() => {
        const { lastPage } = this.state;
        const initialPage = Math.min(
          Math.max(0, this.props.initialPageIndex),
          lastPage,
        );
        const visibleIndexes = this.getVisibleSlidesIndexes(initialPage);

        this.mounted &&
          this.setState({
            currentPage: initialPage,
            visibleSlidesIndexStart: visibleIndexes.start,
            visibleSlidesIndexEnd: visibleIndexes.end,
          });
      });
  };

  subscribeKeyboardEvents = () => {
    if (this.props.enableGlobalKeyboardNavigation) {
      window.addEventListener('keyup', this.handleKeyboardStrokes);
    }
  };

  /**
   * Although not needed right here, we do need it outside. So this is not unused code.
   * Use it with `ref.current.getCurrentSlideIndex` ... and wisely
   *
   * @param {number} index - index of the slide you want to activate. If out of view, the page gets changed.
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  getCurrentSlideIndex = () => this.state.activeSlideIndex;

  /**
   * Adds an event-listener to the slider for navigating by arrow-keys,
   * if enableGlobalKeyboardNavigation is not set.
   */
  onSliderFocus = () => {
    if (this.sliderRef.current && !this.props.enableGlobalKeyboardNavigation) {
      this.sliderRef.current.addEventListener(
        'keyup',
        this.handleKeyboardStrokes,
      );
    }
  };

  /**
   * Removes an event-listener from the slider, if enableGlobalKeyboardNavigation is not set.
   */
  onSliderBlur = () => {
    if (this.sliderRef.current && !this.props.enableGlobalKeyboardNavigation) {
      this.sliderRef.current.removeEventListener(
        'keyup',
        this.handleKeyboardStrokes,
      );
    }
  };

  /**
   * Reacts to the KeyEvent of a keyboard-stroke
   * @param event
   */
  handleKeyboardStrokes = (event) => {
    const { isLooped } = this.props;
    switch (event.key) {
      case 'ArrowLeft':
        this.changePage('previous', isLooped)(event);
        break;
      case 'ArrowRight':
        this.changePage('next', isLooped)(event);
        break;
      default:
    }
  };

  resize = () => {
    const {
      visibleSlidesIndexStart: oldIndexStart,
      visibleSlides: oldVisibleSlides,
    } = this.state;

    this.calculatePageCount().then(() => {
      const { visibleSlidesIndexStart, visibleSlides, activeSlideIndex } =
        this.state;

      if (
        oldIndexStart !== visibleSlidesIndexStart ||
        oldVisibleSlides !== visibleSlides
      ) {
        this.calculateVisibleSlidesForIndex(activeSlideIndex);
      }
    });
  };

  calculateInitialPage = () => {
    let initialPage = this.props.initialPageIndex;

    if (initialPage > this.state.lastPage) {
      initialPage = this.state.lastPage;
    } else if (initialPage < 0) {
      initialPage = 0;
    }

    const visibleIndexes = this.getVisibleSlidesIndexes(initialPage);

    return this.setStateWithPromise({
      currentPage: initialPage,
      visibleSlidesIndexStart: visibleIndexes.start,
      visibleSlidesIndexEnd: visibleIndexes.end,
    });
  };

  // sometimes we need to wait for the changes done by `setState`. To escape the callback hell, we're using promises.
  setStateWithPromise = (state) =>
    new Promise((resolve) => {
      this.mounted ? this.setState(state, resolve) : resolve();
    });

  // If we don't know how much slides are on one page (prop: multiple), we need to calculate it. React as well as
  // the browser does not provide us with an event where the styles are applied to the DOM-Elements. And because
  // the application of the styles does always happen after componentDidMount we need to wait for them manually.
  // This is where `requestAnimationFrame` comes in handy.
  checkIfStylesApplied = () => {
    if (this.trackRef.current) {
      if (!this.requestAnimationFrameStartTime) {
        this.requestAnimationFrameStartTime = Date.now();
      }

      this.calculatePageCount()
        .then(this.calculateInitialPage)
        .then(this.checkIfStylesCheckShouldRepeat);
    } else {
      requestAnimationFrame(this.checkIfStylesApplied);
    }
  };

  ensureChildrenAndStylesAreApplied = () =>
    new Promise((resolve) => {
      const waitForStylesApplied = () => {
        if (
          this.stylesApplied &&
          this.childCount === this.trackRef?.current?.children.length
        ) {
          return resolve();
        }
        return setTimeout(waitForStylesApplied, 1);
      };

      waitForStylesApplied();
    });

  checkIfStylesCheckShouldRepeat = () => {
    if (
      this.trackRef.current.children.length < 2 ||
      this.trackRef.current.getBoundingClientRect().width > 0 ||
      Date.now() - this.requestAnimationFrameStartTime > this.waitForStyles
    ) {
      if (!this.stylesApplied) {
        this.stylesApplied = true;
        this.checkIfStylesApplied();
      } else {
        this.checkAndStartAutoPlay();
      }
    } else {
      requestAnimationFrame(this.checkIfStylesApplied);
    }
  };

  getAnimationSpeedInterval = () => {
    const { animationSpeed } = this.props;
    return animationSpeed === 'slow' ? 7000 : 4000;
  };

  checkAndStartAutoPlay = () => {
    const preferReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)',
    );
    if (
      !preferReducedMotion ||
      (!preferReducedMotion.matches &&
        this.props.autoPlay &&
        this.state.pageCount > 1 &&
        !this.periodicalExecutor)
    ) {
      this.periodicalExecutor = setInterval(
        this.changePage('next', true),
        this.getAnimationSpeedInterval(),
      );
    }
  };

  stopAutoPlay = () => {
    if (this.periodicalExecutor) {
      clearInterval(this.periodicalExecutor);
      this.periodicalExecutor = null;
    }
  };

  // Move focus to Slider component to make sure no keyboard shows up on mobile if an input element was previously focused
  focusOnSliderAfterPageChanging = (direction) => {
    const { arrows } = this.props;

    if (arrows && direction === 'next') {
      this.arrowRightRef.current.focus();
    } else if (arrows && direction === 'previous') {
      this.arrowLeftRef.current.focus();
    } else {
      this.sliderRef.current.focus();
    }
  };

  /**
   * @param {string} direction - can be 'next' or 'previous'
   * @param {boolean} [loop=false] - go to first page if 'next' on last or last page if 'previous' on first page.
   */
  changePage =
    (direction, loop = false) =>
    (event) => {
      const { onPageChange } = this.props;

      if (event?.cancelable) {
        event.preventDefault();

        this.focusOnSliderAfterPageChanging(direction);

        this.stopAutoPlay();
      }

      const { currentPage, lastPage } = this.state;

      if (this.isLastPage() && direction === 'next' && !loop) {
        return;
      }
      if (this.isFirstPage() && direction === 'previous' && !loop) {
        return;
      }

      let newPage = currentPage + (direction === 'next' ? 1 : -1);
      const oldPage = currentPage;

      if (newPage > lastPage) {
        newPage = 0;
      } else if (newPage < 0) {
        newPage = lastPage;
      }

      const visibleIndexes = this.getVisibleSlidesIndexes(newPage);

      this.mounted &&
        this.setState({
          currentPage: newPage,
          visibleSlidesIndexStart: visibleIndexes.start,
          visibleSlidesIndexEnd: visibleIndexes.end,
        });

      if (onPageChange) {
        onPageChange(event, oldPage, newPage);
      }
    };

  /**
   * @param {number} index - index of the page you want to slide to.
   */
  slideToPage = (newPage) => (event) => {
    if (event) {
      this.stopAutoPlay();
    }

    const { onPageChange } = this.props;
    const { currentPage, lastPage } = this.state;

    const calculatedNewPage = Math.max(0, Math.min(lastPage, newPage));

    const oldPage = currentPage;
    const visibleIndexes = this.getVisibleSlidesIndexes(calculatedNewPage);

    this.mounted &&
      this.setState({
        currentPage: calculatedNewPage,
        visibleSlidesIndexStart: visibleIndexes.start,
        visibleSlidesIndexEnd: visibleIndexes.end,
      });

    if (onPageChange) {
      onPageChange(event, oldPage, calculatedNewPage);
    }
  };

  calculateVisibleSlidesForIndex = (index) =>
    new Promise((resolve) => {
      this.ensureChildrenAndStylesAreApplied().then(() => {
        const { lastPage, currentPage } = this.state;

        const oldPage = currentPage;

        const newPage = Math.min(
          lastPage,
          Math.floor(index / this.getCalculatedStep()),
        );
        const visibleIndexes = this.getVisibleSlidesIndexes(newPage);

        this.mounted &&
          this.setState({
            currentPage: newPage,
            visibleSlidesIndexStart: visibleIndexes.start,
            visibleSlidesIndexEnd: visibleIndexes.end,
          });

        resolve({ oldPage, newPage });
      });
    });

  /**
   * We need this method as a way to connect two slider from outside.
   * Use it with `ref.current.setActiveSlideIndex` ... and wisely
   *
   * @param {number} index - index of the slide you want to activate. If out of view, the page gets changed.
   * @param {boolean} supressPageChange - won't change page on setting index.
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  setActiveSlideIndex = (index, supressPageChange = false) => {
    const { onPageChange } = this.props;

    if (supressPageChange) {
      this.mounted &&
        this.setState({
          activeSlideIndex: index,
        });
    } else {
      const { visibleSlidesIndexStart, visibleSlidesIndexEnd } = this.state;

      if (index < visibleSlidesIndexStart || index > visibleSlidesIndexEnd) {
        this.calculateVisibleSlidesForIndex(index).then((pageValues) => {
          this.mounted &&
            this.setState({
              activeSlideIndex: index,
            });

          if (onPageChange && pageValues.oldPage !== pageValues.newPage) {
            onPageChange(null, pageValues.oldPage, pageValues.newPage);
          }
        });
      } else {
        this.mounted &&
          this.setState({
            activeSlideIndex: index,
          });
      }
    }
  };

  isFirstPage = () => this.state.currentPage === 0;

  isLastPage = () => this.state.currentPage === this.state.lastPage;

  getVisibleSlides = (currentTrackRef) => {
    if (!this.props.multiple) {
      return 1;
    }

    const { marginRight, marginLeft } = getComputedStyle(
      currentTrackRef.children[0],
    );

    const margin = parseInt(marginRight, 10) + parseInt(marginLeft, 10);

    const trackWidth = currentTrackRef.getBoundingClientRect().width - margin;
    const slideWidth =
      currentTrackRef.children[0].getBoundingClientRect().width + margin;
    this.touchStats.initialDiffX = 0;

    return Math.round(trackWidth / slideWidth) || 1;
  };

  getVisibleSlidesIndexes = (page) => {
    const { visibleSlides } = this.state;

    let visibleIndexStart = page * this.getCalculatedStep();
    let visibleIndexEnd = visibleIndexStart + visibleSlides - 1;

    if (visibleIndexEnd > this.childCount - 1) {
      visibleIndexEnd = this.childCount - 1;
      visibleIndexStart = Math.max(0, visibleIndexEnd - visibleSlides + 1);
    }

    return {
      start: visibleIndexStart,
      end: visibleIndexEnd,
    };
  };

  calculatePageCount = () => {
    const { children, multiple } = this.props;

    // We need this to check the length of children, because if there is only one child,
    // `children` is not an array.
    this.childCount = children.length ? children.length : 1;

    if (!multiple) {
      return this.setStateWithPromise({
        lastPage: this.childCount - 1,
        pageCount: this.childCount,
      });
    }

    if (this.childCount < 2) {
      return this.setStateWithPromise({ lastPage: 0, pageCount: 1 });
    }

    if (this.trackRef.current) {
      const visibleSlides = this.getVisibleSlides(this.trackRef.current);
      const calculatedStep = this.getCalculatedStep(visibleSlides);
      const slidesDiff = visibleSlides - calculatedStep;
      const pageCount = Math.ceil(
        (this.childCount - slidesDiff) / calculatedStep,
      );

      const lastPage = pageCount > 0 ? pageCount - 1 : pageCount;

      const negativeModuloSlides =
        calculatedStep -
        (this.childCount - (lastPage * calculatedStep + slidesDiff));

      return this.setStateWithPromise({
        lastPage,
        negativeModuloSlides,
        pageCount,
        visibleSlides,
      });
    }

    return this.setStateWithPromise({});
  };

  calculatePosition = () => {
    const { lastPageSemiFilled, sneakPeek, step } = this.props;
    const {
      currentPage,
      negativeModuloSlides,
      lastPage,
      visibleSlides,
      pageCount,
    } = this.state;

    const singleSlideInPercent = 100 / visibleSlides;
    const multiplier =
      !step || step < 1 ? 100 : singleSlideInPercent * this.getCalculatedStep();

    const sneakPeekSpacing =
      sneakPeek && pageCount > 1 ? this.calculateSneakPeekStyle() : '';

    const percentage = currentPage * multiplier;
    const { diffX } = this.touchStats;
    const isZero = percentage === 0 && diffX === 0 && !sneakPeekSpacing;

    if (
      !lastPageSemiFilled &&
      currentPage === lastPage &&
      negativeModuloSlides > 0 &&
      pageCount > 1
    ) {
      return {
        transform: `translate(calc(-${percentage}% + ${
          negativeModuloSlides * singleSlideInPercent
        }% - ${diffX}px${sneakPeekSpacing}))`,
      };
    }

    return isZero
      ? null
      : {
          transform: `translate(calc(-${percentage}% - ${diffX}px${sneakPeekSpacing}))`,
        };
  };

  getCalculatedStep = (currentVisibleSlides) => {
    const { step } = this.props;
    const visibleSlides = currentVisibleSlides ?? this.state.visibleSlides;
    return !step || step < 1 ? visibleSlides : step;
  };

  stopNestedClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.removeEventListener('click', this.stopNestedClick, true);
  };

  stopNestedFocus = (event) => {
    event.preventDefault();
    window.removeEventListener('focus', this.stopNestedFocus, true);
  };

  touchStart = (event) => {
    if (event.type === 'touchstart') {
      this.touchStats.isTouch = true;
      this.touchStats.startX = event.touches[0].clientX;
      this.touchStats.startY = event.touches[0].clientY;
    } else if (!this.touchStats.isTouch) {
      this.touchStats.startX = event.clientX;
      this.touchStats.startY = event.clientY;
    }

    this.stopAutoPlay();

    this.touchStats.touchStartedInside = true;
    this.touchStats.diffX = this.touchStats.initialDiffX;
    this.touchStats.isHorizontal = null;
    this.touchStats.startTime = Date.now();

    this.trackWidth = this.trackRef.current
      ? this.trackRef.current.getBoundingClientRect().width
      : 0;

    window.addEventListener('focus', this.stopNestedFocus, true);
    window.addEventListener('touchmove', this.touchMove);
    window.addEventListener('mousemove', this.touchMove);
    window.addEventListener('touchend', this.touchEnd);
    window.addEventListener('mouseup', this.touchEnd);
  };

  touchMove = (event) => {
    const {
      isTouch,
      rubberDivisor,
      startX,
      startY,
      threshold,
      touchStartedInside,
    } = this.touchStats;
    if (!touchStartedInside || (event.type === 'mousemove' && isTouch)) {
      return;
    }

    let diffX = 0;
    let diffY = 0;

    if (isTouch) {
      diffX = startX - event.touches[0].clientX;
      diffY = startY - event.touches[0].clientY;
    } else {
      diffX = startX - event.clientX;
      diffY = startY - event.clientY;
    }

    if (this.trackWidth) {
      if (diffX > this.trackWidth) {
        diffX = this.trackWidth;
      } else if (diffX < -this.trackWidth) {
        diffX = -this.trackWidth;
      }
    }

    // Only if no direction is detected yet, we will try to detect it.
    if (this.touchStats.isHorizontal === null) {
      // calculate the current radial distance moved from starting point
      const delta = Math.sqrt(diffX * diffX + diffY * diffY);

      // the direction-detection should only happen if there is a significant travel distance
      if (delta > threshold.directionDetectionDelta) {
        this.touchStats.isHorizontal = Math.abs(diffX) >= Math.abs(diffY);
      }
    }

    if ((this.isFirstPage() && diffX < 0) || (this.isLastPage() && diffX > 0)) {
      diffX /= rubberDivisor;
    }

    diffX += this.touchStats.initialDiffX;

    // save for later use
    this.touchStats.diffX = diffX;

    // To enable instant responsiveness of the touch event, we don't want to rely on any internal state handling
    // which is always async. So we're saving the value in an internal prop and manipulating the dom node via style.
    // To achieve this, we need `forceUpdate` ... again
    if (this.touchStats.isHorizontal === null || this.touchStats.isHorizontal) {
      this.forceUpdate();
    }

    if (this.touchStats.isHorizontal) {
      // We want to try to prevent vertical scrolling as soon as we know it is a horizontal swipe
      event.preventDefault();
    }

    this.touchStats.isMoving = true;
  };

  touchEnd = (event) => {
    const { isLooped } = this.props;
    this.touchStats.touchStartedInside = false;
    this.touchStats.isMoving = false;

    const { diffX, isHorizontal, isTouch, threshold } = this.touchStats;
    if (event.type === 'mouseup') {
      if (isHorizontal !== null) {
        // isHorizontal is not null anymore as soon as the user swipes.
        // So no clicks allowed as soon as we swipe in any direction!
        window.addEventListener('click', this.stopNestedClick, true);
      }

      if (isTouch) {
        this.touchStats.isTouch = false;
        return;
      }
    }

    this.touchStats.diffX = this.touchStats.initialDiffX;

    window.removeEventListener('touchmove', this.touchMove);
    window.removeEventListener('mousemove', this.touchMove);
    window.removeEventListener('touchend', this.touchEnd);
    window.removeEventListener('mouseup', this.touchEnd);

    if (isHorizontal) {
      const { currentPage, visibleSlides } = this.state;

      const singleSlideInPixel = this.trackWidth / visibleSlides;

      // If half a slide is smaller than the threshold, we must use a smaller value.
      // Otherwise we're not able to swipe only one slide
      const usedDeltaXThreshold = Math.min(
        singleSlideInPixel / 2,
        threshold.swipeToNextPage,
      );

      const touchTime = Date.now() - this.touchStats.startTime;
      const speed = diffX / touchTime;

      // Only swipe if the horizontal threshold is reached or the swiping is fast
      if (
        Math.abs(speed) > threshold.speed ||
        Math.abs(diffX) > usedDeltaXThreshold
      ) {
        const { multiple, step } = this.props;

        this.mounted &&
          this.setState({
            isThrowing: true,
          });

        // `isThrowing` is setting a CSS class with touch-optimized animation values.
        // after the animation, we remove this CSS class with this timeout.
        // We could use transitionend event here, but the overhead to get the DOM element
        // for handling the event only for 300ms is too high.
        setTimeout(() => {
          this.mounted &&
            this.setState({
              isThrowing: false,
            });
        }, 300);

        if (multiple && step && step > 0) {
          const usedFunction = speed > 0 ? 'ceil' : 'floor';
          const movedSlides = Math[usedFunction](diffX / singleSlideInPixel);
          const movedPages = Math[usedFunction](
            movedSlides / this.getCalculatedStep(),
          );

          this.slideToPage(currentPage + movedPages)(null);
        } else {
          this.changePage(diffX > 0 ? 'next' : 'previous', isLooped)(event);
        }

        // if we actually swiped to the next slide we now want to deregister
        // the click event-handler, so the first click on the slide actually works
        window.removeEventListener('click', this.stopNestedClick, true);
      }
    }

    this.forceUpdate();
  };

  isInView = (index) => {
    // If actual Slide is not in view, it has the attribute 'inert' to prevent any user interaction
    // https://github.com/WICG/inert

    const { visibleSlidesIndexStart, visibleSlidesIndexEnd } = this.state;
    return index >= visibleSlidesIndexStart && index <= visibleSlidesIndexEnd;
  };

  renderSlide = (child, index) => {
    const { activeSlideIndex } = this.state;
    const { className, highlightActiveSlide, multiple, slideOptions } =
      this.props;
    const inView = this.isInView(index);

    return (
      <Slide
        key={`${index}${inView}`}
        className={className?.slide}
        active={highlightActiveSlide && activeSlideIndex === index}
        multiple={multiple}
        aria-hidden={inView ? null : 'true'}
        inert={inView ? null : 'true'}
        {...slideOptions}
      >
        {child}
      </Slide>
    );
  };

  renderArrows = () => {
    const {
      arrows,
      children,
      className,
      isLooped,
      multiple,
      arrowType,
      showDisabledArrow,
    } = this.props;

    return (
      <div
        key={`arrows-slide-count-${children.length}`}
        className={classNames(styles.arrows, className?.arrows)}
      >
        <Arrow
          type={arrowType}
          disabled={!isLooped && this.isFirstPage()}
          direction="previous"
          i18n={this.i18nCache.previous}
          onClick={this.changePage('previous', isLooped)}
          onFocus={this.onSliderFocus}
          onBlur={this.onSliderBlur}
          showOnlyOnFocus={!arrows}
          size={!multiple && !arrows ? 'large' : 'small'}
          showDisabled={showDisabledArrow}
          data-testid="slide-arrow-prev"
          ref={this.arrowLeftRef}
        />
        <Arrow
          type={arrowType}
          disabled={!isLooped && this.isLastPage()}
          direction="next"
          i18n={this.i18nCache.next}
          onClick={this.changePage('next', isLooped)}
          onFocus={this.onSliderFocus}
          onBlur={this.onSliderBlur}
          showOnlyOnFocus={!arrows}
          size={!multiple && !arrows ? 'large' : 'small'}
          showDisabled={showDisabledArrow}
          data-testid="slide-arrow-next"
          ref={this.arrowRightRef}
        />
      </div>
    );
  };

  renderIndicatorsBar = () => {
    const { className, numberOfBigIndicators } = this.props;
    const { pageCount, currentPage } = this.state;

    return (
      <IndicatorsBar
        activeIndex={currentPage}
        className={className?.indicators}
        i18n={this.i18nCache.indicators}
        numberOfBigIndicators={numberOfBigIndicators}
        numberOfIndicators={pageCount}
        onClick={this.slideToPage}
        onFocus={this.onSliderFocus}
        onBlur={this.onSliderBlur}
      />
    );
  };

  // prevents images to be dragged out of the browser in Firefox and Safari.
  // eslint-disable-next-line class-methods-use-this
  preventImageDrag = (event) => {
    event.preventDefault();
  };

  calculateSneakPeekSize = (half = false) => {
    const { sneakPeek } = this.props;
    const { visibleSlides } = this.state;

    let size;

    switch (sneakPeek) {
      case sneakPeekTypes.small:
        size = 100 / (visibleSlides * 4 + 1);
        break;
      case sneakPeekTypes.medium:
        size = 100 / (visibleSlides * 3 + 1);
        break;
      case sneakPeekTypes.large:
        size = 100 / (visibleSlides * 2 + 1);
        break;
      default:
        size = 0;
        break;
    }

    return `${half ? size / 2 : size}%`;
  };

  calculateSneakPeekStyle = () => {
    const { sneakPeekDirection } = this.props;

    const sliderWidth = this.sliderRef.current
      ? this.sliderRef.current.getBoundingClientRect().width
      : 0;

    if (sliderWidth) {
      const parsedSneakPeekSize = parseFloat(
        this.calculateSneakPeekSize(true).slice(0, -1),
      );
      const sneakPeekPixels = Math.round(
        (sliderWidth / 100) * parsedSneakPeekSize,
      );

      if (
        this.isFirstPage() ||
        sneakPeekDirection === sneakPeekDirectionTypes.right
      ) {
        return ` - ${sneakPeekPixels}px`;
      }
      if (
        this.isLastPage() ||
        sneakPeekDirection === sneakPeekDirectionTypes.left
      ) {
        return ` + ${sneakPeekPixels}px`;
      }
    }
    return '';
  };

  renderSneakPeekButtons = () => {
    const { sneakPeekDirection } = this.props;

    let buttonPrevious = null;
    let buttonNext = null;

    const sneakPeekButtonAttributes = {
      onMouseDown: this.touchStart,
      onTouchStart: this.touchStart,
    };

    if (
      this.isLastPage() ||
      (!this.isFirstPage() &&
        sneakPeekDirection === sneakPeekDirectionTypes.both)
    ) {
      buttonPrevious = (
        <button
          {...sneakPeekButtonAttributes}
          data-purpose="slider.sneakPeek.previous"
          className={classNames(styles.sneakPeekButton, styles.previous)}
          style={{ width: this.calculateSneakPeekSize(!this.isLastPage()) }}
          aria-hidden="true"
          onClick={this.changePage('previous')}
        />
      );
    }

    if (
      this.isFirstPage() ||
      (!this.isLastPage() &&
        sneakPeekDirection === sneakPeekDirectionTypes.both)
    ) {
      buttonNext = (
        <button
          {...sneakPeekButtonAttributes}
          data-purpose="slider.sneakPeek.next"
          className={classNames(styles.sneakPeekButton, styles.next)}
          style={{ width: this.calculateSneakPeekSize(!this.isFirstPage()) }}
          aria-hidden="true"
          onClick={this.changePage('next')}
        />
      );
    }

    return (
      <>
        {buttonPrevious}
        {buttonNext}
      </>
    );
  };

  render() {
    const {
      animationSpeed,
      arrows,
      arrowType,
      autoPlay,
      children,
      className,
      disableTouch,
      highlightActiveSlide,
      i18n,
      indicators,
      initialPageIndex,
      isLooped: __,
      lastPageSemiFilled,
      multiple,
      numberOfBigIndicators,
      onPageChange,
      enableGlobalKeyboardNavigation,
      showDisabledArrow,
      slideOptions: _,
      sneakPeek,
      sneakPeekDirection,
      step: ___,
      ...other
    } = this.props;
    const { isThrowing, pageCount } = this.state;
    const renderIndicators = shouldShowIndicators(
      numberOfBigIndicators,
      children.length,
      pageCount,
      multiple,
    );

    let sliderClass = null;

    if (typeof className === 'string') {
      sliderClass = className;
    } else if (className?.slider) {
      sliderClass = className.slider;
    }

    const trackAttributes = {
      className: classNames(styles.track, className?.sliderTrack, {
        [styles.isMoving]: this.touchStats.isMoving,
        [styles.isThrowing]: isThrowing,
      }),
      style: this.calculatePosition(),
    };

    let clipperAttributes = {
      className: classNames(styles.clipper, className?.clipper),
      'data-testid': 'slider-clipper',
    };

    if (sneakPeek && pageCount > 1) {
      const calculatedSize = this.calculateSneakPeekSize(true);

      clipperAttributes = {
        ...clipperAttributes,
        style: {
          paddingLeft: calculatedSize,
          paddingRight: calculatedSize,
        },
      };
    }

    if (!disableTouch && pageCount > 1) {
      trackAttributes.onTouchStart = this.touchStart;
      trackAttributes.onMouseDown = this.touchStart;
    }

    return (
      <section
        key={`slide-count-${this.childCount}`}
        ref={this.sliderRef}
        onDragStart={this.preventImageDrag}
        onFocus={this.stopAutoPlay}
        onBlur={this.checkAndStartAutoPlay}
        onMouseEnter={this.stopAutoPlay}
        onMouseLeave={this.checkAndStartAutoPlay}
        className={classNames(
          styles.slider,
          {
            [styles.multiple]: multiple,
          },
          sliderClass,
        )}
        {...other}
      >
        {multiple
          ? this.renderArrows()
          : this.childCount > 1 && this.renderArrows()}
        <div {...clipperAttributes}>
          <div ref={this.trackRef} {...trackAttributes}>
            {!isArrayEmpty(children) && this.childCount > 1
              ? children.map(this.renderSlide)
              : this.renderSlide(children, 0)}
          </div>
          {sneakPeek && this.renderSneakPeekButtons()}
        </div>
        {indicators && renderIndicators && this.renderIndicatorsBar()}
      </section>
    );
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  }
}

export default Slider;
