import React from 'react';
import classnames from 'classnames';
import { chevronLeft, chevronRight } from '@xxxlgroup/hydra-icons';
import styles from 'components/Tabs/components/ArrowButton/ArrowButton.scss';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

interface ArrowButtonProps {
  onClick: () => void;
  direction: 'previous' | 'next';
}

const ARROW_ICON = {
  next: chevronRight,
  previous: chevronLeft,
};

const ArrowButton = ({ onClick, direction }: ArrowButtonProps) => {
  const [iconStyle, iconClassName] = pseudoIcon(ARROW_ICON[direction], 'after');

  return (
    <button
      className={classnames(styles.arrow, styles[direction], iconClassName)}
      aria-label={direction}
      onClick={onClick}
      style={iconStyle}
    />
  );
};

export default ArrowButton;
