import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import HorizontalStepContent from 'components/ProgressStepper/components/ProgressStepperHorizontal/components/HorizontalStepContent/HorizontalStepContent';
import {
  ProgressStepperDirectionProps,
  StepIconPropsType,
} from 'components/ProgressStepper/ProgressStepper.types';
import StepIcon from 'components/ProgressStepper/components/ProgressStep/components/StepIcon';

import styles from 'components/ProgressStepper/components/ProgressStepperHorizontal/ProgressStepperHorizontal.scss';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { prepareStepsProps } from 'components/ProgressStepper/utils/prepareStepsProps';

const ProgressStepperHorizontal: FC<ProgressStepperDirectionProps> = ({
  isClickOnInactiveDisabled,
  isClickOnFinishedDisabled,
  className,
  current = 0,
  onChange = noop,
  children,
}) => {
  const [currentInteraction, setCurrentInteraction] = useState<number>(current);
  const [stepsProps, setStepProps] = useState<Array<StepIconPropsType>>([]);
  useEffect(() => {
    setCurrentInteraction(current);
  }, [current]);

  const handleOnStepChange = useCallback(
    (newCurrent: number) => () => {
      onChange(newCurrent);
      setCurrentInteraction(newCurrent);
    },
    [onChange],
  );

  /**
   * Takes the ProgressStep children and extracts the props,
   * adds the properties stepNumber, stepIndex, onClick, disableClick and status
   */
  const prepareStepsPropsState = useCallback(() => {
    setStepProps(
      prepareStepsProps(
        children,
        handleOnStepChange,
        current,
        isClickOnFinishedDisabled,
        isClickOnInactiveDisabled,
        currentInteraction,
      ),
    );
  }, [
    children,
    current,
    currentInteraction,
    isClickOnFinishedDisabled,
    isClickOnInactiveDisabled,
    handleOnStepChange,
  ]);

  useEffect(() => {
    prepareStepsPropsState();
  }, [current, currentInteraction, prepareStepsPropsState]);

  /**
   * Given props of single step, it renders the icon with absolute positioning.
   * Setting css-props: left, right
   */
  const renderStepIcon = useCallback(
    (stepProps: StepIconPropsType) => (
      <li
        className={classNames(
          styles.listStep,
          styles[`status-${stepProps.status}`],
          styles[
            `current-interaction-status-${stepProps.currentInteractionStatus}`
          ],
          { [styles.activeLine]: stepProps.stepIndex < current },
          stepProps.className,
        )}
        key={stepProps.stepIndex}
        data-purpose={`progress-step-li-icon-${stepProps.stepIndex}`}
      >
        <StepIcon
          isClickDisabled={stepProps.isClickDisabled}
          glyph={stepProps.glyph}
          glyphFinished={stepProps.glyphFinished}
          onClick={stepProps.onClick}
          status={stepProps.status}
          stepNumber={stepProps.stepNumber}
          title={stepProps.title}
          className={styles.stepIcon}
        />
      </li>
    ),
    [current],
  );

  const renderStepContent = (stepIconProps: StepIconPropsType) => (
    <HorizontalStepContent
      status={stepIconProps.currentInteractionStatus}
      title={stepIconProps.title}
      subTitle={stepIconProps.subTitle}
      key={stepIconProps.stepIndex}
      data-purpose={`progress-step-li-content-${stepIconProps.stepIndex}`}
    >
      {stepIconProps.children}
    </HorizontalStepContent>
  );

  return (
    <div className={className}>
      <ul className={classNames(styles.root)}>
        {stepsProps.map(renderStepIcon)}
      </ul>
      <div className={classNames(styles.contentWrapper)}>
        {stepsProps.map(renderStepContent)}
      </div>
    </div>
  );
};

export default ProgressStepperHorizontal;
