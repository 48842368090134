import React, { AriaRole, ComponentPropsWithRef } from 'react';

export type OnSlideFunc = (
  oldIndex: number,
  newIndex: number,
  event?: Event,
) => void;

export type ArrowAttributesType = {
  showDisabled?: boolean;
  size?: ArrowSize;
  type?: ArrowType;
  onArrowClick: (event: React.MouseEvent) => void;
};

export enum Direction {
  LEFT = 'Left',
  RIGHT = 'Right',
}

export interface CarouselProps extends ComponentPropsWithRef<'div'> {
  /** Slides automatically */
  autoPlay: boolean;
  /** Defines the amount of time between two transitions (in ms) if autoPlay is activated */
  animationSpeed: AnimationSpeed;
  /** define custom props for arrows */
  arrowAttributes?: ArrowAttributesType;
  /** @ignore */
  className?: string;
  /** Adds the possibility to style the arrow components */
  classNameArrow?: string;
  /** Adds the possibility to style the container */
  classNameContainer?: string;
  /** @ignore */
  children: React.ReactNode;
  /** displays the slide in full width */
  fullWidth?: boolean;
  /** Adds the possibility to infinitely loop through the slides */
  isLooped?: boolean;
  /** Translations for previous/next arrows and goTo indicators */
  i18n: { previous: string; next: string; goTo: string };
  /** Function that is called when the slide changed */
  onSlideChanged?: OnSlideFunc;
  /** Add the possibility to change container role for specific accessibility cases */
  roleContainer?: AriaRole;
  /** Carousel used for horizontal or vertical movements */
  scrollDirection?: ScrollDirectionType;
  /** If true, carousel will scroll to the edge of the element */
  sneakPeekDisabled: boolean;
}

export type ArrowSize = 'large' | 'small';
export type ArrowType = 'filled' | 'empty';
export type AnimationSpeed = 'slow' | 'fast';
export type ScrollDirectionType = 'horizontal' | 'vertical';
