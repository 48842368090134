import React from 'react';
import classnames from 'classnames';
import Label from 'components/Label';
import styles from 'components/Input/components/InputLabel/InputLabel.scss';

interface InputLabelProps {
  /** Evaluate if there a value to display  */
  hasValue: () => boolean;
  /** Hide label, but make it accessible to screenreaders still */
  hideLabel: boolean;
  /** Unique identifier */
  id?: string;
  /** Translations for the meaning of the asterisk and aria-label's and title's for icons and iconButtons */
  i18n: {
    mandatory: string;
  };
  /** Text label to attach next to the input field element */
  label: string;
  /** The symbol(text) after placeholder and label if the field is optional (not mandatory) */
  optionalSymbol?: string;
  refId: string;
  /** The input field is mandatory and must be filled. *Notice:* An asterisk is automatically appended to the label */
  required: boolean;
  /** The required symbol(text) after placeholder if the field is mandatory */
  requiredSymbol?: string;
}

const InputLabel = ({
  hasValue,
  hideLabel,
  id,
  refId,
  i18n,
  label,
  optionalSymbol,
  required,
  requiredSymbol,
}: InputLabelProps) => {
  const labelAttributes = {
    className: classnames(styles.label, {
      [styles.labelHidden]: hideLabel,
    }),
    content: label,
    i18n,
    id,
    optionalSymbol,
    refId,
    required,
    requiredSymbol,
    visible: hasValue(),
  };
  return <Label {...labelAttributes} />;
};

export default InputLabel;
