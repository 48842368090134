import React from 'react';
import { ErrorsType } from 'components/Input/Input.types';
import Content from 'components/Content';

import styles from 'components/Input/components/Errors/Errors.scss';

interface ErrorsProps {
  errors: ErrorsType;
  id?: string;
  label: string;
}

const Errors = ({ errors, id, label }: ErrorsProps) => {
  if (Array.isArray(errors)) {
    return (
      <ul id={id} className={styles.errorList}>
        {errors.map((error) => {
          const content = (
            <div role="alert">
              {error}
              <p role="presentation" className={styles.visuallyHidden}>
                {label}
              </p>
            </div>
          );
          return (
            <li key={error}>
              <Content
                data-purpose="input.errorMessage"
                className={styles.errorMessage}
                content={content}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <Content
      data-purpose="input.errorMessage"
      id={id}
      className={styles.errorMessage}
      content={
        <div role="alert">
          {errors}
          <p role="presentation" className={styles.visuallyHidden}>
            {label}
          </p>
        </div>
      }
    />
  );
};

export default Errors;
