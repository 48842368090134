import React, { FC } from 'react';
import {
  ProgressStepperDirection,
  ProgressStepperDirectionProps,
} from 'components/ProgressStepper/ProgressStepper.types';
import ProgressStepperHorizontal from 'components/ProgressStepper/components/ProgressStepperHorizontal';
import ProgressStepperVertical from 'components/ProgressStepper/components/ProgressStepperVertical';

const ProgressStepper: FC<ProgressStepperProps> = ({
  direction = 'horizontal',
  className,
  current = 0,
  onChange,
  disableClickOnInactive,
  disableClickOnFinished,
  isClickOnFinishedDisabled,
  isClickOnInactiveDisabled,
  scrollToActiveStep = false,
  areFinishedTitlesClickable = false,
  stepHeadingLevel,
  stepHeadingSEO,
  children,
}) =>
  direction === 'horizontal' ? (
    <ProgressStepperHorizontal
      className={className}
      current={current}
      onChange={onChange}
      isClickOnFinishedDisabled={
        disableClickOnFinished || isClickOnFinishedDisabled
      }
      isClickOnInactiveDisabled={
        disableClickOnInactive || isClickOnInactiveDisabled
      }
    >
      {children}
    </ProgressStepperHorizontal>
  ) : (
    <ProgressStepperVertical
      className={className}
      current={current}
      onChange={onChange}
      scrollToActiveStep={scrollToActiveStep}
      areFinishedTitlesClickable={areFinishedTitlesClickable}
      isClickOnFinishedDisabled={
        disableClickOnFinished || isClickOnFinishedDisabled
      }
      isClickOnInactiveDisabled={
        disableClickOnInactive || isClickOnInactiveDisabled
      }
      stepHeadingLevel={stepHeadingLevel}
      stepHeadingSEO={stepHeadingSEO}
    >
      {children}
    </ProgressStepperVertical>
  );

interface ProgressStepperProps extends ProgressStepperDirectionProps {
  direction?: ProgressStepperDirection;
}

export default ProgressStepper;
