import React, { useCallback, useId } from 'react';
import classnames from 'classnames';

import RatingIcon from 'components/Rating/components/SingleRating/components/RatingIcon/RatingIcon';
import { SingleRatingProps } from 'components/Rating/Rating.types';

import styles from 'components/Rating/components/SingleRating/SingleRating.scss';

const SingleRating = (props: SingleRatingProps) => {
  const {
    disabled = false,
    index,
    onSubmitRating,
    defaultChecked,
    rating,
    size = 'small',
    name,
    className,
  } = props;

  const inputId = useId();

  const handleClickSaveRating = useCallback(
    () => onSubmitRating && onSubmitRating(index),
    [index, onSubmitRating],
  );

  const renderDiv = (ratingComponent: JSX.Element) => (
    <div className={classnames(styles.ratingStar, styles[`${size}`])}>
      {ratingComponent}
    </div>
  );

  const renderInput = (ratingComponent: JSX.Element) => (
    <>
      <input
        defaultChecked={defaultChecked}
        value={index}
        id={inputId}
        type="radio"
        name={name}
        className={classnames(styles.visuallyHidden)}
        onClick={handleClickSaveRating}
        onChange={handleClickSaveRating}
      />
      <label
        className={classnames(styles.ratingStar, styles[`${size}`], className)}
        htmlFor={inputId}
      >
        <span className={styles.visuallyHidden}>{index} Star</span>
        {index && index > 0 ? ratingComponent : null}
      </label>
    </>
  );

  const ratingIcon = <RatingIcon size={size} percentage={rating} />;
  return disabled ? renderDiv(ratingIcon) : renderInput(ratingIcon);
};

export default SingleRating;
