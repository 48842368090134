import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { defaultLoading, fourSquaresLoading } from '@xxxlgroup/hydra-icons';

import Icon from 'components/Icon';

import styles from 'components/Loading/Loading.scss';

export enum VariantType {
  INHERIT = 'inherit',
  DARK = 'dark',
  LIGHT = 'light',
}

type LoadingProps = {
  ariaLabel?: string;
  type?: 'default' | 'dots';
  variant?: VariantType;
  className?: string;
};

const glyphTypes = {
  default: {
    glyph: defaultLoading,
  },
  dots: {
    glyph: fourSquaresLoading,
  },
};

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const Loading = forwardRef<SVGSVGElement, LoadingProps>(
  (
    {
      ariaLabel = 'Loading',
      className,
      type = 'default',
      variant = VariantType.INHERIT,
      ...other
    },
    ref,
  ) => {
    const { glyph } = glyphTypes[type] || {};

    const svgAttr = {
      className: classnames(
        styles[type],
        styles[variant],
        type === 'default' ? styles.icon : styles.loadingIndicator,
        className,
      ),
      ref,
      ...other,
    };

    const svg = glyph && (
      <Icon glyph={glyph} role="presentation" {...svgAttr} />
    );

    return type === 'default' ? (
      <div
        className={styles.section}
        aria-label={ariaLabel}
        role="progressbar"
        aria-busy
        aria-live="assertive"
      >
        {svg}
      </div>
    ) : (
      svg
    );
  },
);
export default Loading;
