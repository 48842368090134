import { FC } from 'react';
import { ProgressStepProps } from 'components/ProgressStepper/ProgressStepper.types';
import StepContent, {
  StepContentProps,
} from 'components/ProgressStepper/components/ProgressStep/components/StepContent/StepContent';

const ProgressStep: FC<ProgressStepProps> & {
  StepContent: FC<StepContentProps>;
} = () => null;

ProgressStep.StepContent = StepContent;

export default ProgressStep;
