import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useId,
} from 'react';
import {
  CollapsibleProps,
  OnToggleType,
} from 'components/Accordion/components/Collapsible/Collapsible.types';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon/pseudoIcon';
import AnimateHeight from 'components/AnimateHeight';
import { CollapsibleContext } from 'components/Accordion/context';
import { MouseOrKeyboardEvent } from 'types/typeDefinitions';
import ActivatorButton from 'components/Accordion/components/CollapsibleContent/components/ActivatorButton';

import styles from 'components/Accordion/components/CollapsibleContent/CollapsibleContent.scss';

const checkEventAndDisabledState = (
  disabled: boolean | undefined,
  event: MouseOrKeyboardEvent,
) =>
  disabled ||
  (event.nativeEvent instanceof KeyboardEvent &&
    event.nativeEvent.key !== 'Enter' &&
    event.nativeEvent.key !== ' ');

const CollapsibleContent: React.FC<CollapsibleProps> = ({
  activator,
  activatorClassName,
  buttonWrapperTag: Tag = null,
  contentClassName,
  'data-purpose': dataPurpose,
  glyphBefore,
  hasInlineChevron,
  hasNoSpacingInActivator,
  i18n,
  iconClassName,
  nonExpandableContent,
  isNonExpandableContentClickable,
  subText,
  subTextClassName,
  onToggle,
  disabled,
  defaultOpen = false,
  children,
}) => {
  const {
    registerCollapsible,
    openCollapsibles,
    openCollapsible,
    closeCollapsible,
  } = useContext(CollapsibleContext);

  const activatorId = useId();
  const expandedContentId = useId();

  const collapsibleId = useRef<number>();
  const onToggleRef = useRef<OnToggleType | undefined>(onToggle);

  useEffect(() => {
    const [id, unregisterCollapsible] = registerCollapsible(defaultOpen);
    collapsibleId.current = id;

    return () => {
      unregisterCollapsible?.(collapsibleId.current);
    };
  }, [registerCollapsible, defaultOpen]);

  const open = collapsibleId.current
    ? openCollapsibles.includes(collapsibleId.current)
    : false;

  const handleToggle = useCallback(
    (event: MouseOrKeyboardEvent) => {
      if (checkEventAndDisabledState(disabled, event)) {
        return;
      }
      event.preventDefault();

      !open
        ? openCollapsible(collapsibleId.current)
        : closeCollapsible(collapsibleId.current);
      onToggleRef.current?.(event, !open);
    },
    [openCollapsible, closeCollapsible, disabled, open],
  );

  const handleChange = useCallback(
    (event: MouseOrKeyboardEvent) => {
      handleToggle(event);
    },
    [handleToggle],
  );

  const handleClickNonExpandableContent = useCallback(
    (event: MouseOrKeyboardEvent) => {
      if (isNonExpandableContentClickable) {
        handleToggle(event);
      }
    },
    [handleToggle, isNonExpandableContentClickable],
  );

  const [iconStyleBefore, iconClassNameBefore] = pseudoIcon(glyphBefore);

  const renderContentBefore = () => (
    <span
      key="contentIcon"
      className={classnames(
        styles.iconContent,
        styles.iconBefore,
        iconClassNameBefore,
      )}
      style={iconStyleBefore}
      data-testid="collapsible.glyph"
    />
  );

  const activatorButtonProps = {
    isOpen: open,
    activatorId,
    expandedContentId,
    activatorClassName,
    onChange: handleChange,
    hasInlineChevron,
    hasNoSpacingInActivator,
    i18n,
    iconClassName,
    subTextClassName,
    subText,
    activator,
    dataPurpose,
  };

  const ContentCollapsible = (
    <div
      key="contentCollapsible"
      className={classnames(
        styles.buttonAndAnimationWrapper,
        activatorClassName,
        {
          [styles.activatorOpen]: open,
          [styles.activatorWithoutBorder]: hasNoSpacingInActivator,
        },
      )}
    >
      <div
        className={classnames(styles.btnContentWrapper, {
          [styles.btnContentWrapperWithoutSpace]: hasNoSpacingInActivator,
        })}
      >
        {Tag === null ? (
          <ActivatorButton {...activatorButtonProps} />
        ) : (
          <Tag className={styles.withHeading}>
            <ActivatorButton {...activatorButtonProps} />
          </Tag>
        )}
        {nonExpandableContent && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          <div
            className={styles.nonExpandableContent}
            onClick={handleClickNonExpandableContent}
          >
            {nonExpandableContent}
          </div>
        )}
      </div>
      <AnimateHeight animateOpacity height={open ? 'auto' : 0}>
        <section
          aria-labelledby={activatorId}
          className={classnames(styles.contentWrapper, contentClassName)}
          id={expandedContentId}
        >
          {children}
        </section>
      </AnimateHeight>
    </div>
  );

  return glyphBefore ? (
    <>
      {renderContentBefore()} {ContentCollapsible}
    </>
  ) : (
    ContentCollapsible
  );
};

export default CollapsibleContent;
