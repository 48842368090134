import React, { ComponentProps, ReactNode } from 'react';
import classNames from 'classnames';

import styles from 'components/DescriptionList/components/DescriptionListItem/DescriptionListItem.scss';

interface DescriptionListItemProps extends ComponentProps<'div'> {
  /** @ignore */
  children?: ReactNode;
  /** @ignore */
  className?: string;
  /** Title of the list */
  term: string;
  /** Additional classname for the term */
  classNameTerm?: string;
  /** Description of the term */
  description: React.ReactNode;
  /** Additional classname for the term */
  classNameDescription?: string;
}

const DescriptionListItem = ({
  className,
  classNameTerm,
  classNameDescription,
  description,
  term,
  ...other
}: DescriptionListItemProps) => (
  <div className={classNames(styles.wrapper, className)} {...other}>
    {term && <dt className={classNames(styles.term, classNameTerm)}>{term}</dt>}
    {description && (
      <dd className={classNames(styles.description, classNameDescription)}>
        {description}
      </dd>
    )}
  </div>
);

export default DescriptionListItem;
