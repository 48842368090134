import createPropError from 'utils/createPropError';

const isLabelValid = (energyLabel: string) => /^[a-gA-G]$/.test(energyLabel);

export const checkPropTypeLabel = (
  allProps: Record<string, any>,
  propName: string,
  componentName: string,
) => {
  if (!allProps[propName] || !isLabelValid(allProps[propName])) {
    return createPropError(propName, componentName, 'Label is invalid!');
  }
  return null;
};

export const checkPropTypeColor = (
  allProps: Record<string, any>,
  propName: string,
  componentName: string,
) => {
  if (
    allProps[propName] &&
    !/^#([0-9a-fA-F]{3}){1,2}$/.test(allProps[propName])
  ) {
    return createPropError(
      propName,
      componentName,
      'Please provide a valid hexadecimal color code!',
    );
  }
  return null;
};
