import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import FeedbackCard from 'components/FeedbackCard';
import { SnackbarComponentProps } from 'components/Snackbar';
import {
  NotificationsVariants,
  NotificationsOptions,
} from 'components/Notifications/Notifications.types';

import styles from 'components/Notifications/components/Notification/Notification.scss';

const getElementHeight = (element: HTMLElement | null): number => {
  if (element) {
    const { style } = element;
    style.animation = 'none';
    const { height } = element.getBoundingClientRect();
    style.animation = '';
    style.visibility = 'visible';

    return height;
  }
  return 0;
};

const Notification = ({
  className,
  children,
  id,
  isDismissed,
  close,
  pauseDuration,
  resumeDuration,
  glyph,
}: SnackbarComponentProps<NotificationsVariants, NotificationsOptions>) => {
  const [autoHeight, setAutoHeight] = useState(0);
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
  const animationElmRef = useRef<HTMLDivElement>(null);
  const style = {
    '--feedback-card-height': `${autoHeight}px`,
  } as React.CSSProperties;

  useEffect(() => {
    setAutoHeight(getElementHeight(animationElmRef?.current));
  }, [isDismissed]);

  useEffect(() => {
    // close the notification if the user clicks on the links within it
    const links = animationElmRef?.current?.getElementsByTagName('a') || [];
    // eslint-disable-next-line no-restricted-syntax
    for (const link of links) {
      link.onclick = handleDismiss;
    }
  });

  const handleDismiss = () => {
    close?.(true);
    return true;
  };

  return (
    <div
      style={style}
      ref={animationElmRef}
      className={classNames(className, styles.notification, {
        [styles.dismissed]: isDismissed,
      })}
    >
      <FeedbackCard
        variant={id}
        fadeOut={false}
        dismissible={!!close}
        onDismiss={handleDismiss}
        glyph={glyph}
        className={styles.feedbackCard}
        onMouseEnter={pauseDuration}
        onMouseLeave={() => resumeDuration(2000)}
      >
        {children}
      </FeedbackCard>
    </div>
  );
};

export default Notification;
