import { Range } from 'components/RangeSlider/components/MultiRange/MultiRange.types';

export enum RangeSliderVariant {
  REGULAR = 'regular',
  REDUCED = 'reduced',
}

export interface RangeSliderTranslation {
  from: string;
  to: string;
  unit: string;
  label: string;
}

export interface RangeSliderVariantProps {
  'data-purpose'?: string;
  className?: string;
  /** one of the possible variants */
  variant?: RangeSliderVariant;
  /** force the RangeSlider into the disabled state */
  disabled?: boolean;
  /** send changed values to frontend to trigger filter */
  /** @deprecated - use onChange instead */
  dispatchChange?: (values: Range) => void;
  i18n: RangeSliderTranslation;
  /** sets the minimum limit the slider will display */
  minLimit: number;
  /** sets the maximum limit the slider will display */
  maxLimit: number;
  /** sets current minimum value for input and slider */
  minValue: number;
  /** sets current maximum value for input and slider */
  maxValue: number;
  /** Callback function that will be invoked when the value changes. */
  onChange?: (values: Range) => void;
  /** steps of slider */
  step?: number;
}

export interface DefaultRangeSliderProps extends RangeSliderVariantProps {
  /** displays additional information */
  metaLabel?: string;
  showInputFields?: boolean;
}

export type RangeSliderProps =
  | RangeSliderVariantProps
  | DefaultRangeSliderProps;
