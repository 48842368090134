import React from 'react';
import { chevronDown, chevronUp } from '@xxxlgroup/hydra-icons';
import LinkButton from 'components/Link';

import styles from 'components/Expander/components/ExpanderButton/ExpanderButton.scss';
import { ExpanderButtonProps } from 'components/Expander/Expander.types';

const ExpanderButton = ({
  handleClick,
  isExpanded,
  i18n = { showMore: 'show more', showLess: 'show less' },
  theme = 'primary',
  visible,
  idToControl,
}: ExpanderButtonProps) => {
  if (!visible) {
    return null;
  }

  return (
    <LinkButton
      className={styles.expandButton}
      data-purpose={`button.show${isExpanded ? 'Less' : 'More'}`}
      onClick={handleClick}
      glyphAfter={isExpanded ? chevronUp : chevronDown}
      aria-expanded={isExpanded}
      theme={theme}
      aria-controls={idToControl}
    >
      {isExpanded ? i18n.showLess : i18n.showMore}
    </LinkButton>
  );
};

export default ExpanderButton;
