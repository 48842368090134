import { ProgressStepperStatusType } from 'components/ProgressStepper/ProgressStepper.types';

const getStatus = (index: number, current: number) => {
  let status: ProgressStepperStatusType;
  if (index === current) {
    status = 'active';
  } else if (index < current) {
    status = 'finished';
  } else {
    status = 'inactive';
  }
  return status;
};

export default getStatus;
