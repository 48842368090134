import React, { useMemo, useState, useRef, useCallback } from 'react';
import { CollapsibleContext } from 'components/Accordion/context';
import Collapsible from 'components/Accordion/components/Collapsible/Collapsible';
import CollapsibleContent from 'components/Accordion/components/CollapsibleContent/CollapsibleContent';

interface AccordionProps {
  /** Panel components open state is mutually exclusive */
  exclusive?: boolean;
  children: React.ReactNode;
}

let counter = 1;

const Accordion = ({ exclusive = false, ...props }: AccordionProps) => {
  const [openCollapsibles, setOpenCollapsibles] = useState<number[]>([]);

  // children will need to be indented if just one of them has an icon. Only to apply when there's more than one child
  const [collapsibleIndentation, setCollapsibleIndentation] = useState(false);

  const registeredCollapsiblesRef = useRef(new Set());
  const openCollapsiblesRef = useRef(new Set(openCollapsibles));

  const addOpenCollapsible = useCallback(
    (id: number) => {
      const activeKeysSet = openCollapsiblesRef.current;
      if (exclusive) {
        activeKeysSet.clear();
      }
      activeKeysSet.add(id);

      setOpenCollapsibles(Array.from(activeKeysSet));
    },
    [exclusive],
  );

  const registerCollapsible = useCallback(
    (
      open: boolean,
    ): [id?: number, unregisterCollapsible?: (id?: number) => void] => {
      const id = counter;

      registeredCollapsiblesRef.current.add(id);
      open && addOpenCollapsible(id);

      counter += 1;
      return [id, () => registeredCollapsiblesRef.current.delete(id)];
    },
    [addOpenCollapsible],
  );

  const openCollapsible = useCallback(
    (id?: number) => {
      id && addOpenCollapsible(id);
    },
    [addOpenCollapsible],
  );

  const closeCollapsible = useCallback((id?: number) => {
    const activeKeysSet = openCollapsiblesRef.current;

    id && activeKeysSet.delete(id);

    setOpenCollapsibles(Array.from(activeKeysSet));
  }, []);

  const stringifiedCollapsibles = JSON.stringify(openCollapsibles);

  const contextValue = useMemo(
    () => ({
      registerCollapsible,
      openCollapsible,
      closeCollapsible,
      openCollapsibles,
      setCollapsibleIndentation,
      collapsibleIndentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      closeCollapsible,
      openCollapsible,
      stringifiedCollapsibles,
      registerCollapsible,
      collapsibleIndentation,
    ],
  );

  return (
    <CollapsibleContext.Provider value={contextValue}>
      {props.children}
    </CollapsibleContext.Provider>
  );
};

Accordion.Collapsible = Collapsible;
Accordion.CollapsibleContent = CollapsibleContent;

export default Accordion;
