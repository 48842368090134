import { createContext } from 'react';

type ContextProps = {
  registerCollapsible: (
    open: boolean,
  ) => [id?: number, unregisterCollapsible?: (id?: number) => void];
  openCollapsibles: number[];
  openCollapsible: (id?: number) => void;
  closeCollapsible: (id?: number) => void;
  setCollapsibleIndentation: (indentation: boolean) => void;
  collapsibleIndentation: boolean;
};

export const CollapsibleContext = createContext<ContextProps>({
  closeCollapsible: () => null,
  openCollapsible: () => null,
  openCollapsibles: [],
  registerCollapsible: () => [undefined, () => null],
  setCollapsibleIndentation: () => null,
  collapsibleIndentation: false,
});
