/* eslint-disable ssr-friendly/no-dom-globals-in-react-fc */
import React, { useEffect, useRef, useState, useId } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { ALIGNMENT_RIGHT, SelectProps } from 'components/Select/Select.types';
import { scrollIntoView } from 'utils/common';
import { caretDown, callsign } from '@xxxlgroup/hydra-icons';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { noop } from '@xxxlgroup/hydra-utils/common';
import FlyOut, { useSelectFlyout } from 'components/Select/components/FlyOut';
import {
  getFilteredOptions,
  getInitialValue,
  renderSelectOrPlaceHolder,
} from 'components/Select/utils/utils';
import SelectLabel from 'components/Select/components/SelectLabel';

import styles from 'components/Select/Select.scss';

export const formatDataPurpose = (text: string, dataPurpose?: string) =>
  dataPurpose ? `${dataPurpose}.${text}` : text;

const renderError = (error?: string) =>
  error && (
    <div className={styles.error} role="alert">
      {error}
    </div>
  );
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      alignment = ALIGNMENT_RIGHT,
      className,
      disabled = false,
      'data-purpose': dataPurpose,
      error,
      flyOutClassName,
      filterable = false,
      i18n = {
        cancel: 'cancel',
        hideSearchInputLabel: false,
        mandatory: null,
        searchInputAriaLabel: null,
        searchInputLabel: 'Search',
        searchInputPlaceholder: 'Search',
      },
      keyName,
      keyValue,
      label,
      onClick = noop,
      onChange = noop,
      dispatchChange = noop,
      filterItemAmount,
      options,
      optionalSymbol,
      placeholder,
      required = false,
      requiredSymbol = '*',
      seamless = false,
      selectedOption = null,
      onBlur = noop,
      ...other
    },
    ref,
  ) => {
    // Automatically select first option value if selectedOption prop is unset, but the seamless variant is rendered
    const initialValue = getInitialValue(
      selectedOption,
      options,
      keyValue,
      seamless,
    );

    const selectId = useId();

    const [filterText, setFilterText] = useState<string>();

    const flyoutOptions = getFilteredOptions(options, keyName, filterText);

    const {
      value,
      open,
      selectRef,
      flyOutRef,
      className: flyoutClass,
      clickSelect,
      handleKeyDown,
      handleBlur,
    } = useSelectFlyout({
      initialValue,
      selectAlignment: alignment,
      seamless,
      options: flyoutOptions,
      keyValue,
      onClick,
      onChange,
      setFilterText,
      dispatchChange,
    });

    const selectedOptionRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef(null);

    const id = `selectLabelId-${keyName}`;
    const ariaControlsId = `listbox-${selectId}`;

    const [iconStyle, iconClassName] = pseudoIcon(caretDown, 'after');

    useEffect(() => {
      if (selectedOptionRef.current) {
        scrollIntoView(selectedOptionRef.current, {
          vertical: true,
        });
      }
    }, []);

    const handleFilterInput = (event: Event, filteredText: string) => {
      setFilterText(filteredText);
    };

    const wrapperClasses = classnames(styles.wrapper, flyoutClass, className, {
      [styles.seamless]: seamless,
      [styles.disabled]: disabled,
      [styles.hasValue]: value !== null && value !== '',
    });

    const selectClasses = classnames(
      styles.select,
      error && styles.selectError,
    );

    const handleInternalAndExternalBlurEvents = (
      event: React.FocusEvent<HTMLDivElement>,
    ) => {
      handleBlur(event);
      onBlur(event);
    };

    return (
      <div
        data-testid="wrapper"
        className={wrapperClasses}
        data-purpose={dataPurpose}
        ref={ref}
      >
        <div
          className={classnames(selectClasses, iconClassName, styles.arrow)}
          tabIndex={0}
          role="combobox"
          aria-controls={ariaControlsId}
          aria-expanded={open ? 'true' : 'false'}
          aria-haspopup="listbox"
          aria-labelledby={id}
          aria-activedescendant={value ?? undefined}
          onClick={clickSelect}
          onKeyDown={disabled ? undefined : handleKeyDown}
          ref={selectRef}
          onBlur={open ? handleInternalAndExternalBlurEvents : undefined}
          {...other}
          style={iconStyle}
        >
          <span
            className={classnames(styles.value, !value && styles.placeholder)}
            data-purpose={formatDataPurpose('select.value', dataPurpose)}
          >
            {renderSelectOrPlaceHolder(
              value,
              options,
              keyValue,
              keyName,
              label,
              placeholder,
              required,
              requiredSymbol,
              optionalSymbol,
            )}
          </span>
          {error && <Icon glyph={callsign} className={styles.errorIcon} />}
          <FlyOut
            className={flyOutClassName}
            filterable={filterable}
            handleFilterInput={handleFilterInput}
            error={error}
            valueConfig={{
              value,
              keyValue,
              keyName,
            }}
            selectedOptionRef={selectedOptionRef}
            seamless={seamless}
            ref={flyOutRef}
            filterText={filterText || ''}
            i18n={i18n}
            currentOptions={flyoutOptions}
            searchRef={searchRef}
            ariaControlsId={ariaControlsId}
            ariaLabelledbyId={id}
            filterItemAmount={filterItemAmount}
          />
        </div>
        {label && (
          <SelectLabel
            label={label}
            optionalSymbol={optionalSymbol}
            required={required}
            requiredSymbol={requiredSymbol}
            hidden={seamless}
            value={value}
            disabled={disabled}
            id={id}
          />
        )}
        {renderError(error)}
      </div>
    );
  },
);

export default Select;
