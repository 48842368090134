import { config } from '@xxxlgroup/hydra-config';

const buildSizes = (srcSet: string): string | undefined => {
  /* Note: If the `srcSet` attribute contains width descriptors, the `sizes` attribute is required for proper image sizing.
  For more information on responsive images and the `sizes` attribute, see https://cloudfour.com/thinks/responsive-images-101-part-5-sizes/ */
  if (/\s[1-9][0-9]*w/.test(srcSet)) {
    return `(min-width: ${config.breakpoints.xl + 1}px) ${
      config.breakpoints.xl
    }px, 100vw`;
  }
  return undefined;
};

export default buildSizes;
