import { ChangeEvent, KeyboardEvent } from 'react';

export const getNearestNumberWithinRange = (
  num: number,
  min: number,
  max: number,
) => Math.max(min, Math.min(num, max));

export const getValueAndPurpose = (
  event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
  minInputDataPurpose: string,
) => {
  const {
    currentTarget: {
      value,
      valueAsNumber,
      dataset: { purpose: dataPurpose },
    },
  } = event;

  const isMinInput = dataPurpose === minInputDataPurpose;

  return { isMinInput, value, valueAsNumber };
};
