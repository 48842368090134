import React, { useState, forwardRef } from 'react';
import classnames from 'classnames';
import filterInvalidHTMLAttributes from 'utils/htmlAttributes';

import { CheckboxProps } from 'components/Checkbox/Checkbox.types';

import styles from 'components/Checkbox/Checkbox.scss';

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      children,
      className,
      defaultChecked = false,
      description,
      disabled = false,
      name,
      onBlur,
      onChange,
      onFocus,
      onClick,
      value,
      ...other
    },
    ref,
  ) => {
    const [checkState, setCheckState] = useState(
      checked === undefined ? defaultChecked : checked,
    );
    const isChecked = () => (checked === undefined ? checkState : checked);

    const onChangeProxy = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckState(!isChecked());
      if (onChange) {
        onChange(event);
      }
    };

    const inputAttrs = {
      ...other,
      checked: isChecked(),
      className: styles.selectionControlInput,
      disabled,
      name,
      onBlur,
      onChange: onChangeProxy,
      onFocus,
      onClick,
      type: 'checkbox',
      value,
    };

    const markerClassName = classnames(
      styles.selectionControlMarker,
      styles.checkboxControlMarker,
    );

    const markerBackgroundClassName = classnames(
      styles.selectionControlMarkerBackground,
      styles.checkboxControlMarkerBackground,
    );

    const markerIconClassName = classnames(
      styles.selectionControlMarkerIcon,
      styles.checkboxControlMarkerIcon,
    );

    return (
      <label className={classnames(className, styles.selectionControl)}>
        <input
          {...filterInvalidHTMLAttributes('input', inputAttrs)}
          ref={ref}
        />
        <span className={markerClassName}>
          <span className={markerBackgroundClassName}>
            <svg className={markerIconClassName} viewBox="0 0 20 20">
              <path d="m5.5 10.5 3 3 6-7" />
            </svg>
          </span>
        </span>
        <span className={styles.selectionControlContent}>
          {children && (
            <span className={styles.selectionControlContentChildren}>
              {children}
            </span>
          )}
          {description && (
            <span className={styles.selectionControlContentDescription}>
              {description}
            </span>
          )}
        </span>
      </label>
    );
  },
);

export default Checkbox;
