import React from 'react';
import classnames from 'classnames';

import Image from 'components/Image';
import ColorCircle from 'components/ColorCircle';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { Glyph } from 'types/typeDefinitions';

import styles from 'components/Chip/Chip.scss';

type ChipContentProps = {
  children?: React.ReactNode;
  content?: React.ReactNode;
  color?: string;
  glyph?: Glyph;
  image?: string;
  indication?: number;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
  active: boolean;
};

const ChipContent: React.FC<ChipContentProps> = ({
  children,
  content,
  color,
  glyph,
  image,
  indication,
  onRemove,
  active,
}) => {
  const [iconStyle, iconClassName] = pseudoIcon(glyph);

  const indicationVariant =
    onRemove && !indication
      ? styles.indicationRemoveable
      : styles.indicationNormal;

  const textContainerVariant =
    onRemove && !indication
      ? styles.textContainerRemoveable
      : styles.textContainerNormal;

  const renderImage = (imageUrl: string) => (
    <div className={styles.image}>
      <Image source={imageUrl} />
    </div>
  );

  const renderIcon = () => {
    if (color) {
      return (
        <ColorCircle
          className={styles.colorCircle}
          color={color}
          size="xSmall"
          darkBackground={active}
        />
      );
    }

    if (!glyph || !iconStyle) {
      return null;
    }

    return (
      <div
        className={classnames(styles.iconBefore, iconClassName)}
        style={iconStyle}
        aria-hidden="true"
      />
    );
  };

  return (
    <>
      {children}
      {image && renderImage(image)}
      {renderIcon()}
      <div className={textContainerVariant}>
        <span className={styles.content}>{content}</span>
        {indication && <span className={indicationVariant}>{indication}</span>}
      </div>
    </>
  );
};

export default ChipContent;
