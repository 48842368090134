import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  AnimationSpeed,
  OnSlideFunc,
  ScrollDirectionType,
  Direction,
} from 'components/Carousel/Carousel.types';
import styles from 'components/Carousel/Carousel.scss';

const calculateGap = (
  firstSlide: { axis: number; dimension: number },
  secondSlideDimension: number,
) => secondSlideDimension - (firstSlide.axis + firstSlide.dimension);

const getPagination = (
  {
    scrollWidth,
    offsetWidth,
    scrollLeft,
    scrollTop,
    offsetHeight,
    scrollHeight,
  }: HTMLDivElement,
  fullWidth?: boolean,
  isLooped?: boolean,
) => {
  const rightScrollEndReached =
    scrollWidth > 0 && scrollLeft + offsetWidth + 1 >= scrollWidth;
  const bottomScrollReached =
    scrollHeight > 0 && scrollTop + offsetHeight + 1 >= scrollHeight;

  return {
    scrollableLeft: fullWidth && isLooped ? true : scrollLeft > 0, // boolean to indicate if the left end of the scrollbar has been reached
    scrollableRight: fullWidth && isLooped ? true : !rightScrollEndReached, // boolean to indicate if the right end of the scrollbar has been reached
    scrollableTop: scrollTop > 0,
    scrollableBottom: !bottomScrollReached,
  };
};

const getIndex = (scrollLeft: number, offset: number) =>
  Math.round(scrollLeft / offset);

const getNavigationInfo = (
  scrollContainer: HTMLDivElement,
  scrollDirection: ScrollDirectionType,
) => {
  const isHorizontalDirection = scrollDirection === 'horizontal';
  const { width: containerWidth, height: containerHeight }: DOMRect =
    scrollContainer.getBoundingClientRect();
  const firstSlideRect: DOMRect =
    scrollContainer.children[0].getBoundingClientRect();
  const secondSlideRect: DOMRect =
    scrollContainer.children[1].getBoundingClientRect();

  let containerDimension = containerWidth;
  let firstSlide = { axis: firstSlideRect.x, dimension: firstSlideRect.width };
  let secondSlideDimension = secondSlideRect.x;

  if (!isHorizontalDirection) {
    containerDimension = containerHeight;
    firstSlide = { axis: firstSlideRect.y, dimension: firstSlideRect.height };
    secondSlideDimension = secondSlideRect.y;
  }
  // calculate gap if there is one
  const gap = calculateGap(firstSlide, secondSlideDimension);

  // slides per page without sneak peek
  const numberOfSlidesShownPerPage = Math.trunc(
    containerDimension / (firstSlide.dimension + gap),
  );

  const scrollEdge = isHorizontalDirection
    ? scrollContainer.scrollLeft
    : scrollContainer.scrollTop;
  // how many slides have been scrolled past its current left edge (scrollLeft)
  const slidesScrolledPast = scrollEdge / (firstSlide.dimension + gap);
  // the last fully visible slide will become the first slide visible
  const numberOfSlidesToScroll =
    numberOfSlidesShownPerPage > 1 ? numberOfSlidesShownPerPage - 1 : 1;

  const distanceToScroll =
    (Math.round(slidesScrolledPast) - slidesScrolledPast) *
    (firstSlide.dimension + gap);

  const offset = (firstSlide.dimension + gap) * numberOfSlidesToScroll;
  const index = getIndex(scrollEdge, offset);

  return {
    offset,
    numberOfSlidesShownPerPage,
    slidesScrolledPast,
    distanceToScroll,
    slideDimension: firstSlide.dimension,
    numberOfSlidesToScroll,
    gap,
    index,
  };
};

export const useCarouselNavigation = (
  onSlideChanged?: OnSlideFunc,
  fullWidth?: boolean,
  autoPlay?: boolean,
  isLooped?: boolean,
  animationSpeed?: AnimationSpeed,
  sneakPeekDisabled?: boolean,
  scrollDirection?: ScrollDirectionType,
) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [pagination, setPagination] = useState({
    scrollableLeft: !!fullWidth && !!isLooped,
    scrollableRight: true,
    scrollableTop: false,
    scrollableBottom: true,
  });
  const [isLastImageLoaded, setIsLastImageLoaded] = useState(false);
  const isMouseDownRef = useRef(false);
  const isHorizontalDirection = scrollDirection === 'horizontal';
  const mousePosition = useRef({ left: 0, x: 0, y: 0, top: 0 });
  const oldScrollSlideIndex = useRef(0);
  const periodicalExecutor = useRef<ReturnType<typeof setInterval>>();
  const resetScrollSnapTimer = useRef<ReturnType<typeof setTimeout>>();
  const shouldSetScrollLeft = useRef(true);
  const smoothScrollSupported = useRef(true);

  const disableDocumentSelection = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const receiveValuesForScrollDirection = useCallback(
    (horizontalValue: any, verticalValue: any) =>
      isHorizontalDirection ? horizontalValue : verticalValue,
    [isHorizontalDirection],
  );

  useEffect(
    () => () =>
      document.removeEventListener('selectstart', disableDocumentSelection),
    [disableDocumentSelection],
  );

  const scrollTo = (scrollValue: number, scrollToDirection: string) => {
    scrollContainerRef.current?.scrollTo({
      [scrollToDirection]: scrollValue,
      behavior: 'smooth',
    });
  };

  const checkAndStartAutoPlay = useCallback(() => {
    if (!smoothScrollSupported.current) {
      return;
    }

    const images = scrollContainerRef.current?.getElementsByTagName('img');

    if (!images?.length) {
      return;
    }

    const lastImage = images?.[images.length - 1];

    lastImage?.addEventListener('load', () => {
      setIsLastImageLoaded(true);
    });

    const preferReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)',
    );

    if (
      !preferReducedMotion ||
      (!preferReducedMotion.matches && autoPlay && isLastImageLoaded)
    ) {
      periodicalExecutor.current = setInterval(
        () => {
          if (scrollContainerRef.current) {
            scrollTo(
              scrollContainerRef.current.scrollLeft +
                scrollContainerRef.current.clientWidth,
              'left',
            );
          }
        },
        animationSpeed === 'slow' ? 7000 : 4000,
      );
    }
  }, [animationSpeed, autoPlay, isLastImageLoaded]);

  const adjustScrollLeft = useCallback(
    ({ scrollLeft, scrollWidth, clientWidth }: HTMLDivElement) => {
      // To deal with pixel ratios which return decimals
      const offset = 1;
      const threshold = scrollWidth - clientWidth - offset;

      if (
        (scrollLeft >= threshold || scrollLeft <= 0) &&
        shouldSetScrollLeft.current &&
        scrollContainerRef.current
      ) {
        scrollContainerRef.current.style.overflow = 'hidden';
        shouldSetScrollLeft.current = false;

        requestAnimationFrame(() => {
          if (!scrollContainerRef.current) {
            return;
          }
          if (scrollLeft <= 0) {
            scrollContainerRef.current.scrollLeft =
              scrollWidth - clientWidth * 2;
          } else {
            scrollContainerRef.current.scrollLeft =
              scrollContainerRef.current?.getBoundingClientRect().width;
          }
          scrollContainerRef.current.style.overflow = 'auto';
          shouldSetScrollLeft.current = true;
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (scrollContainerRef.current) {
      setPagination(
        getPagination(scrollContainerRef.current, fullWidth, isLooped),
      );
    }
  }, [scrollContainerRef.current?.children.length, fullWidth, isLooped]);

  const onScroll = useCallback(
    (e?: Event) => {
      if (!scrollContainerRef.current) {
        return;
      }

      const { scrollLeft, clientWidth, scrollTop, clientHeight, children } =
        scrollContainerRef.current;

      const [scrollDirection, clientDimension] = isHorizontalDirection
        ? [scrollLeft, clientWidth]
        : [scrollTop, clientHeight];

      const shoudLoop = isLooped && fullWidth;
      const index =
        Math.round(scrollDirection / clientDimension) - (shoudLoop ? 1 : 0);
      let newIndex = index;

      // substract 2 for the cloned slides
      const clonedChildCount = shoudLoop ? 2 : 0;
      const childCount = children.length - clonedChildCount;

      if (index === childCount) {
        newIndex = 0;
      } else if (index < 0) {
        newIndex = childCount - 1;
      }
      if (oldScrollSlideIndex.current !== newIndex) {
        onSlideChanged?.(oldScrollSlideIndex.current, newIndex, e);
        oldScrollSlideIndex.current = newIndex;
      }

      if (!fullWidth || !shoudLoop) {
        setPagination(
          getPagination(scrollContainerRef.current, fullWidth, isLooped),
        );
        return;
      }

      isHorizontalDirection && adjustScrollLeft(scrollContainerRef.current);
    },
    [
      adjustScrollLeft,
      fullWidth,
      isLooped,
      onSlideChanged,
      isHorizontalDirection,
    ],
  );

  useEffect(() => {
    autoPlay && checkAndStartAutoPlay();

    smoothScrollSupported.current =
      'scrollBehavior' in document.documentElement.style;

    const internalRef = scrollContainerRef.current;
    internalRef?.addEventListener('scroll', onScroll, {
      passive: true,
    });

    return () => {
      internalRef?.removeEventListener('scroll', onScroll);
    };
  }, [autoPlay, checkAndStartAutoPlay, onScroll]);

  const scroll = useCallback(
    (direction: Direction, scrollDirection: ScrollDirectionType) => {
      if (
        !scrollContainerRef.current ||
        scrollContainerRef.current?.children.length <= 1
      ) {
        return;
      }

      const {
        slideDimension,
        gap,
        numberOfSlidesToScroll,
        distanceToScroll,
        slidesScrolledPast,
        numberOfSlidesShownPerPage,
      } = getNavigationInfo(scrollContainerRef.current, scrollDirection);
      let offset = (slideDimension + gap) * numberOfSlidesToScroll;

      if (direction === Direction.LEFT) {
        offset = distanceToScroll - offset; // prevent half thumbnail to show when scroll position is on the first item
        if (!fullWidth && !sneakPeekDisabled) {
          offset +=
            slidesScrolledPast < numberOfSlidesShownPerPage
              ? 0
              : slideDimension / 2 - gap / 2;
        }
      } else {
        offset += distanceToScroll;
      }

      const [directionTo, scrollToValue] = isHorizontalDirection
        ? ['left', scrollContainerRef.current.scrollLeft + offset]
        : ['top', scrollContainerRef.current.scrollTop + offset];
      scrollTo(scrollToValue, directionTo);
    },
    [fullWidth, sneakPeekDisabled, isHorizontalDirection],
  );

  const onLeft = useCallback(
    (
      event: React.MouseEvent<Element, MouseEvent> | undefined,
      scrollDirection: ScrollDirectionType,
    ) => {
      scroll(Direction.LEFT, scrollDirection);
    },
    [scroll],
  );

  const onRight = useCallback(
    (
      event: React.MouseEvent<Element, MouseEvent> | undefined,
      scrollDirection: ScrollDirectionType,
    ) => {
      scroll(Direction.RIGHT, scrollDirection);
    },
    [scroll],
  );

  const resetScrollBehavior = useCallback(() => {
    isMouseDownRef.current = false;
    if (scrollContainerRef.current) {
      scrollContainerRef.current.classList.remove(styles.noPointerEvents);
    }
  }, []);

  const onMouseDown = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const carouselRef = scrollContainerRef.current;
    if (carouselRef) {
      const mouseDownPosition = e.clientY - target.getBoundingClientRect().top;
      if (
        mouseDownPosition > carouselRef.clientHeight &&
        scrollDirection === 'horizontal'
      ) {
        return;
      }

      if (fullWidth) {
        if (resetScrollSnapTimer.current) {
          clearTimeout(resetScrollSnapTimer.current);
        }
        carouselRef.classList.add(styles.resetScrollSnap);
      }

      isMouseDownRef.current = true;
      mousePosition.current = {
        // The current scroll
        left: carouselRef.scrollLeft,
        top: carouselRef.scrollTop,
        // Get the current mouse position
        x: e.clientX - carouselRef.offsetLeft,
        y: e.clientY - carouselRef.offsetTop,
      };
    }
  };

  const onMouseLeave = () => {
    resetScrollBehavior();
    scrollContainerRef.current?.classList.remove(styles.resetScrollSnap);

    document.removeEventListener('selectstart', disableDocumentSelection);
    checkAndStartAutoPlay();
  };

  const stopAutoPlay = () => {
    if (periodicalExecutor.current) {
      clearInterval(periodicalExecutor.current);
    }
  };

  const onMouseUp = useCallback(() => {
    resetScrollBehavior();

    if (!fullWidth || !scrollContainerRef.current) {
      return;
    }

    const oldScrollDirection = scrollContainerRef.current.scrollLeft;
    scrollContainerRef.current.classList.remove(styles.resetScrollSnap);
    const newScrollDirection = scrollContainerRef.current.scrollLeft;
    scrollContainerRef.current.classList.add(styles.resetScrollSnap);

    // firefox specific behaviour
    if (oldScrollDirection === newScrollDirection) {
      scrollContainerRef.current.classList.remove(styles.resetScrollSnap);
      scrollTo(newScrollDirection, 'left');
      // Webkit specific
    } else {
      scrollContainerRef.current.scrollLeft = oldScrollDirection;
      scrollTo(newScrollDirection, 'left');
      resetScrollSnapTimer.current = setTimeout(() => {
        scrollContainerRef.current?.classList.remove(styles.resetScrollSnap);
      }, 500);
    }
  }, [fullWidth, resetScrollBehavior]);

  const onMouseEnter = useCallback(() => {
    stopAutoPlay();
    document.addEventListener('selectstart', disableDocumentSelection);
  }, [disableDocumentSelection]);

  const calculateDistance = useCallback(
    (clientAxis: number, mousePositionAxis: number) =>
      clientAxis - mousePositionAxis,
    [],
  );

  const onMouseMove = useCallback(
    (e: { preventDefault: () => void; clientX: number; clientY: number }) => {
      if (isMouseDownRef.current) {
        e.preventDefault();
        const [clientAxis, mousePositionAxis] = isHorizontalDirection
          ? [e.clientX, mousePosition.current.x]
          : [e.clientY, mousePosition.current.y];
        const distance = calculateDistance(clientAxis, mousePositionAxis);
        if (scrollContainerRef.current && Math.abs(distance) > 5) {
          const { classList, offsetLeft, offsetTop } =
            scrollContainerRef.current;
          classList.add(styles.noPointerEvents);
          const offset = receiveValuesForScrollDirection(offsetLeft, offsetTop);
          const distanceToOffset = calculateDistance(clientAxis, offset);
          const SCROLL_SPEED = 3;

          const walk = (distanceToOffset - mousePositionAxis) * SCROLL_SPEED;

          if (isHorizontalDirection) {
            scrollContainerRef.current.scrollLeft =
              mousePosition.current.left - walk;
          } else {
            scrollContainerRef.current.scrollTop =
              mousePosition.current.top - walk;
          }
        }
      }
    },
    [calculateDistance, isHorizontalDirection, receiveValuesForScrollDirection],
  );

  const onMouseDrag = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const onFocus = () => {
    stopAutoPlay();
  };

  const onTouchStart = () => {
    stopAutoPlay();
  };

  const scrollToPage = (page: number) => {
    if (scrollContainerRef.current) {
      const scrollToDirection = receiveValuesForScrollDirection('left', 'top');
      const { offsetWidth, offsetHeight } = scrollContainerRef.current;
      const scrollValue = receiveValuesForScrollDirection(
        offsetWidth,
        offsetHeight,
      );
      scrollTo((page + 1) * scrollValue, scrollToDirection);
    }
  };

  return {
    ...pagination,
    onLeft,
    onRight,
    onMouseDown,
    onMouseLeave,
    onTouchStart,
    onMouseEnter,
    onMouseMove,
    onMouseUp,
    scrollToPage,
    onMouseDrag,
    onFocus,
    ref: scrollContainerRef,
  };
};
