import React from 'react';
import classNames from 'classnames';
import IconButton from 'components/IconButton';
import {
  chevronLeft,
  chevronRight,
  chevronUp,
  chevronDown,
} from '@xxxlgroup/hydra-icons';
import { ArrowSize, ArrowType } from 'components/Carousel/Carousel.types';
import { noop } from '@xxxlgroup/hydra-utils/common';

import styles from 'components/Carousel/components/Arrow/Arrow.scss';

type Direction = 'next' | 'previous' | 'up' | 'down';

interface ArrowProps {
  className?: string;
  /** What kind of arrow should get displayed */
  direction: Direction;
  /** Whether the arrow is disabled or not */
  disabled?: boolean;
  /** What type of arrow should be used */
  type?: ArrowType;
  /** Translation for arrow. */
  i18n: {
    label: string | React.ReactNode;
  };
  /** Providing an event handler for interacting with the slider */
  onClick?: (event: React.MouseEvent) => void;

  /** Providing an event handler for interacting with the slider */
  onFocus?: (event: React.FocusEvent) => void;
  /** Providing an event handler for interacting with the slider */
  onBlur?: (event: React.FocusEvent) => void;
  /** If activated, the arrows on first/last slide are visible, but greyed out and will prevent the click */
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  showDisabled?: boolean;
  /** Defines whether to show the arrow only on focus or permanently. */
  showOnlyOnFocus?: boolean;
  /** Sets the size of the arrow. */
  size?: ArrowSize;
}

const icons = {
  previous: chevronLeft,
  next: chevronRight,
  up: chevronUp,
  down: chevronDown,
};

const Arrow = React.forwardRef<HTMLButtonElement, ArrowProps>(
  (
    {
      className,
      disabled = false,
      type = 'filled',
      showOnlyOnFocus = false,
      size = 'small',
      showDisabled = false,
      onFocus = noop,
      onClick = noop,
      onBlur = noop,
      onMouseEnter = noop,
      onMouseLeave = noop,
      i18n,
      direction,
    },
    ref,
  ) => {
    const handleClick = (event: React.MouseEvent) => {
      if (disabled) {
        event.preventDefault();
      } else {
        onClick(event);
      }
    };

    return (
      <IconButton
        ref={ref}
        glyph={icons[direction]}
        aria-label={i18n.label}
        aria-disabled={disabled} // normal disabled would lose the focus on element on first/last slide
        data-purpose={
          direction === 'previous' || direction === 'up'
            ? 'slider.arrow.previous'
            : 'slider.arrow.next'
        }
        disabled={disabled}
        type="button"
        onClick={handleClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classNames(
          styles.arrow,
          styles[size],
          styles[type],
          styles[direction],
          {
            [styles.disabled]: disabled,
            [styles.showOnlyOnFocus]: showOnlyOnFocus,
            [styles.showDisabled]: disabled && showDisabled,
          },
          className,
        )}
      />
    );
  },
);

export default Arrow;
