import React, { useState, useCallback, useRef, useId } from 'react';
import classnames from 'classnames';

import SingleRating from 'components/Rating/components/SingleRating/SingleRating';
import { RatingProps } from 'components/Rating/Rating.types';

import styles from 'components/Rating/Rating.scss';

const percentagePerRating = (
  ratingValue: number,
  totalSymbols: number,
  currentSymbol: number,
) => {
  const filledSegments = totalSymbols * ratingValue;
  if (currentSymbol <= Math.floor(filledSegments)) {
    return 1;
  }

  if (Math.ceil(filledSegments) === currentSymbol) {
    return filledSegments - Math.floor(filledSegments);
  }

  return 0;
};

const Rating = (props: RatingProps) => {
  const {
    amountOfSymbols = 5,
    className,
    interactive = false,
    size = 'small',
    onSubmitRating,
    value,
    ...other
  } = props;

  const id = useId();
  const [rating, setRating] = useState(0);
  const currentIndex = useRef(0);

  const onSubmitRatingProxy = useCallback(
    (index: number) => {
      if (interactive) {
        if (currentIndex.current !== index) {
          setRating(index / amountOfSymbols);
          onSubmitRating?.(index);
          currentIndex.current = index;
        }
      }
    },
    [amountOfSymbols, interactive, onSubmitRating],
  );

  return (
    <div
      className={classnames(
        styles.starRating,
        { [styles.interactive]: interactive },
        className,
      )}
      {...other}
    >
      {interactive && (
        <SingleRating
          name={id}
          index={0}
          onSubmitRating={onSubmitRating}
          defaultChecked
          className={classnames(styles.visuallyHidden, styles.firstStar)}
          rating={0}
        />
      )}

      {Array(amountOfSymbols)
        .fill('')
        .map((_, arrIndex) => {
          const index = arrIndex + 1;
          const ratingValuePerSymbol = percentagePerRating(
            value || rating,
            amountOfSymbols,
            index,
          );
          return (
            <SingleRating
              disabled={!interactive}
              index={index}
              name={id}
              key={`rating-${index}`}
              rating={ratingValuePerSymbol}
              onSubmitRating={onSubmitRatingProxy}
              size={size}
            />
          );
        })}
      <span className={styles.focusOutline} />
    </div>
  );
};

export default Rating;
