import React, {
  Children,
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
  useId,
} from 'react';
import classnames from 'classnames';
import { isValuePresent } from '@xxxlgroup/hydra-utils/common';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import InputLabel from 'components/Input/components/InputLabel';
import { InputProps } from 'components/Input/Input.types';
import {
  checkValue,
  renderErrors,
  renderDescription,
  renderActionButton,
  isActionButtonVisible,
  renderPrefix,
  setDefaultValue,
  setDataPurpose,
  createClippedValue,
  setElement,
  setSuffixStyles,
  renderSuffixSection,
  getPlaceholderWithRequiredOrOptionalSuffix,
} from 'components/Input/Input.helpers';
import styles from 'components/Input/Input.scss';
import { sanitize } from 'isomorphic-dompurify';
import filterInvalidHTMLAttributes from 'utils/htmlAttributes';

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const Input = ({
  actionButton,
  ariaDescribedBy,
  className,
  'data-purpose': dataPurpose,
  defaultValue,
  description,
  disabled = false,
  errors,
  forwardedRef,
  hideCancelButton = false,
  hideLabel,
  i18n = {
    mandatory: 'Mandatory field',
    cancel: 'cancel',
    showPassword: 'show password',
  },
  inputMode = 'text',
  isTouch = true,
  keepCancelButton = false,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onBlur,
  onClick,
  onFocus,
  onChange,
  onKeyPress,
  onKeyDown,
  onKeyUp,
  optionalSymbol,
  pattern,
  placeholder,
  prefix,
  readOnly = false,
  required = false,
  requiredSymbol = '*',
  success = false,
  suffix,
  title,
  type = 'text',
  value = undefined,
  ...other
}: InputProps) => {
  const id = useId();

  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isMultiline = type === 'multiline';

  const isCancelButtonVisible = useMemo(
    () =>
      (isTouch || keepCancelButton) &&
      !readOnly &&
      !hideCancelButton &&
      showCancelButton,
    [hideCancelButton, isTouch, keepCancelButton, readOnly, showCancelButton],
  );

  const isIconVisible = useMemo(
    () => !!(errors || success || suffix || isCancelButtonVisible),
    [errors, success, suffix, isCancelButtonVisible],
  );

  const togglePasswordVisibility = () =>
    setShowPassword((prevState) => !prevState);

  const hasValue = () =>
    checkValue(value, inputRef?.current?.value, defaultValue);

  const handleFocus = (event: React.FocusEvent) => {
    isValuePresent(inputRef?.current?.value) && setShowCancelButton(true);
    onFocus?.(event);
  };

  const handleBlur = (event: React.FocusEvent) => {
    !keepCancelButton && setShowCancelButton(false);
    onBlur?.(event);
  };

  useEffect(() => {
    if (isMultiline && inputRef?.current) {
      // we need to set the height of the input to 0 before we set a new value for height,
      // otherwise it will not shrink down in height if the user deletes lines.
      inputRef.current.style.height = `0px`;
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [isMultiline, value]);

  const handleChange = (event: Event) => {
    const target = event?.target as HTMLInputElement;
    const valueFromEvent = target?.value;

    // Trim value to maxLength if that exists (only on multiline inputs).
    // Note that this is still required even though we have the onKeyPress filter,
    // because the user could paste something into the textarea.
    const clippedValue = createClippedValue(valueFromEvent, maxLength);
    // if second parameter is undefined, will return whole string
    // Multiline input grows with the content.
    setShowCancelButton(true);

    // propagate to store and therefore to the input
    onChange?.(event, clippedValue);
  };

  const conditionalAttributes = useMemo(
    () => ({
      ...(!isMultiline && {
        maxLength,
        minLength,
        pattern,
        type,
      }),
      ...(type === 'number' && {
        min,
        max,
      }),
      ...(type === 'password' && {
        type: showPassword ? 'text' : 'password',
      }),
      placeholder: getPlaceholderWithRequiredOrOptionalSuffix(
        placeholder || label,
        required,
        requiredSymbol,
        optionalSymbol,
      ),
      ...(errors &&
        ariaDescribedBy && {
          'aria-describedby': ariaDescribedBy,
        }),
    }),
    [
      isMultiline,
      maxLength,
      minLength,
      pattern,
      type,
      min,
      max,
      showPassword,
      placeholder,
      label,
      required,
      requiredSymbol,
      optionalSymbol,
      ariaDescribedBy,
      errors,
    ],
  );

  const inputAttributes: Record<string, unknown> = {
    ...other,
    'aria-readonly': readOnly,
    'aria-invalid': !!errors,
    className: classnames(styles.input, {
      [styles.withPrefix]: prefix,
      [styles.withSuffix]: setSuffixStyles(
        isIconVisible,
        isActionButtonVisible(type, actionButton),
      ),
      [styles.withLabel]: label,
      [styles.multiline]: isMultiline,
      [styles.disabled]: disabled,
    }),

    // do not set defaultValue if you have a value -> don't mix controlled and uncontrolled comps (react will complain)
    defaultValue: setDefaultValue(value, defaultValue),
    disabled,
    id,
    inputMode,
    name,
    onBlur: handleBlur,
    onChange: handleChange,
    onClick,
    onFocus: handleFocus,
    onKeyDown: onKeyDown ?? onKeyPress,
    onKeyUp,
    readOnly,
    ref: inputRef,
    required,
    title,
    value,
    ...conditionalAttributes,
  };

  const prefixWrapperAttributes = {
    className: classnames({
      [styles.prefixWrapper]: prefix,
      [styles.multiline]: isMultiline,
      [styles.disabled]: disabled,
    }),
  };

  const suffixWrapperAttributes = {
    className: classnames({
      [styles.suffixWrapper]: isIconVisible,
      [styles.multiline]: isMultiline,
    }),
  };

  const actionWrapperAttributes = {
    className: classnames({
      [styles.actionWrapper]: isActionButtonVisible(type, actionButton),
      [styles.multiline]: isMultiline,
    }),
  };
  const wrapperClassNames = classnames({
    [styles.wrapperError]: errors,
    [styles.hasValue]: hasValue(),
    [styles.disabled]: disabled,
    [styles.readOnly]: readOnly,
  });

  const Element = setElement(isMultiline);

  // this enables us easily query the field for GUI tests using the original purpose. At the same time a unique
  // purpose is available for tracking
  const innerPurpose = setDataPurpose(dataPurpose);

  const clearValue = (isTriggeredByKeyDown = false) => {
    const currentRef = inputRef?.current;
    currentRef && (currentRef.value = '');

    // user should be able to type immediately after clearing the field - therefore focus again because field loses focus after click
    const focus = () => {
      currentRef?.removeEventListener('blur', focus);
      currentRef?.focus();
    };

    currentRef?.addEventListener('blur', focus);

    if (isTriggeredByKeyDown) {
      currentRef?.focus();
    }

    const event = document.createEvent('HTMLEvents');
    event.initEvent('change', true, false); // trigger change event to pass it to the handleChange, which passes to the store
    currentRef?.dispatchEvent(event); // fill event.target.value for handleChange
    handleChange(event);
  };

  const cloneChildren = (children: React.ReactElement, childType: any) =>
    Children.map(children, (child: React.ReactElement, index: number) => {
      const key = `clone-${childType}-${name}-${index}`;
      const { className: childClassName, ...childProps } = child.props;
      return cloneElement(child, {
        className: classnames(
          {
            [styles.iconInFragment]: child.type === Icon,
            [styles.iconButton]: child.type === IconButton,
          },
          childClassName,
        ),
        key,
        ...childProps,
      });
    });

  const actionButtonAttributes = {
    actionButton,
    cloneChildren,
    i18n,
    type,
    showPassword,
    togglePasswordVisibility,
  };

  const suffixSectionAttributes = {
    isIconVisible,
    errors,
    success,
    isCancelButtonVisible,
    i18n,
    clearValue,
    suffix,
    cloneChildren,
  };

  return (
    <>
      <div
        className={classnames(styles.wrapper, wrapperClassNames, className)}
        data-purpose={dataPurpose}
        ref={forwardedRef}
      >
        {renderPrefix(prefixWrapperAttributes, cloneChildren, prefix)}
        <Element
          {...filterInvalidHTMLAttributes(Element, inputAttributes)}
          data-purpose={innerPurpose}
        />
        <InputLabel
          hasValue={hasValue}
          hideLabel={hideLabel}
          refId={id}
          i18n={{ mandatory: i18n.mandatory || sanitize(label) }}
          optionalSymbol={optionalSymbol}
          label={label}
          required={required}
          requiredSymbol={requiredSymbol}
        />
        {renderSuffixSection(suffixWrapperAttributes, suffixSectionAttributes)}
        {renderActionButton(actionWrapperAttributes, actionButtonAttributes)}
      </div>

      {renderErrors(errors, label, ariaDescribedBy)}
      {renderDescription(description)}
    </>
  );
};

export default Input;
