import React, { FC, MouseEvent } from 'react';
import classNames from 'classnames';

import styles from 'components/Carousel/components/Indicator/Indicator.scss';

interface IndicatorProps {
  /** Whether the indicator is active or not */
  active?: boolean;
  /** @ignore */
  className?: string;
  /** Translation for arrow. */
  i18n: {
    label: string;
  };
  /** Providing an event handler for interacting with the slider */
  onClick: (event: MouseEvent) => void;
}

const Indicator: FC<IndicatorProps> = ({
  active = false,
  className,
  i18n,
  onClick,
}) => (
  <button
    data-purpose="slider.indicator"
    aria-label={i18n.label}
    type="button"
    onClick={onClick}
    className={classNames(
      styles.indicator,
      {
        [styles.active]: active,
      },
      className,
    )}
  />
);

export default Indicator;
