import React from 'react';

export enum ActionType {
  SETINDEX = 'SETINDEX',
}

export interface Actions {
  type: ActionType;
  payload: number;
}

export interface State {
  activeIndex: number;
}

export const initialState: State = {
  activeIndex: 0,
};

export const tabsReducer = (state: State, action: Actions): State => {
  if (action.type === ActionType.SETINDEX) {
    return {
      ...state,
      activeIndex: action.payload,
    };
  }
  return state;
};

export const TabContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});
