import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import MultiRange from 'components/RangeSlider/components/MultiRange';
import { DefaultRangeSliderProps } from 'components/RangeSlider/RangeSlider.types';
import { Range } from 'components/RangeSlider/components/MultiRange/MultiRange.types';
import useRangeChange from 'components/RangeSlider/hooks/useRangeChange';
import styles from 'components/RangeSlider/components/RegularVariant/RegularVariant.scss';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { getValueAndPurpose } from 'components/RangeSlider/utils';

const MIN_INPUT_DATA_PURPOSE = 'rangeSlider.inputFrom';
const invalidCharacters = /\D/;
const validKeystrokes = [
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'Backspace',
  'Delete',
  'Enter',
  'Tab',
];

const DefaultRangeSlider: FunctionComponent<DefaultRangeSliderProps> = ({
  disabled,
  dispatchChange = noop,
  i18n,
  maxLimit,
  maxValue,
  metaLabel,
  minLimit,
  minValue,
  onChange = noop,
  showInputFields,
  step,
}) => {
  const [sliderValues, setSliderValues] = useState<Range>([minValue, maxValue]);

  const [inputValues, setInputValues] = useState<[string, string]>([
    minValue.toString(),
    maxValue.toString(),
  ]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { isMinInput, value } = getValueAndPurpose(
      event,
      MIN_INPUT_DATA_PURPOSE,
    );

    setInputValues(
      isMinInput ? [value, inputValues[1]] : [inputValues[0], value],
    );
  };

  const validateInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (validKeystrokes.includes(event.key)) {
      return;
    }

    if (invalidCharacters.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleDrag = useCallback(
    (sliderValues: Range) => {
      setSliderValues(sliderValues);
      setInputValues([sliderValues[0].toString(), sliderValues[1].toString()]);
    },
    [setSliderValues, setInputValues],
  );

  const handleDragEnd = useCallback(
    (sliderValues: Range) => {
      dispatchChange(sliderValues);
      onChange(sliderValues);
    },
    [dispatchChange, onChange],
  );

  const rangeChangeHandler = useRangeChange({
    maxLimit,
    minLimit,
    minInputDataPurpose: MIN_INPUT_DATA_PURPOSE,
    oldRangeValues: [sliderValues[0], sliderValues[1]],
    onUpdate: (values) => {
      setSliderValues(values);
      dispatchChange(values);
      onChange(values);
    },
  });

  useEffect(() => {
    setSliderValues([minValue, maxValue]);
    setInputValues([minValue.toString(), maxValue.toString()]);
  }, [maxValue, minValue]);

  const inputAttributes = {
    className: styles.rangeInput,
    disabled,
    max: maxLimit,
    min: minLimit,
    title: i18n.label,
    type: 'number',
    onBlur: rangeChangeHandler,
    onKeyDown: validateInput,
    onKeyUp: useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          rangeChangeHandler(event);
        }
      },
      [rangeChangeHandler],
    ),
    onChange: handleInputChange,
  };

  return (
    <>
      {showInputFields && (
        <div className={styles.meta}>
          <div className={styles.range}>
            <input
              aria-label={i18n.from}
              data-purpose="rangeSlider.inputFrom"
              value={inputValues[0]}
              {...inputAttributes}
            />
            <div className={styles.unit}>{i18n.unit}</div>
          </div>
          <div className={styles.range}>
            <input
              aria-label={i18n.to}
              data-purpose="rangeSlider.inputTo"
              value={inputValues[1]}
              {...inputAttributes}
            />
            <div className={styles.unit}>{i18n.unit}</div>
          </div>
        </div>
      )}
      {metaLabel && (
        <div className={styles.metaText}>
          <div>
            {i18n.from} {i18n.unit} {inputValues[0]} {i18n.to} {i18n.unit}{' '}
            {inputValues[1]}
          </div>
          <div className={styles.label}>{metaLabel}</div>
        </div>
      )}
      <div className={styles.multiRange}>
        <MultiRange
          minLimit={minLimit}
          maxLimit={maxLimit}
          step={step}
          minValue={sliderValues[0]}
          maxValue={sliderValues[1]}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          ariaLabel={i18n}
          legend={i18n.label}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default DefaultRangeSlider;
