import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { sanitize } from 'isomorphic-dompurify';

export const getInitialValue = (
  selectedOption: string | null,
  options: Record<string, string>[],
  keyValue: string,
  seamless?: boolean,
) => {
  // Check for empty string as well, for preselection of empty option
  if (selectedOption || selectedOption === '') {
    return selectedOption;
  }
  if (seamless) {
    return options[0][keyValue];
  }
  return null;
};

const filterOptions = (
  option: Record<string, string>,
  filterText: string,
  keyName: string,
) => {
  const val = option[keyName];
  let searchableString = null;

  if (typeof val === 'object' && React.isValidElement(val)) {
    searchableString = renderToStaticMarkup(val);
    searchableString = sanitize(searchableString, {
      ALLOWED_TAGS: [],
    });
  } else if (typeof val === 'string') {
    searchableString = val;
  } else {
    return null;
  }

  return (
    searchableString
      ?.toLocaleLowerCase()
      ?.indexOf(filterText?.toLocaleLowerCase() ?? '') > -1
  );
};

export const getFilteredOptions = (
  options: Record<string, string>[],
  keyName: string,
  filterText?: string,
) => {
  if (!filterText) {
    return options;
  }

  return options.filter((option) => filterOptions(option, filterText, keyName));
};

const renderPlaceholder = (
  label: string,
  required?: boolean,
  requiredSymbol?: string,
  optionalSymbol?: string,
  placeholder?: string,
) => {
  let newPlaceholder = placeholder || label;
  if (required && requiredSymbol) {
    newPlaceholder += ` ${requiredSymbol}`;
  }
  if (!required && optionalSymbol) {
    newPlaceholder += ` ${optionalSymbol}`;
  }
  return newPlaceholder;
};
export const renderSelectOrPlaceHolder = (
  value: string | null,
  options: Record<string, string>[],
  keyValue: string,
  keyName: string,
  label: string,
  placeholder?: string,
  required?: boolean,
  requiredSymbol?: string,
  optionalSymbol?: string,
) =>
  value
    ? renderSelectedOption(options, keyValue, keyName, value)
    : renderPlaceholder(
        label,
        required,
        requiredSymbol,
        optionalSymbol,
        placeholder,
      );

const renderSelectedOption = (
  options: Record<string, string>[],
  keyValue: string,
  keyName: string,
  value: string | null,
) => {
  let matchedOption = null;

  options.forEach((option: Record<string, string>) => {
    if (option[keyValue] === value) {
      matchedOption = option[keyName];
    }
  });

  return matchedOption;
};
