import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { config } from '@xxxlgroup/hydra-config';
import classNames from 'classnames';
import { ImageProps } from 'components/Image/Image.types';
import buildSizes from 'components/Image/utils/buildSizes';
import buildSrcSet from 'components/Image/utils/buildSrcSet';
import { Image as ImageType } from 'types/typeDefinitions';

import useInViewport from 'hooks/useInViewport';
import { fallbackImage } from '@xxxlgroup/hydra-icons';

import styles from 'components/Image/Image.scss';
import createImageUrl from '@xxxlgroup/hydra-utils/image/createImageUrl';
import svgEncode from '@xxxlgroup/hydra-utils/icon/svgEncode';

type ImageStatus = 'loading' | 'loaded';

const hasNativeLazyLoadSupport =
  typeof HTMLImageElement !== `undefined` &&
  `loading` in HTMLImageElement.prototype; // eslint-disable-line ssr-friendly/no-dom-globals-in-module-scope

const inViewportOptions = {
  once: true,
  root: null,
  rootMargin: '300px 0px 0px 0px',
  skip: false,
  isIntersecting: (entry: any) => entry.isIntersecting,
  threshold: 0,
};

const EMPTY_IMAGE =
  'data:image/svg+xml;charset=utf8,<svg style="width:auto; height:auto;" xmlns="http://www.w3.org/2000/svg"></svg>';

const setAltText = (altText: string, source: ImageType | string | null) =>
  altText || (source as ImageType)?.altText || '';

const setLazyFallback = (loading: 'eager' | 'lazy', isInViewport: boolean) =>
  !hasNativeLazyLoadSupport && loading === 'lazy' && !isInViewport;

const setImageLoadingStyle = (imageStatus: string) =>
  imageStatus === 'loading' && styles['image--loading'];

const Image = ({
  altText = '',
  aspectRatio,
  className,
  enableDevLogs = false,
  height = 0,
  loading = 'lazy',
  isDecorative = false,
  maxDPR = 2.0,
  sizes: sizesProp,
  source,
  transformationTemplate,
  useSrcSet = false,
  width = 0,
  upscale,
  ...other
}: ImageProps) => {
  const [imageStatus, setImageStatus] = useState<ImageStatus>('loading');
  const [devicePixelRatio, setDevicePixelRatio] = useState<number>(1.0);
  const [dpr, setDpr] = useState<number>(1.0);

  const shouldUseSrcSet = useMemo(
    () => useSrcSet && (width === 0 || width > config.srcSetImageSizes[0]),
    [useSrcSet, width],
  );

  const { setRef, isInViewport } = useInViewport(inViewportOptions);

  const imageClassName = useMemo(
    () => classNames(className, setImageLoadingStyle(imageStatus)),
    [className, imageStatus],
  );

  useEffect(() => {
    const detectedDPR = window.devicePixelRatio;
    const appliedDPR = Math.min(detectedDPR, maxDPR);

    setDevicePixelRatio(detectedDPR);
    setDpr(appliedDPR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLazyFallback = useMemo(
    () => setLazyFallback(loading, isInViewport),
    [isInViewport, loading],
  );

  const isSourceString = useMemo(() => typeof source === 'string', [source]);

  const imageSource = useMemo(() => {
    if (!source) {
      return null;
    }

    return isSourceString
      ? source
      : {
          ...(source as ImageType),
          hashCode:
            (source as ImageType).hashCode || (source as ImageType).cdnFilename,
        };
  }, [isSourceString, source]);

  const src = useMemo(() => {
    if (isSourceString) {
      return imageSource as string;
    }

    const img = imageSource as ImageType;
    return createImageUrl(img, {
      width: (width || img?.pictureWidth) ?? 0,
      height: (height || img?.pictureHeight) ?? 0,
      upscale,
      aspectRatio,
      formatAuto: true,
      transformationTemplate,
    });
  }, [
    aspectRatio,
    height,
    imageSource,
    isSourceString,
    transformationTemplate,
    upscale,
    width,
  ]);

  const srcSet = useMemo(() => {
    if (isSourceString || !shouldUseSrcSet || !source) {
      return null;
    }

    return buildSrcSet(imageSource as ImageType, {
      aspectRatio,
      formatAuto: true,
      transformationTemplate,
      upscale,
      width,
      devicePixelRatio: dpr,
    });
  }, [
    aspectRatio,
    dpr,
    imageSource,
    isSourceString,
    shouldUseSrcSet,
    source,
    transformationTemplate,
    upscale,
    width,
  ]);

  const sizes = useMemo(() => {
    if (isSourceString || !shouldUseSrcSet || !srcSet) {
      return null;
    }

    return sizesProp || (srcSet && buildSizes(srcSet));
  }, [isSourceString, shouldUseSrcSet, sizesProp, srcSet]);

  const onLoad = useCallback(() => {
    setImageStatus('loaded');

    if (enableDevLogs && !CONFIG.IS_PROD) {
      // eslint-disable-next-line no-console
      console.log({
        info: '[Hydra] Image > DevLogs',
        messages: [
          `detected devicePixelRatio: ${devicePixelRatio}`,
          `provided DPR to optimize srcSet: ${dpr}`,
          `used width for building src: ${
            width || (source as ImageType)?.pictureWidth
          }`,
          `used width for building srcSet: ${
            srcSet?.substring(Number(srcSet?.lastIndexOf(' ')) + 1) ?? '/'
          }`,
          `useSrcSet adjusted to: ${shouldUseSrcSet}`,
        ],
        source,
      });
    }
  }, [
    devicePixelRatio,
    dpr,
    enableDevLogs,
    shouldUseSrcSet,
    source,
    srcSet,
    width,
  ]);

  const imgAttributes = useMemo(() => {
    if (isLazyFallback) {
      return { ref: setRef, width, height, src: EMPTY_IMAGE };
    }

    if (!source) {
      return {};
    }

    return {
      onLoad,
      /* Setting `sizes` after `srcset` or `srcset` after `src` will result in duplicate image downloads for Safari.
       * To solve both problems, the order of the props needs to be exactly `sizes`, `srcset` and finally `src`.
       *
       * Note: when native lazyloading is used (supported since Safari 15.4), both bugs disappear.
       *
       * See these links for reference:
       * https://jira.xxxlgroup.com/browse/XXXL-36743
       * https://jira.xxxlgroup.com/browse/XXXL-44018
       * https://bugs.webkit.org/show_bug.cgi?id=177068
       * https://bugs.webkit.org/show_bug.cgi?id=190031
       */
      ...(sizes && { sizes }),
      ...(srcSet && { srcSet }),
      ...(src && { src }),
    };
  }, [
    height,
    isLazyFallback,
    onLoad,
    setRef,
    sizes,
    source,
    src,
    srcSet,
    width,
  ]);

  const dimensionAttributes = useMemo(() => {
    let areDimensionsSet = false;

    const result: {
      height?: number;
      width?: number;
    } = {};

    const updateDimensions = (obj: { [key: string]: number | undefined }) => {
      let updated = false;

      Object.keys(obj).forEach((key) => {
        const keyValue = Number(obj[key]);
        if (keyValue > 0) {
          result[key as keyof typeof result] = keyValue;
          updated = true;
        }
      });

      return updated;
    };

    // Image component dimensions that override Image.source dimensions
    areDimensionsSet = updateDimensions({ height, width });

    if (!areDimensionsSet) {
      // Image.source dimensions
      updateDimensions({
        height: (source as ImageType)?.pictureHeight ?? undefined,
        width: (source as ImageType)?.pictureWidth ?? undefined,
      });
    }

    return result;
  }, [height, source, width]);

  const alt = isDecorative ? '' : setAltText(altText, imageSource);

  if (!source) {
    return (
      <div className={classNames(className, styles.background)}>
        <img
          className={styles.placeholder}
          src={svgEncode(fallbackImage[1])}
          alt={alt}
          {...other}
        />
      </div>
    );
  }

  return (
    <img
      alt={alt}
      className={imageClassName}
      loading={loading}
      {...other}
      {...imgAttributes}
      {...dimensionAttributes}
    />
  );
};

export default Image;
