/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
import React, { useState, useCallback, forwardRef } from 'react';
import classnames from 'classnames';
import Content from 'components/Content';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { ElementsInterfaces } from 'components/Content/contentSemanticElements';

import styles from 'components/RadioGroup/RadioGroup.scss';

interface RadioGroupProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  /** Default value selected in the radio group. */
  defaultValue?: string;
  /** If true, the group will be displayed as disabled. */
  disabled?: boolean;
  /** set styles for the title of the group */
  legendClassName?: string;
  /** Set a name for the radio group. *Note:* required for correct browser behaviour. Otherwise the operation by keyboard does not work as expected. */
  name: string;
  /** Callback function that will be invoked when the value changes. */
  onChange: (
    radioValue: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  /** The field is mandatory and must be filled. *Notice:* An asterisk is automatically appended to the label/title. */
  required: boolean;
  /** Show legend, per default visuallyHidden */
  hideLegend?: boolean;
  /** title to be shown for this radioGroup */
  title?: string;
  /** Prop for controlling the value of the RadioGroup. */
  value?: string;
}

const RadioGroup = forwardRef<ElementsInterfaces, RadioGroupProps>(
  (
    {
      children,
      className,
      defaultValue,
      disabled = false,
      legendClassName,
      name,
      onChange = noop,
      required = false,
      hideLegend = true,
      title,
      value,
      ...other
    },
    ref,
  ) => {
    const [stateValue, setStateValue] = useState(defaultValue);

    const handleChange = useCallback(
      (propValue: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setStateValue(propValue);
        onChange(propValue, event);
      },
      [onChange, setStateValue],
    );

    const Tag = title ? 'fieldset' : 'div';
    const conditionalRole = !title && { role: 'radiogroup' };

    return (
      <Tag
        {...other}
        className={classnames(styles.radioGroup, className)}
        {...conditionalRole}
      >
        {title && (
          <legend
            className={classnames(
              styles.legend,
              { [styles.legendHidden]: hideLegend },
              legendClassName,
            )}
          >
            <Content content={title} forwardedRef={ref} tag="span" />
          </legend>
        )}
        {React.Children.map(children, (child: React.ReactElement) =>
          React.cloneElement(child, {
            checked: child.props.value === (value || stateValue),
            disabled: disabled || child.props.disabled,
            key: value,
            name,
            required,
            'aria-checked': child.props.value === (value || stateValue),
            onChange: handleChange(child.props.value),
          }),
        )}
      </Tag>
    );
  },
);

export default RadioGroup;
