import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import IndicatorsBar from 'components/Carousel/components/IndicatorsBar';
import Arrows from 'components/Carousel/components/Arrows';
import useCombinedRefs from 'components/Carousel/hooks/useCombinedRefs';
import { CarouselProps } from 'components/Carousel/Carousel.types';
import { useCarouselNavigation } from 'components/Carousel/useCarouselNavigation';
import {
  hasScrollFunctionality,
  mapChildren,
} from 'components/Carousel/Carousel.helpers';
import styles from 'components/Carousel/Carousel.scss';
import { noop } from '@xxxlgroup/hydra-utils/common';

const Carousel = forwardRef<HTMLDivElement, CarouselProps>(
  (
    {
      autoPlay = false,
      animationSpeed = 'fast',
      classNameArrow,
      children,
      className,
      arrowAttributes = {
        size: 'large',
        type: 'empty',
        showDisabled: false,
        onArrowClick: noop,
      },
      classNameContainer,
      fullWidth = false,
      i18n,
      isLooped = false,
      onSlideChanged,
      roleContainer = 'group',
      sneakPeekDisabled = false,
      scrollDirection = 'horizontal',
      ...props
    },
    forwardedRef,
  ) => {
    const internalOnSlideChanged = useCallback(
      (oldIndex: number, newIndex: number, event: Event | undefined) => {
        setSlideIndex(newIndex);
        onSlideChanged?.(oldIndex, newIndex, event);
      },
      [onSlideChanged],
    );

    const {
      ref,
      scrollableLeft,
      scrollableRight,
      scrollableBottom,
      scrollableTop,
      onLeft,
      onRight,
      onMouseDown,
      onMouseUp,
      onMouseEnter,
      onTouchStart,
      onMouseLeave,
      onMouseDrag,
      onMouseMove,
      scrollToPage,
      onFocus,
    } = useCarouselNavigation(
      internalOnSlideChanged,
      fullWidth,
      autoPlay,
      isLooped,
      animationSpeed,
      sneakPeekDisabled,
      scrollDirection,
    );

    const combinedRef = useCombinedRefs(ref, forwardedRef);
    const [slideIndex, setSlideIndex] = useState(0);
    const [isLastControlDisabled, setIsLastControlDisabled] = useState(false);

    useEffect(() => {
      const scrollContainer = ref?.current;
      if (
        scrollContainer &&
        hasScrollFunctionality(scrollContainer, scrollDirection)
      ) {
        setIsLastControlDisabled(true);
      } else {
        setIsLastControlDisabled(false);
      }
    }, [ref, ref.current?.children.length, scrollDirection]);

    useEffect(() => {
      if (combinedRef.current && isLooped && fullWidth) {
        combinedRef.current.classList.add(styles.resetElement);
        combinedRef.current.scrollLeft = ref.current?.clientWidth ?? 0;
      }
    }, [combinedRef, fullWidth, isLooped, ref]);

    const slideToPage = (newPage: number) => () => {
      scrollToPage(newPage);
    };

    const childrenCount = React.Children.count(children);

    const [scrollableStart, scrollableEnd] =
      scrollDirection === 'vertical'
        ? [scrollableTop, scrollableBottom]
        : [scrollableLeft, scrollableRight];

    const navigationEnabled = childrenCount > 1;
    const arrowProps = {
      arrowAttributes,
      scrollableStart,
      scrollableEnd,
      classNameArrow,
      onLeft,
      onRight,
      onMouseLeave,
      onMouseEnter,
      isLastControlDisabled,
      i18n: { next: i18n.next, previous: i18n.previous },
      scrollDirection,
    };

    const { onArrowClick } = arrowAttributes;

    const handleLeftArrowClick = useCallback(
      (event: React.MouseEvent) => {
        onLeft(event, scrollDirection);
        onArrowClick?.(event);
      },
      [onArrowClick, onLeft, scrollDirection],
    );

    const handleRightArrowClick = useCallback(
      (event: React.MouseEvent) => {
        onRight(event, scrollDirection);
        onArrowClick?.(event);
      },
      [onArrowClick, onRight, scrollDirection],
    );

    return (
      <section
        aria-roledescription="carousel"
        className={classnames(styles.container, classNameContainer)}
        role={roleContainer}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <div
          role="group"
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onDragStart={onMouseDrag}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
          onTouchStart={onTouchStart}
          onFocus={onFocus}
          data-childcount={React.Children.count(children)}
          ref={combinedRef}
          className={classnames(
            styles[`${scrollDirection}Carousel`],
            {
              [styles.fullWidth]: !!fullWidth,
              [styles.hideElement]: !!isLooped,
            },
            className,
          )}
          {...props}
        >
          {fullWidth && isLooped ? mapChildren(children) : children}
        </div>
        {navigationEnabled && (
          <>
            <Arrows
              {...arrowProps}
              onLeft={handleLeftArrowClick}
              onRight={handleRightArrowClick}
            />
            {fullWidth && (
              <IndicatorsBar
                activeIndex={slideIndex}
                className={styles.indicators}
                i18n={{ goto: i18n.goTo }}
                numberOfBigIndicators={childrenCount}
                numberOfIndicators={childrenCount}
                onClick={slideToPage}
              />
            )}
          </>
        )}
      </section>
    );
  },
);

export default Carousel;
