import React, { FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';
import { MultiRangeProps } from 'components/RangeSlider/components/MultiRange/MultiRange.types';
import useRangeChange from 'components/RangeSlider/hooks/useRangeChange';
import styles from 'components/RangeSlider/components/MultiRange/MultiRange.scss';

const MIN_INPUT_DATA_PURPOSE = 'input.range.from';

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const MultiRange: FunctionComponent<MultiRangeProps> = ({
  ariaLabel,
  disabled = false,
  legend,
  maxLimit,
  maxValue,
  minLimit,
  minValue,
  onDrag,
  onDragEnd,
  step = 1,
}) => {
  const handleDrag = useRangeChange({
    maxLimit,
    minLimit,
    minInputDataPurpose: MIN_INPUT_DATA_PURPOSE,
    oldRangeValues: [minValue, maxValue],
    onUpdate: onDrag,
  });

  const handleDragEnd = useCallback(() => {
    onDragEnd([minValue, maxValue]);
  }, [onDragEnd, minValue, maxValue]);

  const trackPosition = {
    left: `${((minValue - minLimit) / (maxLimit - minLimit)) * 100}%`,
    right: `${100 - ((maxValue - minLimit) / (maxLimit - minLimit)) * 100}%`,
  };

  const baseProps = {
    'aria-valuemax': maxLimit,
    'aria-valuemin': minLimit,
    disabled,
    max: maxLimit,
    min: minLimit,
    onChange: handleDrag,
    onMouseUp: handleDragEnd,
    onTouchEnd: handleDragEnd,
    role: 'slider',
    step,
  };

  return (
    <fieldset className={styles.range}>
      {legend && <legend className={styles.legend}>{legend}</legend>}
      <div className={styles.track}>
        <div
          style={trackPosition}
          className={classnames(styles.selectedTrack, {
            [styles.disabled]: disabled,
          })}
        />
      </div>
      <input
        type="range"
        data-purpose="input.range.from"
        value={minValue}
        aria-valuenow={minValue}
        className={styles.minimum}
        aria-label={ariaLabel?.from}
        {...baseProps}
      />
      <input
        type="range"
        data-purpose="input.range.to"
        value={maxValue}
        aria-valuenow={maxValue}
        className={styles.maximum}
        aria-label={ariaLabel?.to}
        {...baseProps}
      />
    </fieldset>
  );
};

export default MultiRange;
