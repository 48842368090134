import React, { Fragment } from 'react';
import { InputType, i18nType } from 'components/Input/Input.types';
import classnames from 'classnames';
import { visibilityOn, visibilityOff } from '@xxxlgroup/hydra-icons';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import InputIcon from 'components/Input/components/InputIcon';
import styles from 'components/Input/components/ActionButton/ActionButton.scss';

interface ActionButtonProps {
  /** Adds a react element at the end of the input field for special actions */
  actionButton?: React.ReactElement;
  /** Clones and returns a new react element for each children */
  cloneChildren: (children: React.ReactElement, componentName: string) => void;
  /** Translations for the meaning of the asterisk and aria-label's and title's for icons and iconButtons */
  i18n: i18nType;
  /** Flag to determine wether password should be visible or not */
  showPassword: boolean;
  /** Toogle state of showPassword flag */
  togglePasswordVisibility: () => void;
  /** One of a list of predefined types described separately */
  type: InputType;
}

const ActionButton = ({
  actionButton,
  cloneChildren,
  i18n,
  showPassword,
  togglePasswordVisibility,
  type,
}: ActionButtonProps) => {
  if (type === 'password') {
    const inputIconAttributes = showPassword ? visibilityOff : visibilityOn;
    const otherProps = {
      role: 'switch',
      'aria-checked': showPassword,
      'aria-label': i18n.showPassword,
    };

    return (
      <IconButton
        ariaLabel={i18n.showPassword}
        className={styles.iconButton}
        data-testid="togglePasswordVisibility.button"
        glyph={inputIconAttributes}
        onClick={togglePasswordVisibility}
        title={i18n.showPassword}
        {...otherProps}
      />
    );
  }

  if (actionButton?.props) {
    if (actionButton.type === Fragment) {
      return <>{cloneChildren(actionButton.props.children, 'actionButton')}</>;
    }

    const { actionButtonClassName, ...otherProps } = actionButton.props;

    switch (actionButton.type) {
      case Icon:
        return <InputIcon className={actionButtonClassName} {...otherProps} />;
      case IconButton:
        return (
          <IconButton
            className={classnames(styles.iconButton, actionButtonClassName)}
            glyph={actionButton.props.glyph}
            {...otherProps}
          />
        );
      default:
        return (
          <actionButton.type
            className={actionButtonClassName}
            {...otherProps}
          />
        );
    }
  }
  return null;
};

export default ActionButton;
