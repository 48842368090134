import React from 'react';
import AnimateHeight from 'components/AnimateHeight';
import classnames from 'classnames';

import styles from 'components/Expander/components/TextVariant/TextVariant.scss';
import { ContentHeight } from 'components/Expander/Expander.types';

export interface TextVariantProps {
  animationEnded: (props: { newHeight: number }) => void;
  content: React.ReactNode;
  className?: string;
  contentHeight: ContentHeight;
  ellipsisClass: boolean;
  initialHeight: number | string;
  isExpanded: boolean;
  expanderButton: React.ReactNode;
  id: string;
}

const TextVariant = React.forwardRef<HTMLDivElement, TextVariantProps>(
  (
    {
      animationEnded,
      content,
      className,
      contentHeight,
      ellipsisClass,
      isExpanded,
      initialHeight,
      expanderButton,
      id,
    },
    ref,
  ) => (
    <div className={styles.wrapper}>
      {expanderButton}
      <AnimateHeight
        id={id}
        aria-hidden={!isExpanded}
        onAnimationEnd={animationEnded}
        height={isExpanded ? 'auto' : initialHeight}
        className={classnames(styles.block, {
          [styles.animatedBlock]: !isExpanded && contentHeight !== 'xs',
        })}
      >
        <div
          className={classnames(
            styles.textVariant,
            className,
            ellipsisClass && !isExpanded && styles[`ellipsis-${contentHeight}`],
          )}
          ref={ref}
          data-testid="text"
        >
          {content}
        </div>
      </AnimateHeight>
    </div>
  ),
);

export default TextVariant;
