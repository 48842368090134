import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import {
  RangeSliderVariant,
  RangeSliderProps,
} from 'components/RangeSlider/RangeSlider.types';

import RegularVariant from 'components/RangeSlider/components/RegularVariant';
import ReducedVariant from 'components/RangeSlider/components/ReducedVariant';

import styles from 'components/RangeSlider/RangeSlider.scss';

const variants = {
  [RangeSliderVariant.REGULAR]: RegularVariant,
  [RangeSliderVariant.REDUCED]: ReducedVariant,
};

const RangeSlider: FunctionComponent<RangeSliderProps> = (props) => {
  const {
    className,
    variant = RangeSliderVariant.REGULAR,
    'data-purpose': dataPurpose,
  } = props;

  return (
    <div
      className={classnames(styles.container, className)}
      data-purpose={dataPurpose}
    >
      {React.createElement(variants[variant], props)}
    </div>
  );
};

export default RangeSlider;
