import createPropError from 'utils/createPropError';

const isLabelValid = (energyLabel: string) =>
  /^([aA]\+{0,3})$|^[b-gB-G]$/.test(energyLabel);

export const checkPropTypeLabel = (
  allProps: Record<string, any>,
  propName: string,
  componentName: string,
) => {
  if (!isLabelValid(allProps[propName])) {
    return createPropError(
      propName,
      componentName,
      'Please provide a valid label!',
    );
  }
  return null;
};
