import React, { Children, isValidElement, ReactNode, useId } from 'react';
import { ScrollDirectionType } from './Carousel.types';

/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unused-vars */
// @ts-ignore
declare module 'react' {
  interface HTMLAttributes<T> {
    /**
     * Prevents focus from moving to any element inside this DOM element and ignores user events.
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
     * We need to extend the HTMLAttributes interface to add the inert attribute because React doesn't support it yet.
     */
    inert?: 'inert';
  }
}
/* eslint-enable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unused-vars */

const childrenList = (children: ReactNode) => {
  const initialChildren = Children.toArray(children);
  if (initialChildren.length >= 1) {
    const [firstElement, ...restElements] = initialChildren;
    const lastElement = restElements[restElements.length - 1];

    if (isValidElement(firstElement) && isValidElement(lastElement)) {
      return [lastElement, ...initialChildren, firstElement];
    }
  }

  return initialChildren;
};

export const hasScrollFunctionality = (
  scrollContainer: HTMLDivElement,
  scrollDirection: ScrollDirectionType,
) => {
  const { clientWidth, clientHeight, scrollWidth, scrollHeight } =
    scrollContainer;
  const [clientDimension, scrollDimenstion] =
    scrollDirection === 'horizontal'
      ? [clientWidth, scrollWidth]
      : [clientHeight, scrollHeight];
  if (clientDimension > 0 && clientDimension === scrollDimenstion) {
    return true;
  }
  return false;
};

export const mapChildren = (children: ReactNode) => {
  const shiftedChildren = childrenList(children);
  return shiftedChildren.length > 1
    ? shiftedChildren.map((child, idx) => (
        <div
          inert={
            idx === 0 || idx === shiftedChildren.length - 1
              ? 'inert'
              : undefined
          }
          key={`carousel-item-${useId()}`}
        >
          {child}
        </div>
      ))
    : shiftedChildren;
};
