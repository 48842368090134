import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { x as close } from '@xxxlgroup/hydra-icons';
import Icon from 'components/Icon';

import { ChipProps } from 'components/Chip/Chip.types';
import ChipContent from 'components/Chip/components/ChipContent';

import styles from 'components/Chip/Chip.scss';

const Chip: React.FC<ChipProps> = ({
  deleteButtonAriaLabel,
  disabled = false,
  content,
  glyph,
  i18n,
  image,
  indication,
  initActive = false,
  onClick,
  onChange,
  onRemove,
  ...other
}) => {
  const [active, setActive] = useState(initActive);

  const getGlyph = () => (typeof glyph === 'object' ? glyph : undefined);
  const getColor = () => (typeof glyph === 'string' ? glyph : undefined);

  useEffect(() => {
    setActive(initActive);
  }, [initActive]);

  const getClassNames = (additionalClass?: string) =>
    classnames(
      styles.container,
      {
        [styles.disabled]: disabled,
        [styles.active]: active,
      },
      additionalClass,
    );

  const handleChange = () => {
    setActive((prevActive) => {
      onChange?.(!prevActive);
      return !prevActive;
    });
  };

  const renderContent = () => (
    <ChipContent
      content={content}
      color={getColor()}
      glyph={getGlyph()}
      image={image}
      indication={indication}
      onRemove={onRemove}
      active={active}
      {...other}
    />
  );

  if (onClick) {
    const buttonAttr = {
      'aria-label': i18n?.ariaLabel,
      className: getClassNames(styles.clickable),
      onClick,
      ...(other['data-purpose']
        ? { 'data-purpose': other['data-purpose'] }
        : {}),
    };

    return (
      <button type="button" {...buttonAttr}>
        {renderContent()}
      </button>
    );
  }

  if (onRemove) {
    const ariaLabel = i18n?.remove ?? '';

    return (
      <div className={getClassNames(styles.removable)}>
        {renderContent()}
        <button
          className={styles.remove}
          type="button"
          aria-label={deleteButtonAriaLabel || ariaLabel}
          onClick={onRemove}
          {...(other['data-purpose']
            ? { 'data-purpose': other['data-purpose'] }
            : {})}
        >
          <Icon className={styles.iconAfter} glyph={close} />
        </button>
      </div>
    );
  }

  if (onChange) {
    return (
      <label className={getClassNames(styles.toggle)}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={active}
          onChange={handleChange}
          {...(other['data-purpose']
            ? { 'data-purpose': other['data-purpose'] }
            : {})}
        />
        {renderContent()}
      </label>
    );
  }

  return <div className={getClassNames()}>{renderContent()}</div>;
};

export default Chip;
