import React from 'react';
import { ThemeType } from 'components/Link/Link.types';

export interface ExpanderProps {
  children: React.ReactNode;
  className?: string;
  contentHeight?: ContentHeight;
  defaultExpanded?: boolean;
  i18n?: {
    showMore: React.ReactNode;
    showLess: React.ReactNode;
  };
  listType?: ListType;
  onExpand?: (event: React.MouseEvent, isExpanded: boolean) => void;
  variant: Variant;
  theme?: ThemeType;
}

export interface ExpanderButtonProps {
  handleClick: (event: React.MouseEvent) => void;
  isExpanded: boolean;
  i18n: {
    showMore?: React.ReactNode;
    showLess?: React.ReactNode;
  };
  theme?: ThemeType;
  visible: boolean;
  idToControl: string;
}

export type Variant = 'text' | 'list';

export type ListType = 'ul' | 'ol';

export type ContentHeight = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const getTextSize = (size: ContentHeight) => {
  const rows = {
    xs: 0,
    sm: 1,
    md: 3,
    lg: 5,
    xl: 12,
  };

  return rows[size];
};
