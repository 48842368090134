import React, { useCallback, useEffect, useState, FC, useRef } from 'react';
import {
  ProgressStepperDirectionProps,
  ProgressStepperStatusType,
  STEP_SCROLL_ANIM_DURATION,
} from 'components/ProgressStepper/ProgressStepper.types';
import VerticalStep from 'components/ProgressStepper/components/ProgressStepperVertical/components/VerticalStep';
import { prepareStepsProps } from 'components/ProgressStepper/utils/prepareStepsProps';

interface StepIconPropsType {
  stepNumber: string;
  stepIndex: number;
  onClick: () => void;
  status: ProgressStepperStatusType;
  isClickDisabled?: boolean;
}

const generateScrollableId = (index: number) => `vertical-stepper-${index}`;

const smoothStepperScroll = (index: number) => {
  const yOffset = -24;
  const element = document.getElementById(generateScrollableId(index));
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

const ProgressStepperVertical: FC<ProgressStepperDirectionProps> = ({
  isClickOnInactiveDisabled,
  isClickOnFinishedDisabled,
  scrollToActiveStep,
  areFinishedTitlesClickable,
  className,
  current = 0,
  onChange,
  stepHeadingLevel,
  stepHeadingSEO,
  children,
}) => {
  const [stepsProps, setStepProps] = useState<Array<StepIconPropsType>>([]);
  const isFirstRender = useRef(true);

  useEffect(() => {
    // We need this to make sure that it doesn't scroll at first load.
    if (isFirstRender.current) {
      return;
    }
    // We need to wait as the step has changed, so to adjust only after it changed.
    if (scrollToActiveStep) {
      setTimeout(() => {
        smoothStepperScroll(current);
      }, STEP_SCROLL_ANIM_DURATION);
    }
  }, [current, scrollToActiveStep]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const handleOnStepChange = useCallback(
    (index: number) => () => {
      if (onChange) {
        onChange(index);
      }
      if (scrollToActiveStep) {
        smoothStepperScroll(index);
      }
    },
    [onChange, scrollToActiveStep],
  );

  /**
   * Takes the ProgressStep children and extracts the props,
   * adds the properties stepNumber, stepIndex, onClick, disableClick and status
   */
  const prepareStepsPropsState = useCallback(() => {
    setStepProps(
      prepareStepsProps(
        children,
        handleOnStepChange,
        current,
        isClickOnFinishedDisabled,
        isClickOnInactiveDisabled,
      ),
    );
  }, [
    children,
    current,
    isClickOnFinishedDisabled,
    isClickOnInactiveDisabled,
    handleOnStepChange,
  ]);

  useEffect(() => {
    prepareStepsPropsState();
  }, [current, prepareStepsPropsState]);

  return (
    <div className={className}>
      {stepsProps.map((stepProps) => (
        <VerticalStep
          id={generateScrollableId(stepProps.stepIndex)}
          isFinishedTitleClickable={areFinishedTitlesClickable}
          key={stepProps.stepIndex}
          headingLevel={stepHeadingLevel}
          headingSEO={stepHeadingSEO}
          {...stepProps}
        />
      ))}
    </div>
  );
};

export default ProgressStepperVertical;
