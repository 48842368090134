import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { IconProps } from 'components/Icon/Icon.types';
import styles from 'components/Icon/Icon.scss';

const Icon = forwardRef<SVGSVGElement | HTMLElement, IconProps>(
  ({ ariaLabel, className, glyph, role = 'presentation', ...other }, ref) => {
    if (!glyph || glyph.length !== 2) {
      return null;
    }

    const [, content] = glyph;
    const adjustedAriaLabel = role === 'presentation' ? undefined : ariaLabel;

    const svgAttr = {
      ...other,
      'aria-label': adjustedAriaLabel,
      className: classNames(styles.icon, className),
      role,
    };

    return (
      <i
        {...svgAttr}
        ref={ref as React.MutableRefObject<HTMLElement>}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  },
);

Icon.displayName = 'Icon'; // needed for Input component only (Affix.js)

export default Icon;
