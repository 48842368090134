import React from 'react';
import { Glyph, SEOLevel } from 'types/typeDefinitions';
import { HeadingLevel } from 'components/Heading/Heading.types';

export const STEP_SCROLL_ANIM_DURATION = 300;

export type ProgressStepperStatusType = 'active' | 'inactive' | 'finished';

export type ProgressStepperDirection = 'horizontal' | 'vertical';

export type CircleVariant = 'loud' | 'silent';

export type ThemeVariant = 'primary' | 'secondary' | 'tertiary' | 'dark';

export interface ProgressStepperDirectionProps {
  /** @ignore */
  className?: string;
  /** To set the current step (counting from 0). You can overwrite this state by using status of Step */
  current?: number;
  /** Callback when Step is changed */
  onChange?: (index: number) => void;
  /** @ignore */
  children: React.ReactNode;
  /** @deprecated - use isClickOnInactiveDisabled instead */
  disableClickOnInactive?: boolean;
  /** @deprecated - use isClickOnFinishedDisabled instead */
  disableClickOnFinished?: boolean;
  /** Allows the user to click on open steps, default: true */
  isClickOnInactiveDisabled?: boolean;
  /** Allows the user to click on finished steps, default: true */
  isClickOnFinishedDisabled?: boolean;
  /** Makes it scroll when active step changes NOTE: This does not work for horizontal stepper, default: false */
  scrollToActiveStep?: boolean;
  /** Makes step titles clickable and set current state NOTE: This does not work in horizontal stepper, default: false */
  areFinishedTitlesClickable?: boolean;
  /** Optional adjustment of level of step heading (only works with vertical stepper) */
  stepHeadingLevel?: HeadingLevel;
  /** Optional property to render actual HTML step heading for SEO (only works with vertical stepper) */
  stepHeadingSEO?: boolean | SEOLevel;
}

export interface ProgressStepProps {
  /** @deprecated - use isClickDisabled instead */
  disableClick?: boolean;
  /** To set if a click-event is executed or not. */
  isClickDisabled?: boolean;
  /** allows to control the edit button whether it is disabled or not, default: false */
  isEditButtonDisabled?: boolean;
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Node, which is shown inside the edit-button. Currently only for Vertical Stepper */
  editButtonText?: React.ReactNode;
  /** Icon of the step */
  glyph?: Glyph;
  /** Icon which shall be shown, if the step is finished. */
  glyphFinished?: Glyph;
  /** Overwrite the status */
  status?: ProgressStepperStatusType;
  /** SubTitle of the step */
  subTitle?: React.ReactNode;
  /** Title of the step */
  title?: string;
}
export interface StepIconPropsType extends ProgressStepProps {
  stepNumber: string;
  stepIndex: number;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  currentInteractionStatus: ProgressStepperStatusType;
  status: ProgressStepperStatusType;
}
