import React, { Fragment } from 'react';

import styles from 'components/Input/components/Affix/Affix.scss';
import InputIcon from '../InputIcon';

interface AffixProps {
  /** React Element */
  children?: React.ReactNode;
  /** @ignore */
  className?: string;
  /** Clones and returns a new react element for each children */
  cloneChildren: (children: React.ReactElement, componentName: string) => void;
  /** Element or string which is added at the beginning or end of the input field */
  content: JSX.Element | string;
}

const Affix = ({ cloneChildren, content }: AffixProps) => {
  if (typeof content === 'string') {
    return <span className={styles.attribute}>{content}</span>;
  }

  const { type: Type, props } = content;
  if (props) {
    if (Type === Fragment) {
      return <>{cloneChildren(props.children, 'affix')}</>;
    }

    const { className: affixClassName, ...otherProps } = props;

    if (Type.displayName === 'Icon') {
      return <InputIcon className={affixClassName} {...otherProps} />;
    }

    return <Type className={affixClassName} {...otherProps} />;
  }

  return null;
};

export default Affix;
