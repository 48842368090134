/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
export type Elements =
  | 'div'
  | 'article'
  | 'aside'
  | 'details'
  | 'figcaption'
  | 'figure'
  | 'footer'
  | 'header'
  | 'hr'
  | 'main'
  | 'nav'
  | 'section'
  | 'summary'
  | 'span'
  | 'p'
  | 'b'
  | 'strong'
  | 'i'
  | 'u'
  | 'ol'
  | 'ul'
  | 'li'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6';

export type ParagraphPhraseTags =
  | 'a'
  | 'abbr'
  | 'audio'
  | 'b'
  | 'br'
  | 'cite'
  | 'em'
  | 'i'
  | 'img'
  | 'ins'
  | 'label'
  | 'map'
  | 'mark'
  | 'picture'
  | 'q'
  | 's'
  | 'small'
  | 'span'
  | 'strong'
  | 'sub'
  | 'sup'
  | 'SVG'
  | 'svg'
  | 'textarea'
  | 'use'
  | 'video';

export interface ContentSemanticElements {
  elements: Elements[];
  paragraphPhraseTags: ParagraphPhraseTags[];
  forbiddenAttr: string[];
  addAttr: string[];
}

// converts union types (e.g.: 'a' | 'b') to intersection types  (e.g.: 'a' & 'b')
type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (
  x: infer R,
) => any
  ? R
  : never;
export type ElementsInterfaces = UnionToIntersection<
  HTMLElementTagNameMap[Elements]
>;

export default {
  elements: [
    'div',
    'article',
    'aside',
    'details',
    'figcaption',
    'figure',
    'footer',
    'header',
    'hr',
    'main',
    'nav',
    'section',
    'summary',
    'span',
    'p',
    'b',
    'strong',
    'i',
    'u',
    'ol',
    'ul',
    'li',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
  ],
  paragraphPhraseTags: [
    'a',
    'abbr',
    'audio',
    'b',
    'br',
    'cite',
    'em',
    'i',
    'img',
    'ins',
    'label',
    'map',
    'mark',
    'picture',
    'q',
    's',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'SVG',
    'svg',
    'textarea',
    'use',
    'video',
  ],
  forbiddenAttr: ['style'],
  addAttr: ['target'],
} as ContentSemanticElements;
