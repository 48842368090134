import { ChangeEvent, EventHandler, KeyboardEvent, useCallback } from 'react';
import {
  getNearestNumberWithinRange,
  getValueAndPurpose,
} from 'components/RangeSlider/utils';
import {
  MultiRangeChange,
  Range,
} from 'components/RangeSlider/components/MultiRange/MultiRange.types';

interface UseRangeChangeProps {
  maxLimit: number;
  minLimit: number;
  onUpdate: MultiRangeChange;
  minInputDataPurpose: string;
  oldRangeValues: Range;
}

type UseRangeChange = (
  props: UseRangeChangeProps,
) => EventHandler<
  ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
>;

const useRangeChange: UseRangeChange = ({
  maxLimit,
  minLimit,
  minInputDataPurpose,
  onUpdate,
  oldRangeValues,
}) =>
  useCallback(
    (event) => {
      const { isMinInput, valueAsNumber } = getValueAndPurpose(
        event,
        minInputDataPurpose,
      );

      const numericValue = isMinInput ? minLimit : maxLimit;
      const value = Number.isNaN(valueAsNumber) ? numericValue : valueAsNumber;

      const newRangeValues: Range = isMinInput
        ? [
            getNearestNumberWithinRange(value, minLimit, maxLimit),
            getNearestNumberWithinRange(value, oldRangeValues[1], maxLimit),
          ]
        : [
            getNearestNumberWithinRange(value, minLimit, oldRangeValues[0]),
            getNearestNumberWithinRange(value, minLimit, maxLimit),
          ];

      onUpdate(newRangeValues);
    },
    [maxLimit, minLimit, onUpdate, oldRangeValues, minInputDataPurpose],
  );

export default useRangeChange;
