import { config } from '@xxxlgroup/hydra-config';
import createImageUrl, {
  ImageObject,
  PredefinedTransformationTemplates,
} from '@xxxlgroup/hydra-utils/image/createImageUrl';

interface BuildSrcSetOptions {
  /* aspect ratio is a string in the format of 'aspectWidth:aspectHeight' */
  aspectRatio?: string;
  /* The number of device pixels that make up a CSS pixel in one direction */
  devicePixelRatio?: number;
  /* formatAuto is a boolean that determines if amplience should choose the file format */
  formatAuto?: boolean;
  /* transformationTemplate is a string that allows quick application of multiple presets on amplience side */
  transformationTemplate?: PredefinedTransformationTemplates | string | null;
  /* upscale is a boolean that determines if and how the image should be upscaled */
  upscale?: boolean | 'padd';
  /** image width, used to build src */
  width?: number;
}

const buildSrcSet = (
  source: ImageObject,
  {
    upscale,
    aspectRatio,
    formatAuto = false,
    transformationTemplate = 'dq',
    width = 0,
    devicePixelRatio = 2.0,
  }: BuildSrcSetOptions = {},
): string => {
  let maxWidth = config.srcSetImageSizes[config.srcSetImageSizes.length - 1];

  if (width > 0) {
    maxWidth = width * devicePixelRatio;
  } else if (Number(source?.pictureWidth) > 0) {
    maxWidth = Number(source.pictureWidth) * devicePixelRatio;
  }

  const validWidthsThreshold =
    config.srcSetImageSizes.find((size) => size >= maxWidth) ?? maxWidth;
  const validWidths = config.srcSetImageSizes.filter(
    (size) => size <= validWidthsThreshold,
  );

  return validWidths
    .map(
      (temporaryWidth) =>
        `${createImageUrl(source, {
          width: temporaryWidth,
          aspectRatio,
          formatAuto,
          transformationTemplate,
          upscale,
        })} ${temporaryWidth}w`,
    )
    .join(', ');
};

export default buildSrcSet;
