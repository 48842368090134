import React from 'react';
import classnames from 'classnames';
import Content from 'components/Content';
import { chevronDown, chevronUp } from '@xxxlgroup/hydra-icons';
import { ActivatorButtonProps } from 'components/Accordion/components/Collapsible/Collapsible.types';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

import styles from 'components/Accordion/components/CollapsibleContent/CollapsibleContent.scss';

const ActivatorButton: React.FC<ActivatorButtonProps> = ({
  activator,
  activatorClassName,
  activatorId,
  dataPurpose,
  expandedContentId,
  hasInlineChevron,
  hasNoSpacingInActivator,
  isOpen,
  i18n,
  iconClassName,
  onChange,
  subTextClassName,
  subText,
}) => {
  const buttonAttributes = {
    role: 'button',
    'aria-expanded': isOpen,
    'aria-pressed': isOpen,
    'aria-controls': expandedContentId,
    'data-purpose': dataPurpose,
    className: classnames(styles.activator, activatorClassName, {
      [styles.activatorWithoutSpace]: hasNoSpacingInActivator,
    }),
    onClick: onChange,
    onKeyDown: onChange,
    tabIndex: 0,
    id: activatorId,
  };

  const [iconChevronDownStyle, iconChevronDownClassName] = pseudoIcon(
    chevronDown,
    'after',
  );

  const [iconChevronUpStyle, iconChevronUpClassName] = pseudoIcon(
    chevronUp,
    'after',
  );

  const [chevronStyle, chevronClassName, i18nContent] = isOpen
    ? [iconChevronUpStyle, iconChevronUpClassName, i18n?.showLess]
    : [iconChevronDownStyle, iconChevronDownClassName, i18n?.showMore];

  return (
    <div {...buttonAttributes}>
      <div
        className={classnames(
          styles.activatorText,
          styles.iconAfter,
          chevronClassName,
          {
            [styles.activatorTextWithInlineChevron]: hasInlineChevron,
          },
        )}
        style={chevronStyle}
      >
        <div
          className={classnames(styles.collapsibleBtn, {
            [styles.collapsibleBtnWithInlineChevron]: hasInlineChevron,
          })}
        >
          <div
            className={classnames(styles.activatorContent, activatorClassName)}
          >
            {activator}
          </div>
        </div>
        {i18n?.showMore && i18n?.showLess && (
          <Content
            className={classnames(styles.chevronText, iconClassName)}
            size="xxs"
            content={i18nContent}
          />
        )}
      </div>

      {subText && (
        <span className={classnames(styles.subText, subTextClassName)}>
          {subText}
        </span>
      )}
    </div>
  );
};

export default ActivatorButton;
