import React from 'react';
import classnames from 'classnames';

import {
  checkPropTypeLabel,
  checkPropTypeColor,
} from 'components/EnergyEfficiencyLabel2021/utils';

// Styles
import styles from 'components/EnergyEfficiencyLabel2021/EnergyEfficiencyLabel2021.scss';

type LetterSizeType = 'normal' | 'large';

interface EnergyEfficiencyLabel2021Props {
  /** @ignore */
  className?: string;
  /** @deprecated - since version 26.17.0 */
  color?: string;
  /** Sets the textual label */
  label: string;
  /** defines the letter size */
  letterSize?: LetterSizeType;
}

const labelMap: Record<string, string> = {
  A: styles.labelA,
  B: styles.labelB,
  C: styles.labelC,
  D: styles.labelD,
  E: styles.labelE,
  F: styles.labelF,
  G: styles.labelG,
};

const EnergyEfficiencyLabel2021 = ({
  label,
  className,
  color,
  letterSize,
  ...other
}: EnergyEfficiencyLabel2021Props) => {
  const attributes = {
    ...other,
    style: {},
  };

  if (color) {
    attributes.style = {
      backgroundColor: color,
      borderLeftColor: color,
    };
  }

  return (
    EnergyEfficiencyLabel2021.isLabelValid(label) && (
      <div
        className={classnames(styles.energyLabel, className, labelMap[label], {
          [styles.large]: letterSize === 'large',
        })}
        {...attributes}
      />
    )
  );
};

EnergyEfficiencyLabel2021.isLabelValid = (label: string) =>
  /^[a-gA-G]$/.test(label);

EnergyEfficiencyLabel2021.propTypes = {
  color: checkPropTypeColor,
  label: checkPropTypeLabel,
};

EnergyEfficiencyLabel2021.defaultProps = {
  color: null,
  label: 'A',
};

export default EnergyEfficiencyLabel2021;
