import React from 'react';
import classnames from 'classnames';
import { noop } from '@xxxlgroup/hydra-utils/common';

import styles from 'components/Backdrop/Backdrop.scss';

interface BackdropProps {
  className?: string;
  /** Custom data-purpose, e.g. for tracking */
  'data-purpose'?: string;
  /** Closes the backdrop */
  onClick?: (event: React.MouseEvent) => void;
}

const Backdrop = ({
  className,
  'data-purpose': dataPurpose = 'backdrop',
  onClick = noop,
}: BackdropProps) => (
  <div
    className={classnames(styles.backdrop, className)}
    data-purpose={dataPurpose}
    onClick={onClick}
    role="presentation"
    data-testid="backdrop"
  />
);

export default Backdrop;
