import React, {
  Children,
  useEffect,
  useReducer,
  useRef,
  isValidElement,
  ReactNode,
  useState,
  useCallback,
  useMemo,
} from 'react';
import debounce from 'lodash.debounce';
import classnames from 'classnames';
import HorizontalScrolling from 'components/HorizontalScrolling';
import { ImperativeRefType } from 'components/HorizontalScrolling/HorizontalScrolling.types';
import Tab from 'components/Tabs/components/Tab';
import ArrowButton from 'components/Tabs/components/ArrowButton';
import { TabContext, tabsReducer, ActionType } from 'components/Tabs/reducer';

import tabStyles from 'components/Tabs/components/Tab/Tab.scss';
import styles from 'components/Tabs/Tabs.scss';

type TabsProps = {
  /** A preselected item can be chosen */
  activeIndex?: number;
  /** Tabs container anchor for navigation via url */
  anchorId?: string;
  children: ReactNode;
  className?: string;
  /** Sets a class to give customized styles to content */
  contentClassName?: string;
  /** Sets a class to give customized styles to wrapper */
  wrapperClassName?: string;
};
type ArrowsIndexHashType = {
  firstIndex: number | null;
  lastIndex: number | null;
};

const TABS_PER_SCROLL = 2;

const Tabs = ({
  activeIndex = 0,
  anchorId,
  className,
  contentClassName,
  wrapperClassName,
  ...props
}: TabsProps) => {
  const [state, dispatch] = useReducer(tabsReducer, { activeIndex });
  const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);
  const [lastVisibleIndex, setLastVisibleIndex] = useState(0);
  const trackRef = useRef<ImperativeRefType>(null);
  const tabsCount = Children.count(props.children);

  useEffect(() => {
    dispatch({
      type: ActionType.SETINDEX,
      payload: activeIndex,
    });
  }, [activeIndex]);

  const handleNextTab = (
    firstTabInRound: number,
    nextTab: number,
    lastTabInRound: number,
  ) => {
    const nextTabToSelect =
      state.activeIndex === lastTabInRound ? firstTabInRound : nextTab;
    dispatch({
      type: ActionType.SETINDEX,
      payload: nextTabToSelect,
    });
  };

  const onArrowsUpdate = useCallback(() => {
    const parent = trackRef?.current?.getRef();
    const tabs =
      parent?.querySelectorAll<HTMLElement>(`.${CSS.escape(tabStyles.item)}`) ??
      [];
    const parentScrollLeft = parent?.scrollLeft ?? 0;
    const parentClientWidth = parent?.clientWidth ?? 0;

    const indexHash = Array.from(tabs).reduce(
      (hash: ArrowsIndexHashType, tab: HTMLElement, index: number) => {
        let { firstIndex, lastIndex } = hash;
        if (firstIndex === null && tab.offsetLeft >= parentScrollLeft) {
          firstIndex = index;
        }

        if (
          Math.floor(tab.offsetLeft + tab.offsetWidth) <=
          parentScrollLeft + parentClientWidth
        ) {
          lastIndex = index;
        }

        return {
          firstIndex,
          lastIndex,
        };
      },
      {
        firstIndex: null,
        lastIndex: null,
      },
    );

    setFirstVisibleIndex(indexHash.firstIndex || 0);
    setLastVisibleIndex(indexHash.lastIndex || 0);
  }, []);

  useEffect(() => {
    const parent = trackRef?.current?.getRef();
    const debouncedArrowsUpdate = debounce(onArrowsUpdate, 100);
    onArrowsUpdate();
    parent?.addEventListener('scroll', debouncedArrowsUpdate);
    window.addEventListener('resize', debouncedArrowsUpdate);
    return () => {
      parent?.removeEventListener('scroll', debouncedArrowsUpdate);
      window.removeEventListener('resize', debouncedArrowsUpdate);
    };
  }, [onArrowsUpdate]);

  const onPreviousArrowClick = useCallback(() => {
    const parent = trackRef?.current?.getRef();
    const tabs = parent?.querySelectorAll(`.${CSS.escape(tabStyles.item)}`);
    const prevIndex = Math.max(firstVisibleIndex - TABS_PER_SCROLL, 0);
    const prevTab = tabs?.[prevIndex] as HTMLElement;
    trackRef?.current?.scrollTo(prevTab?.offsetLeft);
  }, [firstVisibleIndex]);

  const onNextArrowClick = useCallback(() => {
    const parent = trackRef?.current?.getRef() as HTMLElement;
    const parentScrollLeft = parent?.scrollLeft ?? 0;
    const tabs = parent?.querySelectorAll(`.${CSS.escape(tabStyles.item)}`);
    const currentTab = tabs[lastVisibleIndex] as HTMLElement;
    const nextIndex = Math.min(
      lastVisibleIndex + TABS_PER_SCROLL,
      tabsCount - 1,
    );
    const nextTab = tabs?.[nextIndex] as HTMLElement;
    trackRef?.current?.scrollTo(
      parentScrollLeft +
        (nextTab?.offsetLeft || 0) -
        (currentTab?.offsetLeft || 0),
    );
  }, [lastVisibleIndex, tabsCount]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    const childTabsCount = React.Children.count(props.children) - 1;

    if (event.key === 'ArrowLeft') {
      const last = childTabsCount;
      const next = state.activeIndex - 1;
      handleNextTab(last, next, 0);
    }
    if (event.key === 'ArrowRight') {
      const next = state.activeIndex + 1;
      handleNextTab(0, next, childTabsCount);
    }
  };

  const renderTab = (tab: React.ReactElement, index: number) => {
    const isActive: boolean = state.activeIndex === index;

    const childProps = {
      ...tab.props,
      ariaControls: `panel-${index}`,
      id: `tab-${index}`,
      index,
      key: `tab-${index}`,
      onKeyDown: handleKeyPress,
    };

    childProps.className = classnames(tabStyles.item, {
      [tabStyles.active]: isActive,
    });
    childProps.isSelected = isActive;
    return React.cloneElement(tab, childProps);
  };

  const renderTabPanels = () => {
    const { activeIndex: currentActiveIndex } = state;

    const sections = Children.map(props.children, (tab, index) => {
      if (!tab || !isValidElement(tab) || tab.type !== Tab) {
        return null;
      }
      const isActiveTab = index === currentActiveIndex;
      const tabId = `tab-${index}`;
      const panelId = `panel-${index}`;

      const usedClassNames = classnames(
        styles.singleContent,
        tab.props.className,
        isActiveTab ? styles.activeContent : null,
      );

      return (
        <section
          aria-hidden={!isActiveTab}
          aria-labelledby={tabId}
          id={panelId}
          className={usedClassNames}
          key={tabId}
          role="tabpanel"
          tabIndex={index}
        >
          {isActiveTab && tab.props.children}
        </section>
      );
    });

    return (
      <div className={classnames(styles.content, contentClassName)}>
        {sections}
      </div>
    );
  };
  const tabContextValue = useMemo(() => ({ state, dispatch }), [state]);
  let tabIndex = -1;

  return (
    <div className={classnames(styles.tabComponent, className)} id={anchorId}>
      {firstVisibleIndex > 0 && (
        <ArrowButton onClick={onPreviousArrowClick} direction="previous" />
      )}
      <HorizontalScrolling
        className={classnames(styles.wrapper, wrapperClassName)}
        listClassName={styles.list}
        role="tablist"
        hideFadeStart
        ref={trackRef}
      >
        <TabContext.Provider value={tabContextValue}>
          {Children.map(props.children, (child) => {
            const item = child;
            if (item && isValidElement(item) && item.type === Tab) {
              tabIndex += 1;
              return renderTab(item, tabIndex);
            }
            return child;
          })}
        </TabContext.Provider>
        <div className={styles.borderHolder} />
      </HorizontalScrolling>
      {!!lastVisibleIndex && lastVisibleIndex < tabsCount - 1 && (
        <ArrowButton onClick={onNextArrowClick} direction="next" />
      )}

      {renderTabPanels()}
    </div>
  );
};
export default Tabs;
