import React from 'react';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { x, copy } from '@xxxlgroup/hydra-icons';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { BannerProps } from 'components/Banner/Banner.types';
import { useBannerState } from 'components/Banner/Banner.state';

import styles from 'components/Banner/Banner.scss';

const Banner = ({
  className,
  colorVariant,
  contentClassName,
  counter,
  i18n,
  isDismissable,
  isFullyClickable,
  link,
  linkTitle,
  onDismiss = noop,
  subTitle,
  title,
  voucher,
  ...other
}: BannerProps) => {
  const [iconCopyStyle, iconCopyClassName] = pseudoIcon(copy, 'after');
  const [iconCloseStyle, iconCloseClassName] = pseudoIcon(x);
  const { titleContent, textContent, subTitleContent, isSubLineShown } =
    useBannerState({ title, subTitle, linkTitle, link, isFullyClickable });

  return (
    <div
      className={classnames(
        styles.banner,
        [styles[colorVariant]],
        { [styles.withCountdown]: !!counter },
        className,
      )}
      {...other}
    >
      <div className={classnames(styles.contentWrapper, contentClassName)}>
        <div className={styles.content}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{titleContent}</span>
            {voucher && (
              <button
                aria-label={voucher.buttonLabel}
                data-purpose="banner.button.copy"
                onClick={voucher.onCopy}
                className={classnames(styles.voucherButton, iconCopyClassName)}
                style={iconCopyStyle}
              >
                {voucher.code}
              </button>
            )}
          </div>
          {isSubLineShown && (
            <div className={styles.subTitle}>
              {subTitleContent && <span>{subTitleContent}</span>} {textContent}
            </div>
          )}
        </div>
        {counter && <div className={styles.counter}>{counter}</div>}
      </div>
      {isDismissable && (
        <button
          aria-label={i18n?.hideBanner}
          data-purpose="banner.closeButton"
          className={classnames(styles.iconClose, iconCloseClassName)}
          style={iconCloseStyle}
          onClick={onDismiss}
        />
      )}
    </div>
  );
};

export default Banner;
