import React, { ComponentProps, ReactNode } from 'react';
import classNames from 'classnames';

import styles from 'components/DescriptionList/DescriptionList.scss';

interface DescriptionListProps extends ComponentProps<'dl'> {
  /** @ignore */
  children?: ReactNode;
  /** @ignore */
  className?: string;
}

const DescriptionList = ({
  children,
  className,
  ...other
}: DescriptionListProps) => (
  <dl className={classNames(styles.wrapper, className)} {...other}>
    {children}
  </dl>
);

export default DescriptionList;
