import React, { Children, isValidElement, ReactNode } from 'react';
import classnames from 'classnames';
import { Glyph } from 'types/typeDefinitions';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { chevronRight } from '@xxxlgroup/hydra-icons';
import styles from 'components/SimpleBreadcrumbs/SimpleBreadcrumbs.scss';

interface SimpleBreadcrumbsProps {
  children: ReactNode;
  className?: string;
  /** Show home element on left side. It is always visible on wide screens. */
  homeItem?: ReactNode;
  /** Glyph separator between breadcrumbs. Default is chevronRight. */
  separator?: Glyph;
}

const SimpleBreadcrumbs = ({
  children,
  className,
  homeItem,
  separator = chevronRight,
}: SimpleBreadcrumbsProps) => {
  const [iconSeparatorStyle, iconSeparatorClass] = pseudoIcon(separator);
  const shouldShowElipsis =
    Children.count(children) > Number(styles.maxVisibleBreadcrumbs);

  return (
    <nav
      className={classnames(styles.breadcrumbsNavigation, className)}
      aria-label="breadcrumbs"
    >
      {(homeItem || shouldShowElipsis) && (
        <div className={styles.fixedContainer}>
          {homeItem}
          {shouldShowElipsis && (
            <span
              className={classnames(styles.homeSeparator, {
                [iconSeparatorClass]: homeItem,
              })}
              style={homeItem ? iconSeparatorStyle : {}}
            >
              <span className={styles.ellipsis}>...</span>
            </span>
          )}
        </div>
      )}
      <ol className={styles.breadcrumbsList}>
        {Children.toArray(children)
          .reverse()
          .map((child, index) => {
            const shouldShowSeparator =
              index < Children.count(children) - 1 || homeItem;

            return (
              isValidElement(child) && (
                <li
                  className={classnames(styles.breadcrumbsChild, {
                    [iconSeparatorClass]: shouldShowSeparator,
                  })}
                  key={`breadcrumbsChild-${child.key}`}
                  style={shouldShowSeparator ? iconSeparatorStyle : {}}
                >
                  {child}
                </li>
              )
            );
          })}
        <li
          className={classnames(styles.breadcrumbsChild, styles.homeChild)}
          key="breadcrumbsChild-home"
        >
          {homeItem}
        </li>
      </ol>
    </nav>
  );
};

export default SimpleBreadcrumbs;
