import React, { FC, useCallback, useEffect, useState, useId } from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import ExpanderButton from 'components/Expander/components/ExpanderButton/ExpanderButton';
import ListVariant from 'components/Expander/components/ListVariant/ListVariant';
import TextVariant from 'components/Expander/components/TextVariant/TextVariant';
import { ExpanderProps, getTextSize } from 'components/Expander/Expander.types';

const resizeObserverSupported =
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  typeof window !== 'undefined' && !!window.ResizeObserver;

const calculateInitialHeight = (
  ref: HTMLDivElement | null,
  hasOverflow: boolean,
  lineHeight: string | number,
) => {
  if (resizeObserverSupported && ref && !hasOverflow) {
    return ref.offsetHeight === 0 ? lineHeight : 'auto';
  }
  return lineHeight;
};

const Expander: FC<ExpanderProps> = ({
  children,
  className,
  i18n = { showMore: 'show more', showLess: 'show less' },
  defaultExpanded = false,
  onExpand = noop,
  variant,
  contentHeight = 'md',
  listType = 'ul',
  theme = 'primary',
}) => {
  const instanceVars = React.useRef<{
    expanderRef: HTMLDivElement | null;
    resizeObserver: ResizeObserver | null;
  }>({
    expanderRef: null,
    resizeObserver: null,
  });

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [ellipsisClass, setEllipsisClass] = useState(!defaultExpanded);
  const [expanderButtonVisible, setExpanderButtonVisible] = useState(true);
  const [textLineHeight, setTextLineHeight] = useState<string>('0');

  const getContentHeight = useCallback(
    () => getTextSize(contentHeight),
    [contentHeight],
  );

  const getCalculatedLineHeight = useCallback(() => {
    if (!instanceVars?.current.expanderRef) {
      return 0;
    }
    return getContentHeight() * parseInt(textLineHeight, 10);
  }, [textLineHeight, getContentHeight]);

  const getExpanderHeight = () =>
    instanceVars.current.expanderRef?.getBoundingClientRect().height;

  const hasOverflow = useCallback(() => {
    const { expanderRef } = instanceVars.current;
    const scrollHeight = expanderRef?.scrollHeight ?? 0;
    const offsetHeight = expanderRef?.offsetHeight ?? 0;
    const expanderHeight = getExpanderHeight() ?? 0;

    return (
      scrollHeight > offsetHeight || expanderHeight > getCalculatedLineHeight()
    );
  }, [getCalculatedLineHeight]);

  const setShouldRenderExpanderButton = useCallback(() => {
    setExpanderButtonVisible(getContentHeight() === 0 || hasOverflow());
  }, [getContentHeight, hasOverflow]);

  useEffect(() => {
    const { current } = instanceVars;
    const { expanderRef } = current;

    if (expanderRef && resizeObserverSupported) {
      expanderRef.addEventListener(
        'animationend',
        setShouldRenderExpanderButton,
      );
      const resizeObserverCallback = () => setShouldRenderExpanderButton();
      current.resizeObserver = new ResizeObserver(resizeObserverCallback);
    }

    return () => {
      current?.resizeObserver?.disconnect();
      expanderRef?.removeEventListener(
        'animationend',
        setShouldRenderExpanderButton,
      );
    };
  }, [setShouldRenderExpanderButton]);

  useEffect(() => {
    const { current } = instanceVars;
    const { resizeObserver, expanderRef } = current;

    if (resizeObserver && expanderRef) {
      resizeObserver.observe(expanderRef);
    }

    if (
      variant === 'list' &&
      getTextSize(contentHeight) >= React.Children.toArray(children).length
    ) {
      setExpanderButtonVisible(false);
    }
  }, [variant, contentHeight, children]);

  const onParagraphRefChange = useCallback(
    (paragraphNode: HTMLDivElement | null) => {
      if (paragraphNode) {
        setTextLineHeight(getComputedStyle(paragraphNode).lineHeight);
        instanceVars.current.expanderRef = paragraphNode;
      }
    },
    [],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      setIsExpanded((prevState) => {
        onExpand?.(event, !prevState);
        return !prevState;
      });
    },
    [onExpand, setIsExpanded],
  );

  const sliceChildren = (alwaysVisibleText = true) => {
    const cutValues = alwaysVisibleText
      ? [0, getContentHeight()]
      : [getContentHeight()];
    return React.Children.toArray(children).slice(...cutValues);
  };

  const animationEnded = useCallback(() => {
    setEllipsisClass(!isExpanded);
  }, [isExpanded]);

  const initialHeight = getCalculatedLineHeight();

  const calculatedHeight = resizeObserverSupported
    ? calculateInitialHeight(
        instanceVars?.current.expanderRef,
        hasOverflow(),
        initialHeight,
      )
    : 'auto';

  const id = useId();

  const expanderButton = (
    <ExpanderButton
      handleClick={handleClick}
      idToControl={id}
      isExpanded={isExpanded}
      i18n={i18n}
      theme={theme}
      visible={expanderButtonVisible}
    />
  );

  if (variant === 'list') {
    return (
      <ListVariant
        id={id}
        className={className}
        contentHeight={getContentHeight()}
        isExpanded={isExpanded}
        listType={listType}
        sliceChildren={sliceChildren}
        expanderButton={expanderButton}
      />
    );
  }

  return (
    <TextVariant
      id={id}
      animationEnded={animationEnded}
      className={className}
      content={children}
      contentHeight={contentHeight}
      ellipsisClass={ellipsisClass}
      initialHeight={calculatedHeight}
      isExpanded={isExpanded}
      ref={onParagraphRefChange}
      expanderButton={expanderButton}
    />
  );
};

export default Expander;
