import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { EyecatcherType } from 'types/typeDefinitions';

import styles from 'components/Eyecatcher/Eyecatcher.scss';

type ContentType = [ReactNode] | ReactNode;

interface EyecatcherProps {
  className?: string;
  /** Content to be shown inside the eyecatcher */
  content?: ContentType;
  /** Defines the layout */
  type: EyecatcherType;
}

const Eyecatcher = ({
  className = '',
  content = null,
  type,
  ...other
}: EyecatcherProps) => {
  if (type && styles[type]) {
    return (
      <span
        className={classnames(styles.type, styles[type], className)}
        {...other}
      >
        {content}
      </span>
    );
  }

  return null;
};

export default Eyecatcher;
