import React from 'react';
import classNames from 'classnames';
import Snackbar, { SnackbarDefinition } from 'components/Snackbar';
import Notification from 'components/Notifications/components/Notification';
import { NotificationsContext } from 'components/Notifications/Notifications.state';
import {
  NotificationsProps,
  NotificationsVariants,
  NotificationsOptions,
} from 'components/Notifications/Notifications.types';

import styles from 'components/Notifications/Notifications.scss';

const ids: NotificationsVariants[] = [
  'success',
  'warning',
  'error',
  'loud',
  'normal',
  'mute',
  'promo',
];

const notificationDefinitions: SnackbarDefinition<
  NotificationsVariants,
  NotificationsOptions
>[] = ids.map((id) => ({
  id,
  component: Notification,
  removeDelay: 300,
  defaultDuration: 6000,
  defaultLayer: 'modal',
}));

const Notifications = ({
  className,
  children,
  component,
  definitions,
}: NotificationsProps<NotificationsVariants>) => (
  <Snackbar
    className={classNames(styles.notifications, className)}
    definitions={[...notificationDefinitions, ...(definitions || [])]}
    component={component}
  >
    {(snackbarApi) => (
      <NotificationsContext.Provider value={snackbarApi}>
        {typeof children === 'function' ? children(snackbarApi) : children}
      </NotificationsContext.Provider>
    )}
  </Snackbar>
);

export default Notifications;
