import React, { useMemo } from 'react';
import classnames from 'classnames';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import Content from 'components/Content';
import { ListContext } from 'components/List/context';

import styles from 'components/List/List.scss';
import {
  BulletPoints,
  BulletPointClassNames,
  ListTypeBulletPoints,
  ListProps,
  ListType,
} from 'components/List/List.types';

const listTypeBulletPoints: ListTypeBulletPoints = {
  ordered: ['decimal-quiet', 'decimal-loud'],
  unordered: ['bullet', 'check', 'plus', 'minus'],
};

const getListType = (bulletPoints: BulletPoints) => {
  const listTypes = Object.keys(listTypeBulletPoints) as ListType[];
  const listType = listTypes.find((type) =>
    listTypeBulletPoints[type].includes(bulletPoints),
  );
  if (!listType) {
    throw new Error(`unknown bulletPoint type "${bulletPoints}"`);
  }
  return listType;
};

const bulletPointClassNames: BulletPointClassNames = {
  'decimal-quiet': '',
  'decimal-loud': 'loud',
  bullet: '',
  check: 'checkmarks',
  plus: 'plus',
  minus: 'minus',
};

const renderDeprecatedItem = (item: any, index: number) => {
  let content = item;
  let className = null;

  if (typeof item === 'object') {
    ({ content, className } = item);
  }

  return (
    <Content
      tag="li"
      content={content}
      className={className}
      // eslint-disable-next-line react/no-array-index-key
      key={`listChild-${index}`}
    />
  );
};

const List: React.FC<ListProps> = ({
  bulletPoints,
  children,
  className,
  divider = false,
  size = 'small',
  type = 'unordered',
  items = [],
  ...other
}) => {
  const listType = bulletPoints ? getListType(bulletPoints) : type;
  const bulletPointsType = bulletPoints || listTypeBulletPoints[listType][0];

  const bulletPointClassName = bulletPointClassNames[
    bulletPointsType
  ] as BulletPoints;

  const validatedChildren = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );
  const isMultilineList = !!validatedChildren.find((child) =>
    React.isValidElement(child) ? !!child.props?.description : false,
  );

  const rootClassName = classnames(styles.list, className);
  const listClassName = classnames(bulletPointClassName, size, {
    divider,
    multiline: isMultilineList,
  });
  const contextMemo = useMemo(
    () => ({
      bulletPoints: bulletPointClassName,
      hasDivider: divider,
      isMultilineList,
      size,
    }),
    [bulletPointClassName, divider, isMultilineList, size],
  );
  const ListElement = listType === 'unordered' ? 'ul' : 'ol';
  const useItemsFallback = !isArrayEmpty(items);

  return (
    <ListContext.Provider value={contextMemo}>
      <div className={rootClassName}>
        <ListElement className={listClassName} {...other}>
          {useItemsFallback
            ? items.map(renderDeprecatedItem)
            : validatedChildren.map((child, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`listChild-${index}`}>{child}</li>
              ))}
        </ListElement>
      </div>
    </ListContext.Provider>
  );
};

export default List;
