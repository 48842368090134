import React, { FC } from 'react';
import styles from 'components/ProgressStepper/components/ProgressStepperHorizontal/components/HorizontalStepContent/HorizontalStepContent.scss';
import Content from 'components/Content';
import classNames from 'classnames';
import { ProgressStepperStatusType } from 'components/ProgressStepper/ProgressStepper.types';

interface HorizontalStepContentProps {
  /** @ignore */
  children?: React.ReactNode | null;
  /** @ignore */
  className?: string | null;
  /** Current status of content */
  status: ProgressStepperStatusType;
  /** Sub Title of Progress Step */
  subTitle?: React.ReactNode | null;
  /** Title of Progress Step */
  title?: React.ReactNode;
}

const HorizontalStepContent: FC<HorizontalStepContentProps> = ({
  className,
  children = null,
  status,
  title,
  subTitle,
  ...others
}) => (
  <div
    className={classNames(
      className,
      styles.stepContent,
      styles[`status-${status}`],
    )}
    {...others}
  >
    {title && <Content content={title} className={styles.title} />}
    {subTitle && <Content content={subTitle} className={styles.subTitle} />}
    {children}
  </div>
);

export default React.memo(HorizontalStepContent);
