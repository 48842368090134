import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import classnames from 'classnames';
import { Glyph } from 'types/typeDefinitions';
import { chevronRight, arrowLeft, ellipsis } from '@xxxlgroup/hydra-icons';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import createIntersectionObserver from '@xxxlgroup/hydra-utils/observers';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

import styles from 'components/Breadcrumbs/Breadcrumbs.scss';

interface BreadcrumbsProps {
  className?: string;
  children?: React.ReactNode;
  isMobileScroll?: boolean;
  separator?: Glyph;
}

const [iconEllipsisStyle, iconEllipsisClassName] = pseudoIcon(
  ellipsis,
  'after',
);
const [iconArrowLeftStyle, iconArrowLeftClassName] = pseudoIcon(arrowLeft);

const useBreadcrumbsDropdown = () => {
  const [dropdownElementsIndexes, setDropdownElementsIndexes] = useState<
    Array<number>
  >([]);
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);
  const breadcrumbsRootRef = useRef<HTMLOListElement | null>(null);

  const getRootElementRef = useCallback((ref: HTMLOListElement | null) => {
    if (!ref) {
      return intersectionObserverRef.current?.disconnect();
    }

    const indexes = new Set<number>();
    const intersectionObserverCallback = (
      entries: Array<IntersectionObserverEntry>,
    ) => {
      const childrenArray = Array.from(ref.children);

      entries.forEach(({ target, intersectionRatio }) => {
        const targetPosition = childrenArray.indexOf(target);

        // only consider targets which are still in the DOM
        // because of IntersectionObserver batching behavior it can happen that items aren't in the DOM at this time)
        /* istanbul ignore else */
        if (target.parentElement) {
          intersectionRatio === 0
            ? indexes.add(targetPosition)
            : indexes.delete(targetPosition);
        }
      });
      const newIndexes = Array.from(indexes);

      setDropdownElementsIndexes((oldIndexes) =>
        oldIndexes.length !== newIndexes.length ||
        newIndexes.some((index, i) => index !== oldIndexes[i])
          ? newIndexes
          : oldIndexes,
      );
    };
    const observer = createIntersectionObserver(intersectionObserverCallback, {
      root: ref,
      threshold: 1,
    });

    intersectionObserverRef.current = observer;
    breadcrumbsRootRef.current = ref;
    return null;
  }, []);

  // after each re-render subscribe the (possibly) new elements to the intersection observer
  // intersection observer ignores already subscribed elements
  useEffect(() => {
    const observer = intersectionObserverRef?.current;
    const breadcrumbsList = breadcrumbsRootRef?.current;

    // only access refs if they are available
    /* istanbul ignore else */
    if (observer && breadcrumbsList) {
      Array.from(breadcrumbsList.children).forEach((listElement) => {
        observer.observe(listElement);
      });
    }
  }, []);

  return {
    getRootElementRef,
    dropdownElementsIndexes,
  };
};

const renderDropdown = (
  children: Array<React.ReactNode>,
  childrenIndexes: Array<number>,
) => (
  <div
    className={classnames(styles.dropdownButton, iconEllipsisClassName)}
    style={iconEllipsisStyle}
    aria-haspopup="true"
    role="button"
    data-testid="dropdownButton"
    aria-label="breadcrumbs dropdown"
    tabIndex={0}
  >
    <ol
      className={styles.dropdownContent}
      data-testid="dropdownContent"
      tabIndex={-1}
    >
      {children.map((child, index) => {
        if (childrenIndexes.includes(index)) {
          return (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`breadcrumbsDropdownChild-${index}`}
              className={styles.dropdownContentChild}
            >
              {child}
            </li>
          );
        }
        return null;
      })}
    </ol>
  </div>
);

const Breadcrumbs = ({
  className,
  children,
  isMobileScroll = false,
  separator = chevronRight,
  ...other
}: BreadcrumbsProps) => {
  const validatedChildren = useMemo(
    () =>
      React.Children.toArray(children).filter((child) =>
        React.isValidElement(child),
      ),
    [children],
  );

  const [iconStyle, iconClassName] = pseudoIcon(separator, 'after');

  const { getRootElementRef, dropdownElementsIndexes } =
    useBreadcrumbsDropdown();

  const renderedChildren = validatedChildren.map((child, index) => {
    const childrenLength = validatedChildren.length;
    const isLast = index === childrenLength - 1;
    const isLastMobileItem = isLast && !isMobileScroll;
    const elementIsInDropdown = dropdownElementsIndexes.includes(index);
    return (
      <li
        // eslint-disable-next-line react/no-array-index-key
        key={`breadcrumbsChild-${index}`}
        style={{
          order: childrenLength - index,
          visibility: elementIsInDropdown ? 'hidden' : undefined,
        }}
      >
        <div
          className={classnames(
            styles.breadcrumbsChild,
            isLastMobileItem && iconArrowLeftClassName,
            iconClassName,
          )}
          style={{ ...iconArrowLeftStyle, ...iconStyle }}
        >
          {child}
        </div>
      </li>
    );
  });

  return (
    <nav
      className={classnames(className, styles.breadcrumbs, {
        [styles.mobileScrollable]: isMobileScroll,
        [styles.breadcrumbsWithDropdown]: !isArrayEmpty(
          dropdownElementsIndexes,
        ),
      })}
      data-testid="breadcrumbs"
      aria-label="breadcrumbs"
      {...other}
    >
      <div
        className={classnames(styles.dropdown, iconClassName)}
        style={iconStyle}
      >
        {renderDropdown(validatedChildren, dropdownElementsIndexes)}
      </div>
      <ol
        className={styles.breadcrumbsList}
        ref={getRootElementRef}
        tabIndex={-1}
      >
        {renderedChildren}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
