import React from 'react';

const useCombinedRefs = (
  innerRef: React.MutableRefObject<HTMLDivElement | null>,
  fwdRef: React.ForwardedRef<HTMLDivElement>,
) => {
  React.useEffect(() => {
    [innerRef, fwdRef].forEach((ref) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(innerRef.current || null);
        } else {
          // eslint-disable-next-line no-param-reassign
          ref.current = innerRef.current || null;
        }
      }
    });
  }, [innerRef, fwdRef]);
  return innerRef;
};

export default useCombinedRefs;
