import React, { useContext, FC } from 'react';
import VerticalStepContext from 'components/ProgressStepper/components/ProgressStepperVertical/components/VerticalStep/VerticalStep.state';
import {
  ProgressStepperStatusType,
  STEP_SCROLL_ANIM_DURATION,
} from 'components/ProgressStepper/ProgressStepper.types';

import styles from 'components/ProgressStepper/components/ProgressStep/components/StepContent/StepContent.scss';
import AnimateHeight from 'components/AnimateHeight';

export interface StepContentProps {
  /** @ignore */
  children?: React.ReactNode;
  /** At which status the content shall be shown */
  status: ProgressStepperStatusType;
}

const StepContent: FC<StepContentProps> = ({ children, status }) => {
  const { status: contextStatus } = useContext(VerticalStepContext);

  return (
    <AnimateHeight
      animateOpacity
      duration={STEP_SCROLL_ANIM_DURATION}
      height={status === contextStatus ? 'auto' : 0}
      className={styles.animateWrapper}
    >
      {children}
    </AnimateHeight>
  );
};

export default StepContent;
