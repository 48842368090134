import React, {
  forwardRef,
  useRef,
  useCallback,
  useEffect,
  useState,
  ReactNode,
  ComponentProps,
} from 'react';
import classname from 'classnames';
import { error, check, info, warning, x } from '@xxxlgroup/hydra-icons';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';

import IconButton from 'components/IconButton';
import { Glyph } from 'types/typeDefinitions';

import styles from 'components/FeedbackCard/FeedbackCard.scss';

type Layout = 'inline' | 'block';
type Variant =
  | 'error'
  | 'warning'
  | 'success'
  | 'loud'
  | 'normal'
  | 'quiet'
  | 'mute'
  | 'promo';
type TimerDuration = 'instant' | 'quick' | 'moderate' | 'relaxed';

interface FeedbackCardProps extends ComponentProps<'div'> {
  /** @ignore */
  children?: ReactNode;
  /** @ignore */
  className?: string;
  /** Allows to dismiss the FeedbackCard */
  dismissible?: boolean;
  /** Whether the FeedbackCard should have a animated FadeIn. */
  fadeIn?: boolean;
  /** Whether the FeedbackCard should have an animated FadeOut. */
  fadeOut?: boolean;
  /** Custom Icon for the FeedbackCard. Is only applied if variant is 'loud', 'normal' or 'quiet' and ignored otherwise.  */
  glyph?: Glyph;
  /** Determines when the FeedbackCard disappears if timer is true */
  timerDuration?: TimerDuration;
  /** Determines how the FeedbackCard will use horizontal space */
  layout?: Layout;
  /** Callback which gets called when the feedback is dismissed, return true to prevent default closing behavior */
  onDismiss?: (
    event: React.AnimationEvent<HTMLDivElement> | React.MouseEvent,
  ) => void | boolean;
  /** FeedbackCard disappears after a default of 6s */
  timer?: boolean;
  /** sets the title text */
  title?: string;
  /** Defines the layout */
  variant?: Variant;
}

const glyphMap: { [key in Variant]: Glyph | null } = {
  error,
  warning,
  success: check,
  loud: info,
  normal: info,
  quiet: info,
  mute: null,
  promo: null,
};

const timeoutMap: { [span in TimerDuration]: number } = {
  instant: 2000,
  quick: 4000,
  moderate: 6000,
  relaxed: 8000,
};

const customGlyphVariantWhitelist: Variant[] = ['loud', 'normal', 'quiet'];

const getGlyph = (variant: Variant, glyph?: Glyph): Glyph | undefined => {
  const variantGlyph = glyphMap[variant];
  const customGlyph = customGlyphVariantWhitelist.includes(variant) && glyph;

  return customGlyph || variantGlyph || undefined;
};

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const FeedbackCard = forwardRef<HTMLDivElement, FeedbackCardProps>(
  (
    {
      children,
      className,
      dismissible = false,
      fadeIn = false,
      fadeOut = true,
      glyph,
      layout = 'block',
      onDismiss: externalOnDismiss,
      timer = false,
      timerDuration = 'moderate',
      title,
      variant = 'normal',
      ...other
    },
    ref,
  ) => {
    const timeout = timeoutMap[timerDuration];
    const finalGlyph = getGlyph(variant, glyph);
    const [visible, setVisible] = useState(true);
    const [iconStyle, iconClassName] = pseudoIcon(finalGlyph);
    const [shouldFadeOut, setShouldFadeOut] = useState(false);
    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const {
      onMouseEnter: originalOnMouseEnter,
      onMouseLeave: originalOnMouseLeave,
      onAnimationEnd: originalOnAnimationEnd,
      onFocus: orgininalOnFocus,
      onBlur: originalOnBlur,
    } = other;

    const classnames = classname(
      className,
      styles.feedbackCard,
      styles[variant],
      styles[layout],
      {
        [styles.fadeIn]: fadeIn,
        [styles.fadeOut]: shouldFadeOut,
      },
    );

    const onDismiss = (
      event: React.AnimationEvent<HTMLDivElement> | React.MouseEvent,
    ) => {
      if (!externalOnDismiss?.(event)) {
        setVisible(false);
      }
    };

    const clearTimer = useCallback(() => {
      if (timer && timerRef.current) {
        clearTimeout(timerRef.current!);
      }
    }, [timer]);

    const handleTimer = useCallback(() => {
      clearTimer();
      if (timer) {
        timerRef.current = setTimeout(() => setShouldFadeOut(true), timeout);
      }
    }, [clearTimer, timeout, timer]);

    const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
      originalOnMouseEnter?.(event);
      clearTimer();
    };

    const onFocus = (event: React.FocusEvent<HTMLDivElement>) => {
      orgininalOnFocus?.(event);
      clearTimer();
    };

    const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
      originalOnBlur?.(event);
      handleTimer();
    };

    const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
      originalOnMouseLeave?.(event);
      handleTimer();
    };

    const onAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
      originalOnAnimationEnd?.(event);
      if (shouldFadeOut) {
        onDismiss(event);
      }
    };

    const iconButtonOnClick = (event: React.MouseEvent) => {
      if (fadeOut) {
        setShouldFadeOut(true);
      } else {
        onDismiss(event);
      }
    };

    useEffect(handleTimer, [handleTimer]);
    if (visible) {
      return (
        <div
          data-testid="feedback.card"
          className={classnames}
          {...other}
          onMouseEnter={onMouseEnter}
          onFocus={onFocus}
          onBlur={onBlur}
          onMouseLeave={onMouseLeave}
          onAnimationEnd={onAnimationEnd}
          ref={ref}
        >
          <div
            className={classname(styles.wrapper, iconClassName)}
            style={iconStyle}
          >
            <div className={styles.container}>
              {title && <p className={styles.headline}>{title}</p>}
              <div role="alert" className={styles.content}>
                {children}
              </div>
            </div>
          </div>
          {dismissible && (
            <IconButton
              className={styles.dismissButton}
              glyph={x}
              data-purpose="dismiss.feedbackcard"
              onClick={iconButtonOnClick}
            />
          )}
        </div>
      );
    }

    return null;
  },
);

export default FeedbackCard;
