import React from 'react';
import { ErrorsType } from 'components/Input/Input.types';
import InputIcon from 'components/Input/components/InputIcon';
import { callsign, check } from '@xxxlgroup/hydra-icons';
import styles from 'components/Input/components/StateIcons/StateIcons.scss';

interface StateIconsProps {
  /** Give an error string or errors string array to display near the field */
  errors?: ErrorsType;
  /** A success-icon will be displayed */
  success: boolean;
}

const StateIcons = ({ errors = null, success }: StateIconsProps) => {
  const glyph = success && !errors ? check : callsign;
  const attributes = {
    glyph,
  };
  if (success) {
    return (
      <InputIcon
        data-testid="success.icon"
        className={styles.successIcon}
        {...attributes}
      />
    );
  }

  if (errors) {
    return (
      <InputIcon
        className={styles.errorIcon}
        data-purpose="input.errorIcon"
        {...attributes}
      />
    );
  }

  return null;
};

export default StateIcons;
