import React from 'react';
import Image from 'components/Image';
import { Image as ImageType } from 'types/typeDefinitions';
import styles from 'components/AnnouncementCard/AnnouncementCard.scss';

interface AnnouncementCardType {
  /** Contains announcement date */
  date?: string;
  /** Announcement title */
  title?: string;
  /** Announcement description */
  description?: string | React.ReactNode;
  /** Announcement image */
  media: string | ImageType;
  /** Announcement additional content */
  children?: React.ReactNode;
}

const AnnouncementCard = ({
  children,
  date,
  description,
  media,
  title,
}: AnnouncementCardType) => (
  <div className={styles.card}>
    <div className={styles.content}>
      <Image source={media as ImageType} className={styles.image} />
      <div className={styles.inner}>
        {date && <div className={styles.date}>{date}</div>}
        {title && <div className={styles.title}>{title}</div>}
        {description && <p className={styles.description}>{description}</p>}
        {children}
      </div>
    </div>
  </div>
);

AnnouncementCard.defaultProps = {
  date: null,
  title: null,
  description: null,
};

export default AnnouncementCard;
