import React, { FunctionComponent, useCallback, useState } from 'react';
import MultiRange from 'components/RangeSlider/components/MultiRange';
import { RangeSliderVariantProps } from 'components/RangeSlider/RangeSlider.types';
import { Range } from 'components/RangeSlider/components/MultiRange/MultiRange.types';
import styles from 'components/RangeSlider/components/ReducedVariant/ReducedVariant.scss';
import { noop } from '@xxxlgroup/hydra-utils/common';

const SingleRangeSliderVariant: FunctionComponent<RangeSliderVariantProps> = ({
  i18n,
  minValue,
  maxValue,
  maxLimit,
  minLimit,
  dispatchChange = noop,
  onChange = noop,
  disabled,
}) => {
  const [values, setValues] = useState<Range>([minValue, maxValue]);

  const handleDragEnd = useCallback(
    (sliderValues: Range) => {
      dispatchChange(sliderValues);
      onChange(sliderValues);
    },
    [dispatchChange, onChange],
  );

  return (
    <div className={styles.reduced}>
      <div className={styles.reducedFrom}>
        {i18n.unit} {values[0]}
      </div>
      <MultiRange
        maxLimit={maxLimit}
        maxValue={values[1]}
        minLimit={minLimit}
        minValue={values[0]}
        onDrag={setValues}
        onDragEnd={handleDragEnd}
        ariaLabel={i18n}
        legend={i18n.label}
        disabled={disabled}
      />
      <div className={styles.reducedTo}>
        {i18n.unit} {values[1]}
      </div>
    </div>
  );
};

export default SingleRangeSliderVariant;
