import React from 'react';
import { i18nType } from 'components/Input/Input.types';
import InputIcon from 'components/Input/components/InputIcon';
import { x } from '@xxxlgroup/hydra-icons';

import styles from 'components/Input/components/CancelButton/CancelButton.scss';

interface CancelButtonProps {
  /** Event handler to clear field and reset settings */
  clearValue: (isTriggeredByKeyDown?: boolean) => void;
  /** Translations for the meaning of the asterisk and aria-label's and title's for icons and iconButtons */
  i18n: i18nType;
}

const CancelButton = ({ clearValue, i18n }: CancelButtonProps) => {
  const handleKeydown = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (event.key === 'Enter' || event.key === ' ') clearValue(true);
  };

  const attributes = {
    glyph: x,
    ariaLabel: i18n.cancel,
    role: 'button',
    // onClick gets fired after onBlur, onBlur makes the Icon disappear and onClick gets canceled
    onMouseDown: clearValue,
    onKeyDown: handleKeydown,
    'data-testid': 'cancel.button',
  };

  return <InputIcon className={styles.clearIcon} {...attributes} />;
};

export default CancelButton;
