import React from 'react';
import getStatus from 'components/ProgressStepper/utils/getStatus';

/**
 * Takes the ProgressStep children and extracts the props,
 * adds the properties stepNumber, stepIndex, onClick, disableClick and status
 */

export const prepareStepsProps = (
  children: React.ReactNode,
  handleOnStepChange: (index: number) => () => void,
  current: number,
  isClickOnFinishedDisabled?: boolean,
  isClickOnInactiveDisabled?: boolean,
  currentInteraction?: number,
) =>
  React.Children.toArray(children)
    .filter((step) => !!step)
    .map((step, index) => {
      const stepIconProps = React.isValidElement(step)
        ? {
            ...step.props,
            stepNumber: `${index + 1}`,
            stepIndex: index,
            onClick: handleOnStepChange(index),
          }
        : {
            stepNumber: `${index + 1}`,
            stepIndex: index,
            onClick: handleOnStepChange(index),
          };

      if (!stepIconProps.status) {
        stepIconProps.status = getStatus(index, current);
      }

      if (
        (stepIconProps.status === 'finished' && isClickOnFinishedDisabled) ||
        stepIconProps.disableClick
      ) {
        stepIconProps.isClickDisabled = true;
      }
      if (
        (stepIconProps.status === 'inactive' && isClickOnInactiveDisabled) ||
        stepIconProps.disableClick
      ) {
        stepIconProps.isClickDisabled = true;
      }

      if (currentInteraction !== undefined) {
        stepIconProps.currentInteractionStatus = getStatus(
          index,
          currentInteraction,
        );
      }

      return stepIconProps;
    });
