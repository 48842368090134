import React, { useMemo, useId } from 'react';
import { RatingIconProps } from 'components/Rating/Rating.types';

import styles from 'components/Rating/components/SingleRating/components/RatingIcon/RatingIcon.scss';

const SIZES = {
  xxsmall: 12,
  xsmall: 16,
  small: 24,
  medium: 32,
  large: 48,
};

const RatingIcon = (props: RatingIconProps) => {
  const { size = 'xsmall', percentage } = props;

  const id = useId();
  const partiallyFilled = percentage < 1;
  const identifier = useMemo(
    () => (partiallyFilled && id) || undefined,
    [id, partiallyFilled],
  );

  const starPath = () => (
    <>
      <path
        className={!partiallyFilled ? styles.fill : undefined}
        data-rating-icon={true} // eslint-disable-line
        fill={(partiallyFilled && `url(#${identifier})`) || '#fff'}
        d="M6.16 23.25a2.32 2.32 0 01-1.33-.43 2.26 2.26 0 01-.9-2.21l.89-5.21a.23.23 0 00-.07-.22L1 11.49a2.26 2.26 0 011.22-3.85l5.23-.76a.25.25 0 00.19-.14L10 2a2.27 2.27 0 014 0l2.33 4.73a.25.25 0 00.19.14l5.23.76A2.26 2.26 0 0123 11.49l-3.78 3.69a.23.23 0 00-.07.22l.89 5.21A2.26 2.26 0 0116.79 23l-4.67-2.46a.25.25 0 00-.24 0L7.21 23a2.33 2.33 0 01-1.05.25z"
      />
      <path
        d="M6.16 23.25a2.32 2.32 0 01-1.33-.43 2.26 2.26 0 01-.9-2.21l.89-5.21a.23.23 0 00-.07-.22L1 11.49a2.23 2.23 0 01-.6-2.31 2.25 2.25 0 011.82-1.54l5.23-.76a.26.26 0 00.19-.13L10 2a2.27 2.27 0 014 0l2.33 4.74a.26.26 0 00.19.13l5.23.76a2.25 2.25 0 011.885 2.792A2.23 2.23 0 0123 11.49l-3.78 3.69a.23.23 0 00-.07.22l.89 5.21A2.26 2.26 0 0116.79 23l-4.67-2.46a.25.25 0 00-.24 0L7.21 23a2.33 2.33 0 01-1.05.25zm5.84-21a.74.74 0 00-.68.42L9 7.41a1.74 1.74 0 01-1.31 1l-5.23.76a.73.73 0 00-.61.51.76.76 0 00.19.78l3.76 3.65a1.75 1.75 0 01.5 1.55l-.89 5.2a.76.76 0 001.1.8l4.68-2.46a1.78 1.78 0 011.62 0l4.68 2.46a.76.76 0 001.1-.8l-.89-5.2a1.75 1.75 0 01.5-1.55l3.8-3.69a.76.76 0 00.19-.78.73.73 0 00-.61-.51l-5.22-.76a1.74 1.74 0 01-1.32-1l-2.36-4.7a.74.74 0 00-.68-.42z"
        opacity=".08"
      />
    </>
  );

  return (
    <svg viewBox="0 0 24 24" height={`${SIZES[size]}px`}>
      {partiallyFilled && (
        <linearGradient id={identifier} x1="0" y1="1" x2="1" y2="1">
          <stop
            className={styles.stopColor}
            offset={`${percentage * 100}%`}
            stopOpacity="1"
          />
          <stop className={styles.stopColor} offset="0%" stopOpacity="0" />
        </linearGradient>
      )}
      {starPath()}
    </svg>
  );
};

export default RatingIcon;
