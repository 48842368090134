import React from 'react';
import styles from 'components/Select/components/SelectLabel.scss';
import Label from 'components/Label';

export interface SelectLabelProps {
  hidden?: boolean;
  label: string;
  /** The symbol(text) after placeholder and label if the field is optional (not mandatory) */
  optionalSymbol?: string;
  /** The associated field is mandatory and must be filled. *Notice:* An asterisk is automatically appended to the label */
  required?: boolean;
  /** The required symbol(text) after placeholder if the field is mandatory */
  requiredSymbol?: string;
  disabled?: boolean;
  value: string | null;
  id: string;
}

const SelectLabel = ({
  hidden = false,
  label,
  optionalSymbol,
  required,
  requiredSymbol,
  disabled = false,
  value,
  id,
}: SelectLabelProps) => {
  const labelAttributes = {
    className: styles.label,
    content: label,
    hidden,
    optionalSymbol,
    required,
    requiredSymbol,
    visible: !disabled && !!value,
    'data-testid': id,
  };

  return <Label id={id} i18n={{ mandatory: '' }} {...labelAttributes} />;
};

export default SelectLabel;
