import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';
import Input from 'components/Input';
import { caretDown, search } from '@xxxlgroup/hydra-icons';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { noop } from '@xxxlgroup/hydra-utils/common';
import {
  Alignment,
  ALIGNMENT_LEFT,
  ALIGNMENT_RIGHT,
  SelectEventType,
} from 'components/Select/Select.types';

import styles from 'components/Select/Select.scss';

type Direction = 'up' | 'down';
const UP: Direction = 'up';
const DOWN: Direction = 'down';

interface SelectFlyoutProps {
  initialValue: string | null;
  selectAlignment: Alignment;
  seamless: boolean;
  options: Record<string, string>[];
  keyValue: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onChange?: (event?: SelectEventType, value?: string | null) => void;
  setFilterText?: Dispatch<SetStateAction<string | undefined>>;
  dispatchChange?: (event?: SelectEventType, open?: boolean) => void;
}

const calculateAlignmentAndDirection = (
  alignment: Alignment,
  selectAlignment: Alignment,
  seamless: boolean,
  optionsLength: number,
  selectBounds: DOMRect,
  flyOutBounds: DOMRect,
): [Alignment, Direction] => {
  let newAlignment: Alignment = alignment;
  const distanceToBottom = seamless
    ? window.innerHeight - (flyOutBounds.top + flyOutBounds.height)
    : window.innerHeight -
      (selectBounds.top + selectBounds.height + flyOutBounds.height);
  const distanceToTop = seamless
    ? selectBounds.top + selectBounds.height - flyOutBounds.height
    : selectBounds.top - flyOutBounds.height;

  const hasOnlyTopSpace =
    distanceToBottom < 0 && distanceToTop > 0 && optionsLength < 20;
  const dir: Direction = hasOnlyTopSpace ? UP : DOWN;

  if (seamless) {
    const distanceToRight =
      window.innerWidth - (flyOutBounds.left + flyOutBounds.width);
    const distanceToLeft =
      selectBounds.left + selectBounds.width - flyOutBounds.width;

    if (
      selectAlignment === ALIGNMENT_RIGHT &&
      distanceToRight < 0 &&
      distanceToLeft > 0
    ) {
      newAlignment = ALIGNMENT_LEFT;
    } else if (selectAlignment === ALIGNMENT_LEFT && distanceToLeft < 0) {
      // important: moving element to left outside viewport would not render a horizontal scrolling of the window.
      // In this case I would not be able to scroll the content of the flyout into view.
      newAlignment = ALIGNMENT_RIGHT;
    }
  }
  return [newAlignment, dir];
};

const isCurrentValueSelectable = (
  currentOptions: Record<string, string>[],
  keyValue: string,
  value: string | null,
) =>
  currentOptions.reduce(
    (alreadyTrue: boolean, option: Record<string, string>) =>
      alreadyTrue || option[keyValue] === value,
    false,
  );

const useSelectFlyout = ({
  initialValue,
  selectAlignment,
  seamless,
  options,
  keyValue,
  onClick = noop,
  onChange = noop,
  setFilterText,
  dispatchChange,
}: SelectFlyoutProps) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const flyOutRef = useRef<HTMLDivElement>(null);
  const currentIndex = useRef<number | null>(0);
  // We need to perform a check if the user changed the value.
  // If not, we should not trigger onChange
  const valueBeforeOpen = useRef<string | null>(null);
  const [shouldToggleFlyOut, setShouldToggleFlyOut] = useState(false);

  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = useState<Alignment>(ALIGNMENT_RIGHT);
  const [direction, setDirection] = useState<Direction>(DOWN);
  const currentOptions = options;
  const updateCurrentIndex = (valueToSelect: string | null) => {
    const index = currentOptions.findIndex(
      (option: any) => option[keyValue] === valueToSelect,
    );

    currentIndex.current = index === -1 ? null : index;
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const toggleFlyOut = useCallback(
    (event?: SelectEventType) => {
      let newAlignment: Alignment = alignment;
      let dir: Direction = DOWN;

      if (open) {
        setFilterText?.('');

        if (valueBeforeOpen.current !== value) {
          onChange(event, value);
        }
      } else {
        valueBeforeOpen.current = value;

        const selectBounds = selectRef.current?.getBoundingClientRect();
        const flyOutBounds = flyOutRef.current?.getBoundingClientRect();

        if (flyOutBounds !== undefined && selectBounds !== undefined) {
          [newAlignment, dir] =
            calculateAlignmentAndDirection(
              alignment,
              selectAlignment,
              seamless,
              options.length,
              selectBounds,
              flyOutBounds,
            ) ?? [];
        }
      }

      setAlignment(newAlignment);
      setDirection(dir);
      setOpen(!open);

      dispatchChange?.(event, !open);
    },
    [
      alignment,
      dispatchChange,
      onChange,
      open,
      options.length,
      seamless,
      selectAlignment,
      setFilterText,
      value,
    ],
  );

  const openFlyOut = useCallback(
    (event: SelectEventType) => {
      if (!open) {
        toggleFlyOut(event);
      }
    },
    [open, toggleFlyOut],
  );

  const closeFlyOut = useCallback(
    (event: SelectEventType) => {
      if (open) {
        toggleFlyOut(event);
      }
    },
    [open, toggleFlyOut],
  );

  const handleClickOutside = useCallback(
    () => (event: React.MouseEvent<HTMLDivElement>) => {
      if (!selectRef.current?.contains(event.target as HTMLElement)) {
        closeFlyOut(event);
      }
    },
    [closeFlyOut],
  );

  useEffect(() => {
    if (open) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside, open]);

  useEffect(() => {
    if (shouldToggleFlyOut) {
      toggleFlyOut();
      setShouldToggleFlyOut(false);
    }
  }, [shouldToggleFlyOut, toggleFlyOut]);

  updateCurrentIndex(value);

  const selectOption = useCallback(
    (
      event: SelectEventType,
      valueToSelect: string | null = null,
      toggleFlyout = false,
    ) => {
      const target = event.target as Element;

      // allow any kind of value but as well empty string as an empty selection.
      if (target && (valueToSelect || valueToSelect === '')) {
        setShouldToggleFlyOut(toggleFlyout);
        setValue(valueToSelect);
        return valueToSelect;
      }
      const dataValue = target?.closest('[data-value]');
      if (dataValue) {
        const val = dataValue.getAttribute('data-value') ?? null;
        setShouldToggleFlyOut(toggleFlyout);
        setValue(val);
        return val;
      }
      if (toggleFlyout) {
        toggleFlyOut(event);
      }
      return value;
    },
    [toggleFlyOut, value],
  );

  const clickSelect = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.persist();
      // don't close if clicked into input field
      if ((event.target as Node).nodeName !== 'INPUT') {
        selectOption(event, null, true);
      }
      onClick(event);
    },
    [onClick, selectOption],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = event;
      let newIndex = null;

      switch (key) {
        case 'Escape':
          event.preventDefault();
          closeFlyOut(event);
          break;
        case 'Enter':
          event.preventDefault();
          toggleFlyOut(event);
          break;
        case 'ArrowDown':
          event.preventDefault();
          openFlyOut(event);
          if (
            currentIndex.current === null ||
            !isCurrentValueSelectable(currentOptions, keyValue, value)
          ) {
            newIndex = 0;
          } else if (currentIndex.current + 1 < currentOptions.length) {
            newIndex = currentIndex.current + 1;
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          openFlyOut(event);
          if (currentIndex.current && currentIndex.current - 1 >= 0) {
            newIndex = currentIndex.current - 1;
          }
          break;
        case 'Home':
          if (open) {
            event.preventDefault();
            newIndex = 0;
          }
          break;
        case 'End':
          if (open) {
            event.preventDefault();
            newIndex = currentOptions.length - 1;
          }
          break;
        default:
          break;
      }

      if (newIndex !== null) {
        selectOption(event, currentOptions[newIndex][keyValue]);
      }
    },
    [
      closeFlyOut,
      currentOptions,
      keyValue,
      open,
      openFlyOut,
      selectOption,
      toggleFlyOut,
      value,
    ],
  );

  return {
    value,
    open,
    selectRef,
    flyOutRef,
    className: classnames({
      [styles.upwards]: direction === UP,
      [styles[alignment]]: seamless,
      [styles.open]: open,
    }),
    clickSelect,
    handleKeyDown,
    handleBlur(event: React.FocusEvent<HTMLDivElement>) {
      if (open) {
        const willFocusOutside =
          selectRef.current &&
          !selectRef.current.contains(event.relatedTarget as HTMLElement);

        if (willFocusOutside) {
          toggleFlyOut(event);
        }
      }
    },
  };
};

interface FlyOutProps {
  className?: string;
  currentOptions: Record<string, string>[];
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  searchRef?: RefObject<HTMLDivElement>;
  handleFilterInput?: (event: Event, filteredText: string) => void;
  filterText?: number | string;
  i18n?: any;
  filterable: boolean;
  error?: string;
  ariaControlsId?: string;
  ariaLabelledbyId?: string;
  valueConfig: { keyName: string; keyValue: string; value: string | null };
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  selectedOptionRef: RefObject<HTMLDivElement>;
  seamless?: boolean;
  filterItemAmount?: number;
}

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const FlyOut = React.forwardRef<HTMLDivElement, FlyOutProps>(
  (
    {
      className,
      currentOptions,
      searchRef,
      handleFilterInput,
      filterText,
      i18n,
      filterable,
      error,
      valueConfig,
      selectedOptionRef,
      seamless,
      ariaControlsId,
      ariaLabelledbyId,
      filterItemAmount,
    },
    ref,
  ) => {
    const { keyName, keyValue, value } = valueConfig;

    const renderSearch = () => {
      const filterableItemCount = filterItemAmount || 15;

      if (currentOptions.length > filterableItemCount || filterText !== '') {
        return (
          <Input
            i18n={i18n}
            className={styles.filterInput}
            data-purpose="select.search"
            hideLabel={i18n.hideSearchInputLabel}
            label={i18n?.searchInputLabel?.toString() ?? 'no-text'}
            placeholder={i18n?.searchInputPlaceholder?.toString()}
            value={filterText}
            onChange={handleFilterInput}
            forwardedRef={searchRef}
            suffix={<Icon glyph={search} className={styles.searchIcon} />}
            inputMode="search"
          />
        );
      }

      return null;
    };

    const renderOption = (option: Record<string, string | null>) => {
      const classes = [styles.option];

      const active = value === option[keyValue];
      if (active) {
        classes.push(styles.selected);
      }

      const finalValue = option[keyName];

      const stringifiedOption = option[keyValue]?.toString();

      return (
        <div
          key={stringifiedOption}
          data-value={stringifiedOption || ''}
          className={classnames(classes)}
          id={stringifiedOption}
          role="option"
          aria-selected={active ? 'true' : 'false'}
          ref={active ? selectedOptionRef : null}
        >
          {finalValue}
        </div>
      );
    };

    const [iconStyle, iconClassName] = pseudoIcon(caretDown, 'after');

    const flyOutClasses = classnames(
      styles.flyOut,
      error && styles.flyOutError,
      {
        [iconClassName]: seamless,
        [styles.arrow]: seamless,
      },
      className,
    );

    return (
      <div
        className={flyOutClasses}
        ref={ref}
        style={seamless ? iconStyle : undefined}
      >
        {filterable && renderSearch()}
        <div
          className={styles.scrollable}
          data-purpose="select.flyout"
          role="listbox"
          id={ariaControlsId}
          tabIndex={-1}
          aria-labelledby={ariaLabelledbyId}
        >
          {currentOptions.map(renderOption)}
        </div>
      </div>
    );
  },
);

export default FlyOut;
export { useSelectFlyout };
