import React from 'react';
import AnimateHeight from 'components/AnimateHeight';
import classnames from 'classnames';
import { ListType } from 'components/Expander/Expander.types';

import styles from 'components/Expander/components/ListVariant/ListVariant.scss';

interface ListVariantProps {
  className?: string;
  contentHeight: number;
  isExpanded: boolean;
  listType: ListType;
  sliceChildren: (alwaysVisibleText: boolean) => React.ReactNode;
  expanderButton: React.ReactNode;
  id: string;
}

const ListVariant = ({
  className,
  contentHeight,
  isExpanded,
  listType,
  sliceChildren,
  expanderButton,
  id,
}: ListVariantProps) => {
  const List = listType;

  // visible content in first <List />, expandable content in second <List />
  return (
    <div className={styles.wrapper}>
      <List
        className={classnames(styles.listVariantVisible, className)}
        data-testid="visible-list"
      >
        {sliceChildren(true)}
      </List>
      {expanderButton}
      <AnimateHeight
        id={id}
        aria-hidden={!isExpanded}
        height={isExpanded ? 'auto' : 0}
        className={styles.animatedBlock}
      >
        <List
          start={listType === 'ol' ? contentHeight + 1 : undefined}
          className={classnames(styles.listVariantHidden, className)}
          data-testid="hidden-list"
        >
          {sliceChildren(false)}
        </List>
      </AnimateHeight>
    </div>
  );
};

export default ListVariant;
