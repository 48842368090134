import React, { forwardRef } from 'react';
import classnames from 'classnames';
import styles from 'components/Container/Container.scss';

export interface ContainerProps {
  /** @ignore */
  children?: React.ReactNode;
  /** @ignore */
  className?: string;
  /** The component used for the root node. Either a string to use an HTML element or a component. */
  component?: string | React.ComponentClass<any>;
}

const Container = forwardRef(
  ({ children, className, component: Comp, ...other }: ContainerProps, ref) => {
    const Component = Comp || 'div';
    return (
      <Component
        className={classnames(styles.container, className)}
        ref={ref}
        {...other}
      >
        {children}
      </Component>
    );
  },
);

export default Container;
