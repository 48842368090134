import React, { useMemo, FC } from 'react';
import {
  ProgressStepperStatusType,
  ProgressStepProps,
} from 'components/ProgressStepper/ProgressStepper.types';
import Heading from 'components/Heading';
import LinkHydra from 'components/Link';
import VerticalStepContext from 'components/ProgressStepper/components/ProgressStepperVertical/components/VerticalStep/VerticalStep.state';
import classNames from 'classnames';
import StepIcon from 'components/ProgressStepper/components/ProgressStep/components/StepIcon';

import styles from 'components/ProgressStepper/components/ProgressStepperVertical/components/VerticalStep/VerticalStep.scss';
import { HeadingLevel } from 'components/Heading/Heading.types';
import { SEOLevel } from 'types/typeDefinitions';

interface VerticalStepProps extends ProgressStepProps {
  /** if the step clickable */
  isClickDisabled?: boolean;
  /** Callback after the VerticalStep is clicked */
  onClick?: () => void;
  /** current status of the step(active, inactive, finished) */
  status: ProgressStepperStatusType;
  /** Number which shall be shown, either glyph or stepNumber should be given. */
  stepNumber: string | number;
  /** index of step */
  stepIndex: number;
  /** Id used for the step */
  id: string;
  /** Identify if step title is clickable */
  isFinishedTitleClickable?: boolean;
  /** Identify if editButton is disabled */
  isEditButtonDisabled?: boolean;
  /** Optional adjustment of level of heading */
  headingLevel?: HeadingLevel;
  /** Optional property to render actual HTML heading for SEO */
  headingSEO?: boolean | SEOLevel;
}

const VerticalStep: FC<VerticalStepProps> = ({
  className,
  children,
  editButtonText,
  onClick,
  status,
  stepIndex,
  isFinishedTitleClickable,
  isEditButtonDisabled = false,
  isClickDisabled = true,
  id,
  headingLevel = 3,
  headingSEO,
  ...stepProps
}) => {
  const verticalStepContextValue = useMemo(
    () => ({
      status,
    }),
    [status],
  );

  return (
    <VerticalStepContext.Provider value={verticalStepContextValue}>
      <div
        className={classNames(styles.step, {
          [styles.activeLine]: status === 'active',
        })}
        id={id}
      >
        <div
          className={classNames(styles.progressStepIconWrapper, className)}
          data-purpose={`progress-step-icon-${stepIndex}`}
        >
          <StepIcon
            {...stepProps}
            title={typeof stepProps.title === 'string' ? stepProps.title : ''}
            isClickDisabled={isClickDisabled}
            status={status}
            className={styles.progressStepIcon}
          />
        </div>
        <Heading
          className={classNames(styles.progressStepHeading, {
            [styles.inactiveHeading]: status === 'inactive',
            [styles.clickable]:
              isFinishedTitleClickable && status === 'finished' && onClick,
          })}
          level={headingLevel}
          SEO={headingSEO}
          noMargin
          onClick={
            isFinishedTitleClickable && status === 'finished' && onClick
              ? onClick
              : undefined
          }
        >
          {stepProps.title}
        </Heading>
        {status === 'finished' && !isEditButtonDisabled && (
          <div className={styles.progressStepEdit}>
            <LinkHydra
              className={styles.editLink}
              onClick={onClick}
              data-purpose={`progress-step-edit-${stepIndex}`}
            >
              {editButtonText}
            </LinkHydra>
          </div>
        )}
        <div className={styles.progressStepContentWrapper}>{children}</div>
      </div>
    </VerticalStepContext.Provider>
  );
};

export default VerticalStep;
