/* eslint-disable ssr-friendly/no-dom-globals-in-react-fc */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import classnames from 'classnames';
import { HorizontalScrollingProps } from 'components/HorizontalScrolling/HorizontalScrolling.types';

import styles from 'components/HorizontalScrolling/HorizontalScrolling.scss';

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
type RefType = (HTMLDivElement & HTMLUListElement & HTMLOListElement) | null;

const HorizontalScrolling = forwardRef(
  (
    {
      children,
      className,
      hideFadeEnd = false,
      hideFadeStart = false,
      role = 'tablist',
      scrollTo = 'start',
      listType = 'div',
      listClassName,
      scrollOnlyMobile = false,
    }: HorizontalScrollingProps,
    ref,
  ) => {
    const trackRef = useRef<RefType>(null);

    // Allows to reset current scroll position to default
    useImperativeHandle(ref, () => ({
      reset: () => {
        const track = trackRef?.current;
        if (track) {
          track.scrollLeft = scrollTo === 'end' ? track.scrollWidth : 0;
        }
      },
      scrollTo: (value: number) => {
        const track = trackRef?.current;
        if (track) {
          track.scrollTo({
            left: value,
            behavior: 'smooth',
          });
        }
      },
      getRef: () => trackRef?.current,
    }));

    useEffect(() => {
      if (scrollTo === 'end' && trackRef.current) {
        trackRef.current.scrollLeft = trackRef.current.scrollWidth;
      }
    });

    const renderChildren = () => {
      const Element = listType;

      const computedRule = listType === 'div' ? role : undefined;

      return (
        <Element
          ref={trackRef}
          className={classnames(styles.track, listClassName, {
            [styles.scrollOnlyMobile]: scrollOnlyMobile,
          })}
          role={computedRule}
        >
          {children}
        </Element>
      );
    };

    const wrapperClassName = classnames(
      className,
      styles.wrapper,
      { [styles.showFadeStart]: !hideFadeStart },
      { [styles.showFadeEnd]: !hideFadeEnd },
    );

    return (
      <div data-testid="scrolling.horizontal" className={wrapperClassName}>
        {renderChildren()}
      </div>
    );
  },
);

export default HorizontalScrolling;
