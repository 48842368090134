import React from 'react';
import classnames from 'classnames';
import { noop } from '@xxxlgroup/hydra-utils/common';

import styles from 'components/Breadcrumbs/components/BreadcrumbsItem/BreadcrumbsItem.scss';

interface BreadcrumbsItemProps {
  className?: string;
  children?: React.ReactNode;
  /**
   * The name of this BreadcrumbsItem.
   * This string will be displayed in case this BreadcrumbsItem is displayed inside a dropdown.
   * Is only required to be filled if the children property isn't a string. (Else the children string will be used as the name.)
   * */
  name?: string;
  /** @deprecated */
  href?: string;
  /** The constructor of a Link component. */
  link?: string | React.ElementType;
  /** Providing a method to intersect via onClick. */
  onClick?: (event: React.MouseEvent) => void;
  /** Providing a method to intersect via onKeyDown. */
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

const BreadcrumbsItem = React.memo(
  ({
    children,
    className,
    link: Tag = 'button',
    name,
    onClick = noop,
    onKeyDown = noop,
    ...other
  }: BreadcrumbsItemProps) => {
    const childrenIsString = typeof children === 'string';
    const contentClasses = classnames(styles.content, {
      [styles.underline]: childrenIsString,
    });
    const computedName = childrenIsString && !name ? children : name;

    return (
      <Tag
        className={classnames(styles.breadcrumbsItem, className)}
        onClick={onClick}
        onKeyDown={onKeyDown}
        data-name={computedName}
        aria-label={computedName}
        title={computedName}
        {...other}
      >
        {children && <span className={contentClasses}>{children}</span>}
      </Tag>
    );
  },
);

export default BreadcrumbsItem;
