import React from 'react';
import { sanitize } from 'isomorphic-dompurify';
import classnames from 'classnames';

import styles from 'components/Subheading/Subheading.scss';

import { SubheadingProps } from 'components/Subheading/Subheading.types';

const Subheading = ({
  anchorId = '',
  className,
  content,
  hasEmphasizedStyle = false,
  level = 1,
  noMargin = false,
  textAlign = 'inherit',
  ...other
}: SubheadingProps) => {
  const htmlContent = sanitize(content);

  return (
    <p
      {...other}
      id={anchorId}
      className={classnames(
        styles.subheading,
        { [styles[`subheading-${level}`]]: !hasEmphasizedStyle },
        { [styles[`subheading-emphasize-${level}`]]: hasEmphasizedStyle },
        { [styles.noMargin]: noMargin },
        styles[`aligned-${textAlign}`],
        className,
      )}
      {...(htmlContent && {
        dangerouslySetInnerHTML: { __html: htmlContent },
      })}
    />
  );
};

export default Subheading;
