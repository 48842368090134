import React, { FC } from 'react';
import classNames from 'classnames';

import styles from 'components/Skeleton/Skeleton.scss';

export interface SkeletonProps {
  /** @ignore */
  className?: string;
  /** number of skeleton elements that will get generated */
  count: number;
}

const Skeleton: FC<SkeletonProps> = ({ count, className, ...other }) => (
  <>
    {Array.from(Array(count).keys()).map((index) => (
      <div
        key={`skeleton-${index}`}
        className={classNames(styles.default, className)}
        aria-hidden="true"
        {...other}
      />
    ))}
  </>
);

export default Skeleton;
