import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  ForwardedRef,
  ReactElement,
} from 'react';
import type { SnackbarApiOptions } from 'components/Snackbar';
import type {
  SnackbarItemProps,
  CloseSnackbarItem,
} from 'components/Snackbar/components/SnackbarItem/SnackbarItem.types';
import useDurationTimer from 'hooks/useDurationTimer';

interface SnackbarItemRef {
  isDismissed?: boolean;
  close?: CloseSnackbarItem;
}

const SnackbarItemRefRender = <I extends string, O extends SnackbarApiOptions>(
  { children, apiOptions, coreDefinition, remove }: SnackbarItemProps<I, O>,
  ref: ForwardedRef<CloseSnackbarItem>,
) => {
  const { removeDelay, component: Component } = coreDefinition;
  const {
    onRemoved,
    onDismissed,
    onDurationPaused,
    onDurationResumed,
    isCloseable,
    className,
    duration: initialDuration,
  } = apiOptions;
  const [isDismissed, setIsDismissed] = useState(false);
  const { current: snackbarItemRef } = useRef<SnackbarItemRef>({});
  const { startDuration, pauseDuration, resumeDuration, clearDuration } =
    useDurationTimer(initialDuration, {
      onExpired() {
        if (!snackbarItemRef.isDismissed) {
          snackbarItemRef.close?.(false);
        }
      },
      onDurationPaused(percent, initDuration) {
        if (!snackbarItemRef.isDismissed) {
          onDurationPaused(percent, initDuration);
        }
      },
      onDurationResumed(percent, initDuration, durationOffset) {
        if (!snackbarItemRef.isDismissed) {
          onDurationResumed(percent, initDuration, durationOffset);
        }
      },
    });

  const close = useCallback<(close?: boolean) => void>(
    (closed = false) => {
      const { isDismissed: itemDismissed } = snackbarItemRef;
      const removeSelf = () => {
        remove();
        onRemoved(closed);
      };
      if (!itemDismissed) {
        clearDuration();
        setIsDismissed(true);
        onDismissed(closed);

        if (removeDelay) {
          setTimeout(removeSelf, removeDelay);
        } else {
          removeSelf();
        }
      }
    },
    [
      snackbarItemRef,
      removeDelay,
      clearDuration,
      remove,
      onDismissed,
      onRemoved,
    ],
  );

  const resumeDurationProxy = useCallback<(durationOffset?: number) => number>(
    (durationOffset = 0) =>
      resumeDuration(snackbarItemRef.isDismissed ? 0 : durationOffset),
    [resumeDuration, snackbarItemRef],
  );

  const snackbarItemInfo = {
    ...coreDefinition,
    ...apiOptions,
    isDismissed,
    className,
    close: isCloseable ? close : undefined,
    pauseDuration,
    resumeDuration: resumeDurationProxy,
  };

  useEffect(startDuration, [startDuration]);
  useImperativeHandle(ref, () => close, [close]);

  snackbarItemRef.isDismissed = isDismissed;
  snackbarItemRef.close = close;

  return <Component {...snackbarItemInfo}>{children}</Component>;
};

type ForwardRefFn<R> = <P = Record<string, unknown>>(
  p: P & React.RefAttributes<R>,
) => ReactElement | null;

const SnackbarItem = forwardRef(
  SnackbarItemRefRender,
) as ForwardRefFn<CloseSnackbarItem>;

export default SnackbarItem;
