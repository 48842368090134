import React, { FC } from 'react';
import classNames from 'classnames';
import { Glyph } from 'types/typeDefinitions';

import Indicator from 'components/Indicator';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import {
  CircleVariant,
  ProgressStepperStatusType,
  ThemeVariant,
} from 'components/ProgressStepper/ProgressStepper.types';

import styles from 'components/ProgressStepper/components/ProgressStep/components/StepIcon/StepIcon.scss';

const INDICATOR_STATUS: Record<
  ProgressStepperStatusType,
  { theme: ThemeVariant; variant: CircleVariant }
> = {
  finished: { theme: 'dark', variant: 'loud' },
  active: { theme: 'dark', variant: 'silent' },
  inactive: { theme: 'tertiary', variant: 'silent' },
};

interface StepIconProps {
  /** @ignore */
  className?: string;
  /** Disables the click on the icon and applies different styling. */
  isClickDisabled?: boolean;
  /** Glyph which shall be shown, either glyph or stepNumber should be given. */
  glyph?: Glyph;
  /** Glyph which shall be shown, if the step is finished. */
  glyphFinished?: Glyph;
  /** Callback after the StepIcon is clicked */
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => void;
  /** In which status the step is currently in */
  status?: ProgressStepperStatusType;
  /** Number which shall be shown, either glyph or stepNumber should be given. */
  stepNumber?: string | number;
  /** In case button has no valid content to provide accessible description */
  title?: string;
}

const StepIcon: FC<StepIconProps> = ({
  className,
  isClickDisabled,
  glyph,
  glyphFinished,
  onClick,
  status = 'finished',
  stepNumber,
  title,
}) => {
  const renderStepIconAndContent = () => {
    const indicator = INDICATOR_STATUS[status];

    let displayedGlyph = glyph;
    if (status === 'finished' && glyphFinished) {
      displayedGlyph = glyphFinished;
    }

    const [iconStyle, iconClassName] = pseudoIcon(displayedGlyph);

    return (
      <Indicator
        aria-hidden="true"
        className={classNames(styles.indicator, className, iconClassName)}
        theme={indicator.theme}
        variant={indicator.variant}
        onClick={!isClickDisabled ? onClick : undefined}
        style={iconStyle}
        title={title}
      >
        {!displayedGlyph && stepNumber}
      </Indicator>
    );
  };

  return (
    <div className={classNames(styles.step)}>{renderStepIconAndContent()}</div>
  );
};

export default React.memo(StepIcon);
