import React from 'react';
import { isValuePresent } from '@xxxlgroup/hydra-utils/common';
import Errors from 'components/Input/components/Errors';
import Content from 'components/Content';
import { ErrorsType, i18nType, InputType } from 'components/Input/Input.types';
import ActionButton from 'components/Input/components/ActionButton';
import Affix from 'components/Input/components/Affix';
import styles from 'components/Input/Input.scss';
import StateIcons from './components/StateIcons';
import CancelButton from './components/CancelButton';

type ValueType = string | number | undefined;
type StringType = string | undefined;
type CloneChildrenType = (
  children: React.ReactElement,
  childType: string,
) => void;

export const setSuffixStyles = (
  isIconVisible: boolean,
  actionButtonVisible: boolean,
) => isIconVisible || actionButtonVisible;

export const getPlaceholderWithRequiredOrOptionalSuffix = (
  placeholder: StringType,
  required: boolean,
  requiredSymbol: StringType,
  optionalSymbol: StringType,
): string => {
  let newPlaceholder = placeholder || '';
  if (required && requiredSymbol) {
    newPlaceholder += ` ${requiredSymbol}`;
  }
  if (!required && optionalSymbol) {
    newPlaceholder += ` ${optionalSymbol}`;
  }
  return newPlaceholder;
};

export const isActionButtonVisible = (
  type: InputType,
  actionButton: React.ReactElement | undefined,
) => type === 'password' || !!actionButton;

export const setElement = (isMultiline: boolean) =>
  isMultiline ? 'textarea' : 'input';
export const createClippedValue = (
  valueFromEvent: string,
  maxLength: number | undefined,
) =>
  valueFromEvent?.length
    ? valueFromEvent.substr(0, maxLength || undefined)
    : '';

export const setDataPurpose = (dataPurpose: StringType) =>
  `${dataPurpose || 'input'}.field`;

export const checkValue = (
  value: ValueType,
  refValue: StringType,
  defaultValue: ValueType,
) =>
  isValuePresent(value) ||
  isValuePresent(refValue) ||
  isValuePresent(defaultValue);

export const setDefaultValue = (value: ValueType, defaultValue: ValueType) =>
  value ? undefined : defaultValue;

export const renderErrors = (
  errors: ErrorsType | undefined,
  label: string,
  id?: string,
) => errors && <Errors errors={errors} id={id} label={label} />;

export const renderDescription = (description: React.ReactNode) =>
  description && (
    <Content className={styles.description} content={description} />
  );

interface ActionButtonAttributes {
  actionButton: React.ReactElement | undefined;
  cloneChildren: CloneChildrenType;
  i18n: i18nType;
  type: InputType;
  showPassword: boolean;
  togglePasswordVisibility: () => void;
}

export const renderActionButton = (
  actionWrapperAttributes: Record<string, string>,
  actionButtonAttributes: ActionButtonAttributes,
) => {
  const {
    actionButton,
    cloneChildren,
    i18n,
    type,
    showPassword,
    togglePasswordVisibility,
  } = actionButtonAttributes;
  return (
    isActionButtonVisible(type, actionButton) && (
      <div {...actionWrapperAttributes}>
        <ActionButton
          actionButton={actionButton}
          cloneChildren={cloneChildren}
          i18n={i18n}
          type={type}
          showPassword={showPassword}
          togglePasswordVisibility={togglePasswordVisibility}
        />
      </div>
    )
  );
};

export const renderPrefix = (
  prefixWrapperAttributes: any,
  cloneChildren: CloneChildrenType,
  prefix?: JSX.Element | string,
) =>
  prefix && (
    <div {...prefixWrapperAttributes}>
      <Affix cloneChildren={cloneChildren} content={prefix} />
    </div>
  );

interface SuffixAttributes {
  isIconVisible?: string | boolean | string[] | JSX.Element;
  errors?: ErrorsType;
  success: boolean;
  isCancelButtonVisible?: boolean;
  i18n: i18nType;
  clearValue: () => void;
  suffix?: JSX.Element | string;
  cloneChildren: CloneChildrenType;
}

export const renderSuffixSection = (
  suffixWrapperAttributes: Record<string, unknown>,
  suffixSectionAttributes: SuffixAttributes,
) => {
  const {
    isIconVisible,
    errors,
    success,
    isCancelButtonVisible,
    i18n,
    clearValue,
    cloneChildren,
    suffix,
  } = suffixSectionAttributes;
  return (
    isIconVisible && (
      <div {...suffixWrapperAttributes}>
        <StateIcons errors={errors} success={success} />
        {isCancelButtonVisible ? (
          <CancelButton i18n={i18n} clearValue={clearValue} />
        ) : (
          suffix && <Affix cloneChildren={cloneChildren} content={suffix} />
        )}
      </div>
    )
  );
};
