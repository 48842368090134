import React, {
  CSSProperties,
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useState,
} from 'react';
import {
  SnackbarItemProps,
  SnackbarItemRank,
} from 'components/Snackbar/components/SnackbarItem/SnackbarItem.types';
import {
  SnackbarContentProps,
  SnackbarContentRef,
} from 'components/Snackbar/components/SnackbarContent/SnackbarContent.types';

const isRank = (
  snackbarItem: ReactElement<SnackbarItemProps>,
  rank: SnackbarItemRank,
): boolean => snackbarItem.props.apiOptions.rank === rank;

const SnackbarContent = forwardRef<SnackbarContentRef, SnackbarContentProps>(
  ({ className, component }, ref) => {
    const Component = component || 'div';
    const [items, setItems] = useState<Set<ReactElement<SnackbarItemProps>>>(
      new Set(),
    );
    const [containerStyles, setContainerStyles] = useState<CSSProperties>();
    const itemsArr = Array.from(items);

    useImperativeHandle(
      ref,
      () => ({
        setContainerStyles,
        setItems,
      }),
      [setContainerStyles, setItems],
    );

    return (
      <Component className={className} style={containerStyles}>
        {[
          ...itemsArr.filter((snackbarItem) => isRank(snackbarItem, 'first')),
          ...itemsArr.filter((snackbarItem) => isRank(snackbarItem, 'auto')),
          ...itemsArr.filter((snackbarItem) => isRank(snackbarItem, 'last')),
        ]}
      </Component>
    );
  },
);

export default React.memo(SnackbarContent);
