import React, { FC } from 'react';
import classnames from 'classnames';
import { noop } from '@xxxlgroup/hydra-utils/common';
import Indicator from 'components/Carousel/components/Indicator/Indicator';

import styles from 'components/Carousel/components/IndicatorsBar/IndicatorsBar.scss';

interface IndicatorOptions {
  className?: string;
}

interface IndicatorsBarProps {
  /** Index of active indicator. */
  activeIndex: number;
  /** @ignore */
  className?: string;
  /** Translation for UI elements. */
  i18n: {
    goto: string;
  };
  /** Number of slider big indicators to show */
  numberOfBigIndicators?: number;
  /** Number of indicators. */
  numberOfIndicators: number;
  /** Callback gets fired when the indicator button is clicked */
  onClick?: (index: number) => (event: React.MouseEvent) => void;
  /** Callback gets fired when the indicator button is focused */
  onFocus?: () => void;
  /** Callback gets fired when the indicator button is blured */
  onBlur?: () => void;
}
const LIST_ITEM_WIDTH = 19;

const IndicatorsBar: FC<IndicatorsBarProps> = ({
  activeIndex,
  className,
  i18n,
  numberOfBigIndicators = 3,
  numberOfIndicators,
  onClick = () => noop,
  onFocus = noop,
  onBlur = noop,
}: IndicatorsBarProps) => {
  const previousActiveIndex = 0;
  let minIndex = 0;
  let maxIndex = numberOfBigIndicators + 1;
  let maxBigDotsIndex = numberOfBigIndicators - 1;
  let minBigDotsIndex = 0;

  const renderIndicator = (index: number, options: IndicatorOptions = {}) => {
    const active = index === activeIndex;
    const { className } = options;

    return (
      <li
        key={index}
        className={styles.indicatorsListItem}
        aria-current={active}
      >
        <Indicator
          active={active}
          className={className}
          i18n={{ label: `${i18n.goto} ${index + 1}` }}
          onClick={onClick(index)}
        />
      </li>
    );
  };

  const allDotsVisible = numberOfIndicators <= numberOfBigIndicators + 2;

  const endIndex = numberOfIndicators - 1;

  // adjust visible dots min/max indexes and big dots min/max indexes
  // there are 3 different types of dots shown in indicators bar - small (S), big (B), tiny (T)
  // there is max one small and one tiny dot shown on the both sides of big dots area
  // size of big dots are is defined with prop set to slider component
  if (allDotsVisible && numberOfIndicators > 1) {
    // if all dots should be visible they are all big
    maxIndex = endIndex;
    minBigDotsIndex = minIndex;
    maxBigDotsIndex = maxIndex;
  } else {
    // if some dots should stay hidden adapt small and tiny dots around big dots area
    const isMovingForward = activeIndex > previousActiveIndex;
    const isMovingBackward = activeIndex < previousActiveIndex;

    // dots adjustment is needed if next active index is not big dot
    const needsDotsAdjustment =
      minBigDotsIndex > activeIndex || maxBigDotsIndex < activeIndex;

    if (needsDotsAdjustment && isMovingForward) {
      // when transitioning from big to small dot (step froward)
      if (activeIndex >= numberOfBigIndicators) {
        if (activeIndex === numberOfBigIndicators) {
          // TRANSITION TO FIRST POSSIBLE SMALL DOT FROM LEFT SIDE
          // B B B *S* T
          // next state should render small dot on left side of big dots area
          // and small and tiny dot on right side of big dots area
          // S B B *B* S T
          maxIndex = numberOfBigIndicators + 2;
        } else if (activeIndex > numberOfBigIndicators) {
          // TRANSITION TO SMALL OR TINY DOT FROM LEFT SIDE - DIFFERENT THAN FIRST POSSIBLE
          // S B B B *S* T or T S B B B *S* T or T S B B B *S* or S B B B S *T* or T S B B B S *T*
          const distanceToRightEdge = endIndex - activeIndex;
          minIndex = activeIndex - (numberOfBigIndicators + 1);
          if (distanceToRightEdge < 2) {
            // when there are no enough remaining dots on right side
            // to display both (small and tiny) then next state should render one of
            // T S B B *B* S or T S B B *B*
            maxIndex = activeIndex + distanceToRightEdge;
          } else {
            // when there are enough remaining dots on the right side
            // then next state should render small and tiny dot
            // on both sides of big dots area
            // T S B B *B* S T
            maxIndex = activeIndex + 2;
          }
        }
        // if next active index is not big dot, adjust big dots area
        maxBigDotsIndex = activeIndex;
        minBigDotsIndex = maxBigDotsIndex - (numberOfBigIndicators - 1);
      }
    } else if (needsDotsAdjustment && isMovingBackward) {
      const indexOfFirstRightNonBigDot = endIndex - numberOfBigIndicators;
      // when transitioning from big to small dot (step backward)
      if (activeIndex <= indexOfFirstRightNonBigDot) {
        if (activeIndex === indexOfFirstRightNonBigDot) {
          // TRANSITION TO FIRST POSSIBLE SMALL DOT FROM RIGHT SIDE
          // T *S* B B B
          // next state should render small dot on right side of big dots area
          // and small and tiny dot on left side of big dots area
          // T S B B B S
          maxIndex = endIndex;
          minIndex = endIndex - (numberOfBigIndicators + 2);
        } else if (activeIndex < indexOfFirstRightNonBigDot) {
          // TRANSITION TO SMALL OR TINY DOT FROM RIGHT SIDE - DIFFERENT THAN FIRST POSSIBLE
          // T *S* B B B S or T *S* B B B S T or *S* B B B S T or *T* S B B B S or *T* S B B B S T
          const distanceToLeftEdge = activeIndex;
          maxIndex = activeIndex + (numberOfBigIndicators + 1);
          if (distanceToLeftEdge >= 2) {
            // when there are enough remaining dots on the left side
            // then next state should render small and tiny dot
            // on both sides of big dots area
            // T S *B* B B S T
            minIndex = activeIndex - 2;
          }
        }
        // if next active index is not big dot, adjust big dots area
        minBigDotsIndex = activeIndex;
        maxBigDotsIndex = minBigDotsIndex + (numberOfBigIndicators - 1);
      }
    }
  }

  const containerWidth = allDotsVisible
    ? numberOfIndicators * LIST_ITEM_WIDTH
    : (maxIndex - minIndex + 1) * LIST_ITEM_WIDTH;

  const leftOffset = allDotsVisible ? 0 : -1 * minIndex * LIST_ITEM_WIDTH;

  const indicators = Array.from({ length: numberOfIndicators })
    .fill(1)
    .map((child, index) => {
      // render dots and set correct size (big, small or tiny)
      // small dotsa are first dots on left and right side of big dots area
      // tiny are all other dots that are sorrounding big dots area
      let indicatorClassName = styles.indicatorBig;
      if (index === maxBigDotsIndex + 1 || index === minBigDotsIndex - 1) {
        indicatorClassName = styles.indicatorSmall;
      } else if (index > maxBigDotsIndex + 1 || index < minBigDotsIndex - 1) {
        indicatorClassName = styles.indicatorTiny;
      }

      return renderIndicator(index, {
        className: classnames(styles.indicator, indicatorClassName),
      });
    });

  return (
    <div
      className={classnames(styles.indicatorsContainer, className)}
      key={`indicators-slide-count-${numberOfIndicators}`}
      style={{ width: `${containerWidth}px` }}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid="slider-indicator-bar"
    >
      <ul
        className={styles.indicatorsList}
        style={{ transform: `translateX(${leftOffset}px)` }}
      >
        {indicators}
      </ul>
    </div>
  );
};

export default IndicatorsBar;
